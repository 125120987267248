import SubNavBar from '../components/SubNavBar'
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../redux/types";
import no_image from "../images/logo.png"
import {useHistory, useParams} from "react-router-dom";
import MDEditor from "@uiw/react-md-editor";
import {useEffect} from "react";
import {getBlogsRequest} from "../redux/actions";

const Article = () => {
    const {id} = useParams<{ id: string }>()
    console.log(id)

    const dispatch = useDispatch()
    const history = useHistory()
    useEffect(() => {
        dispatch(getBlogsRequest())
        return () => {
        }
    }, [dispatch, history])

    const resources = useSelector((state: AppState) => state.blogs)
    const {list} = resources
    console.log(list)
    const article = list.find(el => el.url === id || el.id === parseInt(id))
    console.log(article)
    return (
        <>
            <SubNavBar/>
            {article ? <div>
                <div className="section-wrapper article-header-container">
                    <div>
                        <div className="article-header block">
                            <div className="article-category article-view">

                            </div>
                            <div className="article-title  article-view">
                                <div>
                                    <h1>
                                        {article.title}
                                    </h1>
                                </div>
                            </div>
                            <div className="article-byline article-view">
                                <div className="article-byline-info">

                                </div>
                            </div>
                            {article.cover?.length && <div
                                className="article-feature-image"
                                style={{
                                    backgroundImage: article.cover_type === 'image' ? `url(${article.cover}), url(${no_image})` : 'none',
                                }}
                            >
                                {
                                    article.cover_type === 'video' && <video
                                        src={article.cover}
                                        style={{
                                            width: '100%',
                                            maxHeight: '100%',
                                            background: '#000000',
                                        }}
                                        controls
                                    >
                                    </video>
                                }
                                {article.cover_type === 'youtube_video' && <iframe
                                    height="100%"
                                    src={`https://www.youtube.com/embed/${article.cover}`}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    title={article.title}
                                    style={{
                                        margin: '0 auto',
                                        width: '100%',
                                    }}
                                />
                                }
                            </div>
                            }
                        </div>
                    </div>
                </div>
                <div
                    className="article-content"
                >
                    <MDEditor.Markdown source={article.content}/>
                </div>

                <div
                    className="tags block article-topics"
                >
                    <div>
                        <div>
                            <p className="tags-container">
                                {article.topics?.map((item: any) => {
                                    console.log(item)
                                    return item?.name?.length ? <span className="button">
                                    {item.name}
                                </span> : null
                                })}
                            </p>
                        </div>
                    </div>
                </div>
            </div>: <div>
                404
            </div>
            }
        </>
    )
}

export default Article
