import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Button, Col, Container, Form, Row} from 'react-bootstrap'

import {updateUserRequest,} from '../redux/actions'
import Message from './Message'
import Loader from './Loader'
import {AppState} from '../redux/types'

const MemberForm = ({onSelect, key}: any) => {
    const member = useSelector((state: AppState) => state.user)
    const {error, loading} = member;
    const [chosenService, setChosenService] = useState(member?.chosenService || '');
    const [serviceLang, setServiceLang] = useState(member?.tabletLanguage || '');
    const [autoAnswer, setAutoAnswer] = useState(member?.autoAnswer || '');
    const [option, setOption] = useState(member?.serviceOption || '');

    const dispatch = useDispatch()

    useEffect(() => {
        console.log(member?.chosenService)
        console.log(chosenService)
        if (!member?.chosenService || member?.chosenService !== chosenService) {
            if (chosenService === 'huoleton') {
                setServiceLang('suomi');
                setAutoAnswer('true');
                setOption('huoleton1');
            } else if (chosenService === 'helppo') {
                setServiceLang('suomi');
                setAutoAnswer('true');
                setOption('helppo4');
            } else if (chosenService === 'mestari') {
                setServiceLang('suomi');
                setAutoAnswer('false');
            } else if (chosenService === 'suosikkipalvelu') {
                setServiceLang('suomi');
                setAutoAnswer('true');
                setOption('helppo4');
            } else if (chosenService === 'peruspalvelu') {
                setServiceLang('suomi');
                setAutoAnswer('true');
            }
        } else {
            setServiceLang(member?.tabletLanguage);
            setAutoAnswer(member?.autoAnswer);
        }
    }, [chosenService, member])

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault()

        dispatch(updateUserRequest({
            ...member,
            password: '',
            chosenService: chosenService,
            tabletLanguage: serviceLang,
            autoAnswer: autoAnswer,
            serviceOption: option
        }));

        setTimeout(function () {
            onSelect('orders');
        }, 3000);
    }

    const auto_answer = <Form.Group
        className="feature_choices-form"
    >
        <label>Videopuhelun avautuminen seniorin tabletissa, kun perheenjäsen tai hoitaja soittaa:</label>
        <div
            style={{
                marginLeft: 127,
            }}
        >
            <Form.Check
                className='radio-button'
                type='radio'
                label={`Videopuhelu avautuu automaattisesti ilman kosketusta${chosenService === 'mestari' ? ' (mahdollinen Samsung tabletissa)' : ''}`}
                id='true'
                name='autoAnswer'
                value={'true'}
                checked={autoAnswer === 'true'}
                onChange={() => setAutoAnswer('true')}
            />
            <Form.Check
                className='radio-button'
                type='radio'
                label={`Videopuhelu ei avaudu automaattisesti ilman kosketusta${chosenService === 'mestari' ? ' (Samsung tai Apple)' : ''}`}
                id='false'
                name='autoAnswer'
                value={'false'}
                checked={autoAnswer === 'false'}
                onChange={() => setAutoAnswer('false')}
            />
        </div>
    </Form.Group>

    const lang = <Form.Group className="feature_choices-form">
        <label>Tabletin kieli:</label>
        <Form.Check
            style={{
                display: 'inline-block',
                margin: 8,
            }}
            className='radio-button'
            type='radio'
            label='Suomi'
            id='suomi'
            name='serviceLang'
            value={'suomi'}
            checked={serviceLang === 'suomi'}
            onChange={() => setServiceLang('suomi')}
        />
        <Form.Check
            style={{
                display: 'inline-block',
                margin: 8,
            }}
            className='radio-button'
            type='radio'
            label='Ruotsi'
            id='ruotsi'
            name='serviceLang'
            value={'ruotsi'}
            checked={serviceLang === 'ruotsi'}
            onChange={() => setServiceLang('ruotsi')}
        />
    </Form.Group>

    const helppo_options = <Form.Group className="feature_choices-form">
        <label>Sovellusvalikoima:</label>
        <div
            style={{
                marginLeft: 127,
            }}
        >
            <Form.Check
                className='radio-button'
                type='radio'
                label='Videopuhelu'
                id='helppo1'
                name='option'
                value={'helppo1'}
                checked={option === 'helppo1'}
                onChange={() => setOption('helppo1')}
            />
            <Form.Check
                className='radio-button'
                type='radio'
                label='Videopuhelu, äänikirja, radio'
                id='helppo2'
                name='option'
                value={'helppo2'}
                checked={option === 'helppo2'}
                onChange={() => setOption('helppo2')}
            />
            <Form.Check
                className='radio-button'
                type='radio'
                label='Videopuhelu, äänikirja, radio, Yle Areena, lehdet'
                id='helppo3'
                name='option'
                value={'helppo3'}
                checked={option === 'helppo3'}
                onChange={() => setOption('helppo3')}
            />
            <Form.Check
                className='radio-button'
                type='radio'
                label='Videopuhelu, äänikirja, radio, Yle Areena, lehdet, pelit'
                id='helppo4'
                name='option'
                value={'helppo4'}
                checked={option === 'helppo4'}
                onChange={() => setOption('helppo4')}
            />
            <Form.Check
                className='radio-button'
                type='radio'
                label='Videopuhelu, äänikirja, radio, Yle Areena, lehdet, taide'
                id='helppo5'
                name='option'
                value={'helppo5'}
                checked={option === 'helppo5'}
                onChange={() => setOption('helppo5')}
            />
            <Form.Check
                className='radio-button'
                type='radio'
                label='Videopuhelu, äänikirja, radio, Yle Areena, lehdet, pelit, taide, Spotify'
                id='helppo6'
                name='option'
                value={'helppo6'}
                checked={option === 'helppo6'}
                onChange={() => setOption('helppo6')}
            />
            <Form.Check
                className='radio-button'
                type='radio'
                label='Videopuhelu, äänikirja, radio, Yle Areena, lehdet, pelit, taide, Spotify, Whatsapp'
                id='helppo7'
                name='option'
                value={'helppo7'}
                checked={option === 'helppo7'}
                onChange={() => setOption('helppo7')}
            />
        </div>
    </Form.Group>

    return (
        <Container fluid>
            <Row className="justify-content-md-left">
                <Col xs={12}>
                    {error && <Message variant='danger'>{error.message}</Message>}
                    {loading && <Loader/>}
                    <h1 className='member-form-header'>4 Räätälöi tabletin ominaisuudet halutessasi</h1>
                    {member?.id && <div
                        className='feature_choices'
                    >
                        <div className='text-for-companies-second'>
                            Suosittelemme tämän kohdan täyttämistä, mutta se ei ole välttämätöntä. Oletusasetuksina on
                            suomi, videopuhelun automaattinen avautuminen ja valitun palvelun (Huoleton, Helppo,
                            Mestari) suosituin sovellusvalikoima. Jatka valitsemalla ostamasi palvelu tai siirry
                            eteenpäin.
                        </div>
                        <Form
                            onSubmit={submitHandler}
                        >
                            <Form.Group className='feature_choices'>
                                <Form.Check
                                    className='radio-button'
                                    type='radio'
                                    label='Huoleton - palvelu'
                                    id='huoleton'
                                    name='chosenService'
                                    value='huoleton'
                                    checked={chosenService === 'huoleton'}
                                    onChange={() => setChosenService('huoleton')}
                                />
                                {chosenService === 'huoleton' && <div className="feature_choices-option">
                                    {lang}
                                    {auto_answer}
                                    <Form.Group className="feature_choices-form">
                                        <label>Sovellusvalikoima:</label>
                                        <div
                                            style={{
                                                marginLeft: 127,
                                            }}
                                        >
                                            <Form.Check
                                                className='radio-button'
                                                type='radio'
                                                label='Videopuhelu'
                                                id='huoleton1'
                                                name='option'
                                                value={'huoleton1'}
                                                checked={option === 'huoleton1'}
                                                onChange={() => setOption('huoleton1')}
                                            />
                                            <Form.Check
                                                className='radio-button'
                                                type='radio'
                                                label='Videopuhelu, radio'
                                                id='huoleton2'
                                                name='option'
                                                value={'huoleton2'}
                                                checked={option === 'huoleton2'}
                                                onChange={() => setOption('huoleton2')}
                                            />
                                            <Form.Check
                                                className='radio-button'
                                                type='radio'
                                                label='Videopuhelu, äänikirja'
                                                id='huoleton3'
                                                name='option'
                                                value={'huoleton3'}
                                                checked={option === 'huoleton3'}
                                                onChange={() => setOption('huoleton3')}
                                            />
                                            <Form.Check
                                                className='radio-button'
                                                type='radio'
                                                label='Videopuhelu, Yle Areena'
                                                id='huoleton4'
                                                name='option'
                                                value={'huoleton4'}
                                                checked={option === 'huoleton4'}
                                                onChange={() => setOption('huoleton4')}
                                            />
                                            <Form.Check
                                                className='radio-button'
                                                type='radio'
                                                label='Videopuhelu, radio, äänikirja, Yle Areena'
                                                id='huoleton5'
                                                name='option'
                                                value={'huoleton5'}
                                                checked={option === 'huoleton5'}
                                                onChange={() => setOption('huoleton5')}
                                            />
                                        </div>
                                    </Form.Group>
                                    <div className="feature_choices-text">
                                        <p>
                                            Huoleton tabletti on kooltaan pienin ja edullisin. Suosittelemme sitä vain
                                            sovelluksiin, joissa ei tarvitse kirjoittaa tabletin näppäimistöllä.
                                            Valitsemme huolella tablettiin sovellukset, jotka ovat suosittuja, helppoja,
                                            turvallisia ja maksuttomia. Kehitämme valikoimia jatkuvasti ja teemme niihin
                                            tarvittaessa muutoksia.
                                        </p>
                                        <p>
                                            Maksuton äänikirjasovellus on Celian. Neuvomme, miten saat palvelun käyttöön
                                            ja omat tunnukset kirjastosta. Palvelu on ikäihmisille, joilla on
                                            esimerkiksi heikentynyt näkö, lihaskunto, toimintakyky tai muisti.
                                        </p>
                                    </div>
                                </div>}
                                <Form.Check
                                    className='radio-button'
                                    type='radio'
                                    label='Helppo - palvelu'
                                    id='helppo'
                                    name='chosenService'
                                    value='helppo'
                                    checked={chosenService === 'helppo'}
                                    onChange={() => setChosenService('helppo')}
                                />
                                {chosenService === 'helppo' && <div className="feature_choices-option">
                                    {lang}
                                    {auto_answer}
                                    {helppo_options}
                                    <div className="feature_choices-text">
                                        <p>
                                            Valitsemme huolella tablettiin sovellukset, jotka ovat suosittuja, helppoja,
                                            turvallisia ja maksuttomia. Esimerkiksi peleistä on pasianssi, muistipeli ja
                                            shakki ja lehdistä HS ja IS. Kehitämme valikoimia jatkuvasti ja teemme
                                            niihin
                                            tarvittaessa muutoksia.
                                        </p>
                                        <p>
                                            Maksuton äänikirjasovellus on Celian. Neuvomme, miten saat palvelun käyttöön
                                            ja
                                            omat tunnukset kirjastosta. Palvelu on ikäihmisille, joilla on esimerkiksi
                                            heikentynyt näkö, lihaskunto, toimintakyky tai muisti. Jos seniori toivoo
                                            yksittäisen muun lisäsovelluksen tai maksullisen sovellusversion, it-tukemme
                                            auttaa. Helppo tablettiin seniori ei voi itse lisätä sovelluksia.
                                        </p>
                                    </div>
                                </div>}
                                <Form.Check
                                    className='radio-button'
                                    type='radio'
                                    label='Mestari - palvelu'
                                    id='mestari'
                                    name='chosenService'
                                    value='mestari'
                                    checked={chosenService === 'mestari'}
                                    onChange={() => setChosenService('mestari')}
                                />
                                {chosenService === 'mestari' && <div className="feature_choices-option">
                                    {lang}
                                    {auto_answer}
                                    <div className="feature_choices-text">
                                        <p>
                                            <div>
                                                <b>Tabletin sovellusvalikoima</b>
                                            </div>
                                            Mestari tablettiin on huolella valittu ja valmiiksi asennettu senioreiden
                                            suosimat ja toimivat sovellukset. Tabletissa on valmiina mm. Digihappy
                                            videopuhelut, Celian maksuttomat äänikirjat, Chrome, radio, kamera, Yle
                                            Areena, lehdet kuten HS ja IS, pelit kuten pasianssi, muistipeli ja shakki,
                                            taide, Spotify, Whatsapp, Facebook, Gmail ja muut Googlen sovellukset.
                                            Kehitämme valikoimia jatkuvasti ja teemme niihin tarvittaessa muutoksia.
                                            Henkilökohtaisessa alkuneuvonnassa asennamme toiveiden mukaan
                                            lisäsovelluksia ja neuvomme henkilökohtaisten tilien, kuten sähköposti- ja
                                            sometilien luomisessa. Neuvomme myös, miten seniori voi itse asentaa
                                            sovelluksia.
                                        </p>
                                        <p>
                                            Maksuton äänikirjasovellus on Celian. Neuvomme, miten saat palvelun käyttöön
                                            ja omat tunnukset kirjastosta. Palvelu on ikäihmisille, joilla on
                                            esimerkiksi heikentynyt näkö, lihaskunto, toimintakyky tai muisti.
                                        </p>
                                    </div>
                                </div>}
                                <Form.Check
                                    className='radio-button'
                                    type='radio'
                                    label='Kotisairaanhoito/Palveluasunto Suosikkipalvelu'
                                    id='suosikkipalvelu'
                                    name='chosenService'
                                    value='suosikkipalvelu'
                                    checked={chosenService === 'suosikkipalvelu'}
                                    onChange={() => setChosenService('suosikkipalvelu')}
                                />
                                {chosenService === 'suosikkipalvelu' && <div className="feature_choices-option">
                                    {lang}
                                    {auto_answer}
                                    {helppo_options}
                                </div>}
                                <Form.Check
                                    className='radio-button'
                                    type='radio'
                                    label='Kotisairaanhoito/Palveluasunto Peruspalvelu'
                                    id='peruspalvelu'
                                    name='chosenService'
                                    value='peruspalvelu'
                                    checked={chosenService === 'peruspalvelu'}
                                    onChange={() => setChosenService('peruspalvelu')}
                                />
                                {chosenService === 'peruspalvelu' && <div className="feature_choices-option">
                                    {lang}
                                    {auto_answer}
                                </div>}
                            </Form.Group>
                        </Form>
                        <div className="feature_choices-text">
                            Jos tilasit yksinomaan Digihappy videopuhelusovelluksen seniorin ja perheen omille
                            laitteelle tai sairaalan yhteiselle laitteelle tämä kohta ei koska sinua.
                        </div>

                        <Button type='button' className='tallenna' onClick={submitHandler}>Valmis</Button>
                    </div>}
                </Col>
            </Row>
        </Container>
    )
}
export default MemberForm
