import {Container, Image, Row} from 'react-bootstrap'

import TextOnImage from '../components/TextOnImage'
import ServicesAccordionTable from '../components/ServicesAccordionTable'
import ServicesInfo2 from '../components/ServicesInfo2'
import texts from '../texts'
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {getAlertsRequest} from "../redux/actions";
import {AppState} from "../redux/types";
import {Link} from 'react-router-dom'
import {Helmet} from "react-helmet";
import services_page from "../images/services_page.jpg";

const Services = () => {
    const dispatch = useDispatch()

    const currentDate = Date.now();

    useEffect(() => {
        dispatch(getAlertsRequest())
        return () => {
        }
    }, [dispatch])

    const resources = useSelector((state: AppState) => state.alerts)
    const {list} = resources

    return (
        <>
            <Helmet>
                <title>Senioritabletti palveluna kattaa kaiken | Digihappy</title>
                <meta
                    name="description"
                    content="Senioritabletti palveluna on helppo. Saat käyttövalmiin ja senioriystävällisen tabletin, helpon videopuhelun, it tuen ja etähallinnan."
                />
            </Helmet>
            <div>
                <TextOnImage
                    text1={texts[9].text1}
                    text2={texts[9].text2}
                    text5={texts[9].text3}
                    height={`calc(100vh - ${56 + 56 * list?.filter(el => {
                        console.log(el)
                        console.log(currentDate)
                        // @ts-ignore
                        return (!el.start || (new Date(el.start)) <= currentDate) && (!el.end || (new Date(el.end)) >= currentDate);

                    })?.length - 1}px)`}
                    width='100vw'
                    backgroundImage={services_page}
                    backgroundSize='cover'
                    backgroundPosition='center center'
                    backgroundRepeat='no-repeat'
                    backgroundAttachment=''
                    imgClassName='servicespic'
                    className1='services-text-on-image'
                    className2='services-text-on-image services-text-on-image-second'
                />
            </div>

            <Container fluid className=' my-3 services-container services-container-80'>
                <h1 className='services-header'>Mitä palveluun kuuluu?</h1>
                <ServicesInfo2/>
            </Container>
            <Container fluid className='my-3 services-container' id='palvelut'>
                <h1 className='what-is-best' id='best'>
                    Mikä palvelu on paras seniorille?
                </h1>
                <ServicesAccordionTable/>
                <Row>
                    <div
                        style={{
                            fontSize: '1.4rem',
                            margin: '1em auto 1em auto',
                            textAlign: 'justify',
                        }}
                    >
                        <Link
                            to="/tilaa"
                            className="linkki"
                        >
                            Tilaa palvelu
                        </Link>
                    </div>
                </Row>
                <Row>
                    <div
                        style={{
                            fontSize: '0.8rem',
                            margin: '1em 0px 1em 0',
                            textAlign: 'justify',
                            padding: '0 15px',
                        }}
                    >
                        <div
                            id="footnote-1"
                        >
                            <b>1</b> Digihappy palveluversion (Huoleton, Helppo, Mestari) vaihtaminen on mahdollista.
                            Vaihtokustannus on 40 € ja toimitusmaksu, jos vaihtoon sisältyy tabletin fyysinen vaihto
                            (esimerkiksi Samsung Appleksi).
                        </div>
                        <div
                            id="footnote-2"
                        >
                            <b>2</b> Näyttö ilman lukitusta, suuri tekstikoko, äänivolyymi automaattisesti päällä,
                            orientaatio lukittu. Video, jossa automaattinen vastaus-toiminto
                        </div>
                        <div
                            id="footnote-3"
                        >
                            <b>3</b> Applen Ipadin kaikki sovellukset ja asetukset, myös toimintakykyrajoituksia
                            (esimerkiksi näkö, kuulo) helpottavat erityisominaisuudet ovat mahdollisia. Oman Apple-tilin
                            tai sähköpostitilin liittäminen on mahdollista.
                        </div>
                        <div
                            id="footnote-4"
                        >
                            <b>4</b> Saatte ensimmäisen kuukauden Digihappy-palvelua koskevaa it- puhelintukea maksutta
                            ilman rajoituksia. Tämän jälkeen henkilökohtainen it-tuki on maksutonta 30 minuuttia /kk
                            Huoleton, 45 minuuttia/kk Helppo ja 45 minuuttia/kk Mestari. Lisätuen kustannus on 10 €/10
                            minuuttia, minimiveloitus on 10 €/puhelu. Jos kyseessä on palvelun tai laitteen meihin
                            liittyvä toimintahäiriö, sen ratkaiseminen on aina teille maksutonta.
                        </div>
                        <div
                            id="footnote-5"
                        >
                            <b>5</b> Seniorin tabletissa on valmiina maksuton äänikirjasovellus, jonka tuottaa <a
                            href='https://www.celia.fi/'
                            className='linkki'
                            target='_blank'
                            rel="noreferrer"
                        >Celia</a>.
                            Palvelu on henkilöille, joilla on lukemisvaikeus, esimerkiksi ikäihmisellä heikentynyt näkö,
                            lihaskunto, toimintakyky tai muisti. Digihappy neuvoo, miten saat palvelun käyttöön ja omat
                            tunnukset kirjastosta.
                        </div>
                    </div>
                </Row>
            </Container>
            <Image fluid src={'https://senioritabletti-image-bucket.s3.eu-north-1.amazonaws.com/connect1.jpg'}
                   className="connect-img" alt="mother-and-daughter"/>
        </>
    )
}

export default Services
