import SubNavBar from '../components/SubNavBar'
import {Col, Row} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../redux/types";
import no_image from "../images/logo.png"
import {useHistory} from "react-router-dom";
import {useEffect} from "react";
import {getBlogsRequest} from "../redux/actions";
import {Helmet} from "react-helmet";

const blogURI = (article: any) => article?.url || article?.id;

const Health = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    useEffect(() => {
        dispatch(getBlogsRequest())
        return () => {
        }
    }, [dispatch, history])

    const resources = useSelector((state: AppState) => state.blogs)
    const {list} = resources
    console.log(list)
    const featured_article = list.find(el=>el.featured) || list[0] || {
        id: null,
        title: '',
        topics: [{name: ''}],
        cover: '',
        createdAt: '',
        content_preview: '',
    };
    return (
        <>
            <Helmet>
                <meta
                  name="description"
                  content="Blogi seniorin terveydestä ja hyvinvoinnista. Ja vinkkejä iloiseen tabletin käyttöön. Lääketieteen, hyvinvoinnin ja digin ammattilaiset kirjoittajina."
                />
            </Helmet>
            <SubNavBar/>
            <div className="blog-wrapper">
            </div>
            {featured_article?.id &&
                <div className="blog-wrapper">
                    <div className="featured-article block">
                        <div className="featured-article-card-div">
                            <div
                                className="article_in_blog"
                                onClick={() => {
                                    history.push(`/blog/${blogURI(featured_article)}`)
                                }}
                            >
                                <Row className="featured-article-card">
                                    <Col
                                        md={7} xs={12}
                                        className="featured-article-card-image"
                                        style={{
                                            background: featured_article?.cover_type === 'image' ? `top center / cover no-repeat url(${featured_article?.cover}), top center / contain no-repeat url(${no_image})` : (
                                                featured_article?.cover_type === 'youtube_video' ? `top center / cover no-repeat url(https://img.youtube.com/vi/${featured_article?.cover}/maxresdefault.jpg)` : 'none'),
                                        }}
                                    >
                                        {
                                            featured_article?.cover_type === 'video' && <video
                                                src={featured_article?.cover}
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover'
                                                }}
                                            >
                                            </video>
                                        }
                                    </Col>
                                    <Col md={5} xs={12} className="featured-article-card-body">
                                        <p className="article-category">
                                            {featured_article?.topics[0]?.name}
                                        </p>
                                        <h3>{featured_article?.title}</h3>
                                        <p className="featured-article-card-description">
                                            {featured_article?.content_preview}
                                        </p>
                                        <p className="featured-article-card-date">
                                            {(new Date(featured_article?.createdAt)).toLocaleDateString('fi-FI', {
                                                year: 'numeric',
                                                month: '2-digit',
                                                day: '2-digit'
                                            }).replace(/\./g, '-')}
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {featured_article?.id &&
                <div className="blog-wrapper">
                </div>
            }
            <div className="blog-wrapper">
                <Row className="blog-items">
                    {list?.filter(el=>el.id !== featured_article.id)?.map(item => {
                        return <Col
                            md={4}
                            style={{
                                marginBottom: 48
                            }}
                            key={`col_article_${item?.id}`}
                        >
                            <div
                                className="article_in_blog"
                                onClick={() => {
                                    history.push(`/blog/${blogURI(item)}`)
                                }}
                            >
                                <Row className="article-card column">
                                    <Col
                                        xs={12}
                                        className="article-card-image"
                                        style={{
                                            background: item.cover_type === 'image' ? `top center / cover no-repeat url(${item.cover}), top center / contain no-repeat url(${no_image})` : (
                                                item.cover_type === 'youtube_video' ? `top center / cover no-repeat url(https://img.youtube.com/vi/${item.cover}/maxresdefault.jpg)` : 'none'),
                                        }}
                                    >
                                        {
                                            item.cover_type === 'video' && <video
                                                src={item.cover}
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover'
                                                }}
                                            >
                                            </video>
                                        }
                                    </Col>
                                    <Col xs={12} className="article-card-body">
                                        <p className="article-category">
                                            {item.topics[0]?.name}
                                        </p>
                                        <h3
                                            title={item.title}
                                        >
                                            {item.title}
                                        </h3>
                                        <p className="featured-article-card-description">
                                            {item.content_preview}
                                        </p>
                                        <p className="featured-article-card-date">
                                            {(new Date(item.createdAt)).toLocaleDateString('fi-FI', {
                                                year: 'numeric',
                                                month: '2-digit',
                                                day: '2-digit'
                                            }).replace(/\./g, '-')}
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    })
                    }
                </Row>
            </div>
        </>
    )
}

export default Health
