import {
  all,
  call,
  delay,
  put,
  select,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import axios from 'axios';
import LocalStorage from '../../local-storage';
import {
  AppState,
  CreateGroupRequestAction,
  DeleteUserRequestAction,
  LoginUserRequestAction,
  RegisterCustomerRequestAction,
  SendContactMailRequestAction,
  SendEmailCustomerRequestAction,
  SendEmailMemberRequestAction,
  UpdateGroupRequestAction,
  UpdateUserRequestAction,
  GetUserEditFormDataRequestAction,
  SetUserEditFormDataRequestAction,
  UpdateUserEditRequestAction,
} from '../types';
import {
  createGroupFail,
  createGroupSuccess,
  deleteUserFail,
  deleteUserSuccess,
  getUserFail,
  getUsersFail,
  getUsersSuccess,
  getUserSuccess,
  loginUserFail,
  loginUserRequest,
  loginUserSuccess,
  registerCustomerFail,
  registerCustomerSuccess,
  sendContactMailFail,
  sendContactMailSuccess,
  sendEmailCustomerFail,
  sendEmailCustomerSuccess,
  sendEmailMemberFail,
  sendEmailMemberSuccess,
  setLoggedIn,
  updateGroupFail,
  updateGroupSuccess,
  updateUserFail,
  updateUserSuccess,
  getNursesRequest,
  getUserEditFormDataSuccess,
  getUserEditFormDataFail,
  setUserEditFormDataSuccess,
  setUserEditFormDataFail,
  updateUserEditRequest,
  updateUserEditRequestSuccess,
  updateUserEditRequestFail,
} from '../actions';
import { toast } from 'react-toastify';

const groupId = (state: AppState) => state.user.group.id;

function* getUsersSaga() {
  try {
    //@ts-ignore
    // const res = yield axios.get('/user/all')
    const res = yield axios.post('/user/customers', { take: 0, skip: 0 });
    yield put(getUsersSuccess(res.data.payload));
  } catch (error) {
    yield put(getUsersFail(error));
  }
}

function* registerCustomerSaga(action: RegisterCustomerRequestAction) {
  const username = action.payload.username;
  const email = action.payload.email;
  const password = action.payload.password;
  const firstName = action.payload.firstName;
  const lastName = action.payload.lastName;
  const role = action.payload.role;
  const history = action.payload.history;
  const redirect = action.payload.redirect;

  try {
    //@ts-ignore
    const res = yield axios.post('/user/root', {
      username,
      email,
      password,
      firstName,
      lastName,
      role,
      history,
    });
    yield all([
      put(registerCustomerSuccess(res.data)),
      put(loginUserRequest(username, password, redirect, history)),
    ]);
  } catch (error: any) {
    console.log(error.response.status);
    if (error.response.status === 409) {
      error.response.data.message += '. Try to log in.';
    }
    yield put(registerCustomerFail(error));
    setTimeout(() => {
      put(registerCustomerFail(''));
    }, 3000);
  }
}

function* clearUserErrorSaga(action: RegisterCustomerRequestAction) {
  try {
    yield put(registerCustomerFail({ message: '' }));
  } catch (error) {
    yield put(registerCustomerFail(''));
  }
}

function* loginUserSaga(action: LoginUserRequestAction) {
  const username = action.payload.credential.username;
  const password = action.payload.credential.password;
  const history = action.payload.history;
  const redirect = action.payload.redirect;

  try {
    //@ts-ignore
    const res = yield axios.post('/login', {
      username,
      password,
    });
    console.log(res);
    if (res.data.status === 200) {
      yield put(loginUserSuccess(res.data.payload));
      yield LocalStorage.saveToken(res.data.payload.token);
      yield put(setLoggedIn());
      if (res?.data?.payload?.isAdmin) {
        history.push('/admin/users');
      } else {
        if (redirect) {
          history.push(redirect);
        } else {
          history.push('/user');
        }
      }
    }
  } catch (error) {
    yield put(loginUserFail(error));
    yield delay(2000);
    yield put(loginUserFail(null));
  }
}

function* getUserSaga() {
  try {
    //@ts-ignore
    const res = yield axios.get('/user');
    yield put(getUserSuccess(res.data.payload));
  } catch (error) {
    yield put(getUserFail(error));
  }
}

function* updateUserSaga(action: UpdateUserRequestAction) {
  let userInfo = action.payload;
  // @ts-ignore
  delete userInfo['orders'];
  try {
    //@ts-ignore
    const res = yield axios.patch('/user', userInfo);
    console.log(res);
    toast.success('Tiedot tallennettu', {
      hideProgressBar: true,
      position: 'bottom-center',
      theme: 'colored',
    });
    yield put(updateUserSuccess(res.data.payload));
    yield call(getUsersSaga);
  } catch (error: any) {
    console.log(error);
    let msg = 'Error';
    if (error?.response?.data?.message === 'Password too short') {
      msg = 'Salasana liian lyhyt';
    }
    toast.error(msg, {
      hideProgressBar: true,
      position: 'bottom-center',
      theme: 'colored',
    });
    yield put(updateUserFail(error));
  }
}

function* deleteUserSaga(action: DeleteUserRequestAction) {
  const userId = action.payload;
  try {
    //@ts-ignore
    const res = yield axios.delete(`/user/${userId}`);
    if (res.status === 200) {
      yield put(deleteUserSuccess());
      yield call(getUsersSaga);
    }
  } catch (error) {
    yield put(deleteUserFail(error));
  }
}

function* createGroupSaga(action: CreateGroupRequestAction) {
  const group = action.payload;
  try {
    //@ts-ignore
    const res = yield axios.post('/user/group', group);
    yield put(createGroupSuccess(res.data.payload));
  } catch (error) {
    yield put(createGroupFail(error));
  }
}

// Add new group member
function* updateGroupSaga(action: UpdateGroupRequestAction) {
  const member = action.payload;

  try {
    //@ts-ignore
    const id = yield select(groupId);
    //@ts-ignore
    const res = yield axios.patch(`/user/group/${id}`, member);
    yield put(updateGroupSuccess(res.data.payload));
  } catch (error: any) {
    yield put(updateGroupFail(error));
    toast.error(error?.response?.data?.message || 'Error', {
      hideProgressBar: true,
      position: 'bottom-center',
      theme: 'colored',
    });
  }
}

// Send mail to a member
function* sendEmailMemberSaga(action: SendEmailMemberRequestAction) {
  const email = action.payload.email;
  const name = action.payload.firstName;
  try {
    //@ts-ignore
    const res = yield axios.post('/email/member', { email, name });
    yield put(sendEmailMemberSuccess(res.data));
  } catch (error) {
    yield put(sendEmailMemberFail(error));
  }
}

// Send mail to a customer
function* sendEmailCustomerSaga(action: SendEmailCustomerRequestAction) {
  const email = action.payload.email;
  const name = action.payload.firstName;
  try {
    //@ts-ignore
    const res = yield axios.post('/email/customer', { email, name });
    yield put(sendEmailCustomerSuccess(res.data));
  } catch (error) {
    yield put(sendEmailCustomerFail(error));
  }
}

// Contact mail
function* sendContactMailSaga(action: SendContactMailRequestAction) {
  const { email, name, text } = action.payload;
  console.log('actionpayloadcontactmailSaga', action.payload);
  try {
    //@ts-ignore
    const res = yield axios.post('/email/contact', { email, name, text });
    console.log('resdatainsaga', res.data);
    yield put(sendContactMailSuccess(res.data));
  } catch (error) {
    yield put(sendContactMailFail(error));
  }
}

// Get User Profile
function* getUserEditFormDataSaga(action: GetUserEditFormDataRequestAction) {
  const id = action.payload;
  console.log('payload', id);
  try {
    // @ts-ignore
    const res = yield axios.get(`/user/customer-detail/${id}`);
    yield put(getNursesRequest());
    if (res.data.status === 200) {
      yield put(getUserEditFormDataSuccess(res.data.payload));
    }
  } catch (error) {
    yield put(getUserEditFormDataFail(error));
  }
}

// Update User
function* updateUserEditSaga(action: UpdateUserEditRequestAction) {
  try {
    // @ts-ignore
    const res = yield axios.patch('/user', action.payload);
    if (res.data.status === 200) {
      updateUserEditRequestSuccess();
    }
  } catch (error) {
    updateUserEditRequestFail(error);
  }
}

// Update User Profile
function* setUserEditFormDataSaga(action: SetUserEditFormDataRequestAction) {
  const profile = action.payload?._formData;
  const userEditFormData = action.payload?.userEditFormData;
  try {
    let res = undefined;

    if (profile.nurses) {
      // @ts-ignore
      res = yield axios.post('/user/senior/edit-profile', profile);
    } else {
      // @ts-ignore
      res = yield axios.post('/user/member/edit-profile', profile);
    }

    if (res?.data?.status === 200) {
      yield put(setUserEditFormDataSuccess());
      yield put(updateUserEditRequest(userEditFormData));
    }
  } catch (error) {
    yield put(setUserEditFormDataFail(error));
  }
}

const sagaWatcher = [
  takeLatest('GET_USERS_REQUEST', getUsersSaga),
  takeLatest('REGISTER_CUSTOMER_REQUEST', registerCustomerSaga),
  takeLatest('CLEAR_USER_ERROR', clearUserErrorSaga),
  takeLatest('LOGIN_USER_REQUEST', loginUserSaga),
  takeLatest('GET_USER_REQUEST', getUserSaga),
  takeLeading('UPDATE_USER_REQUEST', updateUserSaga),
  takeLatest('CREATE_GROUP_REQUEST', createGroupSaga),
  takeLeading('UPDATE_GROUP_REQUEST', updateGroupSaga),
  takeLatest('DELETE_USER_REQUEST', deleteUserSaga),
  takeLatest('SEND_EMAIL_MEMBER_REQUEST', sendEmailMemberSaga),
  takeLatest('SEND_EMAIL_CUSTOMER_REQUEST', sendEmailCustomerSaga),
  takeLatest('SEND_CONTACT_MAIL_REQUEST', sendContactMailSaga),
  takeLatest('GET_USER_EDIT_FORM_DATA_REQUEST', getUserEditFormDataSaga),
  takeLatest('SET_USER_EDIT_FORM_DATA_REQUEST', setUserEditFormDataSaga),
  takeLatest('UPDATE_USER_EDIT_REQUEST', updateUserEditSaga),
];

export default sagaWatcher;
