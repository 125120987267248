import {Container} from 'react-bootstrap'

const PrivacyPolicy = () => {
    return (
        <>
            <Container fluid>
                <section className='privacy-policy-text'>
                    <div className='privacy-policy-dates-container '>
                        {/*<div className='privacy-policy-dates-left'>*/}
                        {/*    Laatimispäivämäärä: 14.6.2021*/}
                        {/*</div>*/}
                        {/*<div className='privacy-policy-dates-right'>*/}
                        {/*    Viimeisin muutos: 14.6.2021*/}
                        {/*</div>*/}
                    </div>
                    <div className='privacy-policy-header'>
                        Tietosuojaseloste
                    </div>
                    <div className='text-for-companies-first'>
                        <p>
                            Tämä on EU:n yleisen tietosuoja-asetuksen (GDPR) mukainen rekisteri- ja tietosuojaseloste.
                            Laatimispäivämäärä on 14.6.2021 <i>Viimeisin muutos on tehty 20.1.2024</i>.
                        </p>
                        <p>
                            Rekisterin pitäjä
                        </p>
                        <p>
                            Yritys valvoo antamiasi tietoja ja on vastuussa henkilötiedoista tietosuojalain mukaisesti.
                        </p>
                        <p>
                            Yrityksen tiedot
                        </p>
                        Digihappy Oy
                        <br/>
                        Otakaari 5 A
                        <br/>
                        02150 Espoo
                        <br/>
                        Puh. 010 517 2070
                    </div>

                    <div className='privacy-policy-header2'>
                        Rekisterin nimi
                        <br/>
                        Digihappy Oy:n asiakasrekisteri
                        <br/>
                        <br/>
                        Oikeusperuste ja henkilötietojen käyttötarkoitus
                    </div>

                    <div className='text-for-companies-first'>
                        <p>
                            EU:n yleisen tietosuoja-asetuksen mukaan henkilön tietoinen suostumus, kuten sopimus, on
                            oikeusperuste henkilötietojen käsittelylle. Palveluun tallennetaan myös varsinaisen
                            asiakkaan läheisten yhteystietoja, joiden luovuttamiseen asiakkaalla on oltava kyseisten
                            henkilöiden suostumus.
                        </p>
                        <p>
                            Henkilötietojen käsittelyn tarkoitus on yhteydenpito asiakkaisiin, tilatun palvelun
                            tuottaminen ja asiakassuhteen ylläpito. Tietoja ei käytetä automatisoituun päätöksentekoon
                            tai profilointiin.
                        </p>
                    </div>

                    <div className='privacy-policy-header'>
                        Rekisterin tietosisältö
                    </div>
                    <div className='text-for-companies-first'>
                        <p>
                            Rekisterissä voidaan käsitellä seuraaviin ryhmiin kuuluvia, rekisterin käyttötarkoituksen
                            kannalta tarpeellisia tietoja:
                            <ul>
                                <li>Nimi</li>
                                <li>Yhteystiedot (kuten osoitteet, puhelinnumerot, sähköpostiosoitteet)</li>
                                <li>Kieli</li>
                            </ul>
                        </p>
                        <p>
                            Asiakkuuteen ja muuhun asialliseen yhteyteen liittyvät tiedot, kuten:
                            <ol>
                                <li>
                                    Asiallisen yhteyden alkamis- ja päättymisajankohta
                                </li>
                                <li>
                                    Palvelujen käyttämiseen liittyvät tunnistetiedot (esim. Asiakasnumero,
                                    käyttäjätunnukset ja salasanat) ja sähköisen viestinnän tunnistamistiedot (esim.
                                    IP-osoite) ja palveluvalinnat
                                </li>
                                <li>
                                    Tunnistettuna asiakkaana tehtyjen ostojen tiedot ja ostoprosessin eri vaiheet ja
                                    tapahtumat
                                </li>
                                <li>
                                    Laskutukseen ja perintään liittyvät tiedot
                                </li>
                                <li>
                                    Asiakkuuteen ja muuhun asialliseen yhteyteen liittyvä yhteydenpito ja viestintä eri
                                    kanavissa ja medioissa (esim. Palautteet sekä asiakaspalvelupuheluiden tallenteet)
                                </li>
                                <li>
                                    Suoramarkkinointiluvat ja –kiellot sekä markkinoinnin kohdentamiseen liittyvät
                                    tiedot
                                </li>
                                <li>
                                    Rekisteröidyn tuottama tai häntä koskeva aineisto
                                </li>
                                <li>
                                    Tiedot rekisteröidyn julkaisemista ilmoituksista
                                </li>
                                <li>
                                    Tapahtuma- ja käyttäjäanalyysitiedot
                                </li>
                                <li>
                                    Edellä yksilöityjen tietojen muutostiedot
                                </li>
                            </ol>
                        </p>
                    </div>

                    <div className='privacy-policy-header'>
                        Säännönmukaiset tietolähteet
                    </div>

                    <div className='text-for-companies-first'>
                        <p>
                            Henkilötietoja kerätään rekisteröidyltä itseltään sekä rekisterinpitäjän järjestelmistä, kun
                            rekisteröity rekisteröityy palveluihin ja käyttää palveluita.
                            <br/>
                            Henkilötietoja voidaan kerätä ja päivittää rekisterinpitäjän ja sen kanssa kulloinkin samaan
                            konserniin kuuluvien yhtiöiden rekistereistä, väestötietojärjestelmästä,
                            luottotietorekistereistä, ASML Suomen Asiakkuusmarkkinointiliitto ry:n ylläpitämistä
                            rekistereistä sekä muista vastaavista tietopalveluja tarjoavista julkisista tai yksityisistä
                            rekistereistä ja tietolähteistä, sekä rekisterinpitäjän yhteistyökumppaneilta.
                        </p>
                    </div>

                    <div className='privacy-policy-header'>
                        Tietojen luovutus ja siirto
                    </div>

                    <div className='text-for-companies-first'>
                        <p>
                            Tietoja ei siirretä Euroopan unionin tai Euroopan talousalueen ulkopuolelle, ellei se ole
                            palvelun teknisen toteuttamisen vuoksi tarpeellista. Tällöin rekisterinpitäjä huolehtii
                            riittävästä tietosuojan tasosta lainsäädännön edellyttämällä tavalla.
                        </p>
                    </div>

                    <div className='privacy-policy-header'>
                        Rekisterin suojaus
                    </div>

                    <div className='text-for-companies-first'>
                        <p>
                            Digihappy Oy:n asiakastietokanta sijaitsee Euroopan talousalueella Amazon Web Services (AWS)
                            pilvipalveluntarjoajan kapasiteetissa ja täten on erittäin kattavasti teknisesti suojattu
                            ulkopuolisen luvattoman käytön estämiseksi. Pilvipalveluntarjoajat suojaavat asiakkaitaan
                            vain ulkoisilta teknisiltä uhilta ja Digihappy Oy on käyttöehtojen mukaan itse vastuussa
                            omasta ympäristöstään. Niiltä osin kuin Digihappy on vastuussa omasta teknisestä
                            infrastruktuurista, huolehdimme itse ylläpidosta ja tietoturvasta.
                        </p>
                        <p>
                            Rekisteriä käyttävät vain tehtävään koulutetut henkilöt kenen työn suorittamiseen rekisterin
                            käyttö on välttämätöntä. Kaikki rekisteriin liittyvät käyttövaltuudet ovat työperusteisia ja
                            jokaisella käyttäjällä on vain hänen tehtäväänsä tarvittavat oikeudet. Rekisteriä suojaavat
                            tekniset kontrollit, jotka ovat Digihappy Oy:n hallinnassa ja ovat jatkuvan valvonnan
                            alaisia.
                        </p>
                    </div>

                    <div className='privacy-policy-header'>
                        Tietojen säilytysaika
                    </div>

                    <div className='text-for-companies-first'>
                        <p>
                            Rekisteröidyn henkilökohtaisia tietoja säilytetään vain tarvittava aika, jotta voidaan
                            täyttää tässä selosteessa kuvatut käyttötarkoitukset. Lisäksi joitain tietoja voidaan
                            säilyttää kauemmin niiltä osin kuin se on tarpeen laissa asetettujen velvollisuuksien, kuten
                            kirjanpitoa ja kuluttajakauppaa koskevien vastuiden, toteuttamiseksi ja niiden asianmukaisen
                            toteuttamisen osoittamiseksi.
                        </p>
                    </div>

                    <div className='privacy-policy-header'>
                        Tarkastus-, kielto- ja korjausoikeus
                    </div>

                    <div className='text-for-companies-first'>
                        <p>
                            Rekisteröidyllä on oikeus tarkastaa, mitä häntä koskevia tietoja rekisteriin on talletettu.
                            Tarkastuspyyntö tulee lähettää kirjallisesti ja allekirjoitettuna rekisteriasioista
                            vastaavalle henkilölle. Tarkastuspyyntö voidaan myös esittää henkilökohtaisesti
                            rekisterinpitäjän toimipaikassa yllä mainitussa osoitteessa. Tarkastuspyynnön voi tehdä
                            maksutta kerran vuodessa. Tarkastuspyyntöihin vastataan viimeistään kuukauden kuluttua
                            pyynnön esittämisestä.
                        </p>
                        <p>
                            Rekisteröidyillä on oikeus kieltää tietojensa käyttäminen suoramainontaan, etämyyntiin tai
                            muuhun suoramarkkinointiin sekä mielipide- ja markkinatutkimukseen. Rekisteröidyllä on myös
                            oikeus vaatia virheellisen tiedon korjaamista tai kaikkien tietojensa poistamista
                            ilmoittamalla siitä rekisterinpitäjän asiakaspalveluun <a
                            href="mailto:digihappy@mediti.fi">digihappy@mediti.fi</a>.
                        </p>
                    </div>
                </section>

            </Container>
        </>
    )
}

export default PrivacyPolicy
