import {Col, Container, Row} from 'react-bootstrap'

import ContactForm from '../components/ContactForm'
import texts from '../texts'
import {Helmet} from "react-helmet";

// import {Container} from 'react-bootstrap'

const Contact = () => {
    return (
        <>
            <Helmet>
                <title>Yhteystiedot | Digihappy</title>
                <meta
                    name="description"
                    content="Yhteydenotto meta description: Ota yhteyttä. Autamme sinua ja läheisiäsi."
                />
            </Helmet>
            <Container
                fluid
                style={{
                    height: `calc(100vh - 66px)`,
                    width: '100vw',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage: `url(https://senioritabletti-image-bucket.s3.eu-north-1.amazonaws.com/connect2.jpg)`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'right top',
                    position: 'relative',
                    minHeight: 640,
                }}
                className='img-fluid contact-pic'
            >
                <Row>
                    <Col xs={12} md={7} className='contact-texts-column'>
                        <p className='ota-yhteyttä'>{texts[8].text1}</p>
                        <p className='apu'>{texts[8].text2}</p>
                    </Col>
                    <Col xs={12} md={5} className='contact-form-column d-none d-md-block'>
                        <ContactForm/>
                    </Col>
                </Row>
            </Container>
            <section>
                    <div className='d-block d-md-none'>
                <ContactForm/>
                    </div>
            </section>
        </>
    )
}

export default Contact
