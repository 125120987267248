import {Card, Col, Row} from 'react-bootstrap'
import {HashLink as Link} from 'react-router-hash-link';
import puhelin from '../images/puhelin.png';

const ServicesInfo2 = () => {
    return (
        <>
            <Row className='box-row'>
                <Col xs={12} md={6} className='info-col'>
                    <Card.Body>
                        <Card.Title className='services-info-title'>
                            <strong>Tabletti, sim, jalusta</strong>
                        </Card.Title>
                        <ul className='services-info-text'>
                            <li>Samsung Galaxy Tab tai Apple iPad</li>
                            <li>Sim-kortti, rajaton data</li>
                            <li>Jalusta - <a
                                href='https://www.youtube.com/watch?v=4Fb5M2m_LAw'
                                className='lue-lisää-linkki'
                                target='_blank'
                                rel="noreferrer"
                            >
                                <strong>
                                    katso video
                                </strong>
                            </a></li>
                        </ul>
                        <p className='options'>
                            <Link className="linkki" to='/palvelut#palvelut'>
                                Valitse seniorin toimintakyvyn mukaan tabletti <strong>Huoleton, Helppo tai
                                Mestari</strong>.
                            </Link>
                        </p>
                    </Card.Body>
                </Col>
                <Col xs={12} md={6} className='icon-col'>
                    <div className='icon-box'>
                        <i className='fas fa-tablet'/>
                    </div>
                </Col>
            </Row>

            <Row className='box-row'>
                <Col xs={12} md={6} className='icon-col'>
                    <div className='icon-box'>
                        <i className='fas fa-tablet-alt'/>
                    </div>
                </Col>
                <Col xs={12} md={6} className='info-col'>
                    <Card.Body>
                        <Card.Title className='services-info-title'>
                            <strong>
                                Käyttövalmis, yksilöllisesti muokattu tabletti
                            </strong>{' '}
                        </Card.Title>
                        <ul className='services-info-text'>
                            <li>
                                Senioriystävälliset asetukset, sovellukset ja kontaktit valmiina
                            </li>
                            <li>
                                Seniorille, joka ei itse pysty koskemaan tablettiin videopuhelu toimii ilman apua,
                                avustettuna äänikirjat, radio ja musiikki
                            </li>
                            <li>
                                Aktiiviselle seniorille lisäksi mm. lehdet, <a
                                href='https://areena.yle.fi/'
                                className='linkki'
                                target='_blank'
                                rel="noreferrer"
                            >Yle Areena</a>, pelit tai sähköposti - myös
                                maksetut sovellukset kuten Netflix
                            </li>
                        </ul>
                    </Card.Body>
                </Col>
            </Row>

            <Row className='box-row'>
                <Col xs={12} md={6} className='info-col'>
                    <Card.Body>
                        <Card.Title className='services-info-title'>
                            <strong>Helppo videopuhelu</strong>
                        </Card.Title>
                        <ul className='services-info-text'>
                            <li>Digihappy videosovellus on seniorilla tabletissa ja läheisellä kännykässä</li>
                            <li>Kun läheinen soittaa, seniorin video avautuu haluttaessa ilman kosketusta</li>
                            <li>Seniori soittaa koskemalla läheisen kuvaa</li>
                        </ul>

                        <a
                            href='https://www.youtube.com/watch?v=XQmltrIyYQ4'
                            className='lue-lisää-linkki'
                            target='_blank'
                            rel="noreferrer"
                        >
                            <strong>
                                Katso video
                            </strong>
                        </a>
                    </Card.Body>
                </Col>
                <Col xs={12} md={6} className='icon-col'>
                    <div
                        style={{
                            width: '75%',
                            height: '75%',
                            backgroundImage: `url(${puhelin})`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'contain',
                        }}
                    />
                </Col>
            </Row>

            <Row className='box-row'>
                <Col xs={12} md={6} className='icon-col'>
                    <div className='icon-box'>
                        <i className='fas fa-users'/>
                    </div>
                </Col>
                <Col xs={12} md={6} className='info-col'>
                    <Card.Body>
                        <Card.Title className='services-info-title'>
                            <strong>Perhetili</strong>
                        </Card.Title>
                        <ul className='services-info-text'>
                            <li>Liitä palveluun kuusi perheenjäsentä tai ystävää. Jäsenellä on oikeus jäsenpalveluihin.
                                Hän voi helposti soittaa videopuhelun seniorille ja hänen kontaktinsa ovat seniorin
                                tabletissa.
                            </li>
                            <li>Jäsenen käytössä on it-tuki
                            </li>
                        </ul>
                    </Card.Body>
                </Col>
            </Row>

            <Row className='box-row'>
                <Col xs={12} md={6} className='info-col'>
                    <Card.Body>
                        <Card.Title className='services-info-title'>
                            <strong>It-tuki, etähallinta, tietoturva, päivitykset</strong>
                        </Card.Title>
                        <ul className='services-info-text'>
                            <li>Aloituksessa videoneuvonta</li>
                            <li>Jatkuva it-tuki, puhelin- ja videoneuvonta, viestikanava</li>
                            <li>Tabletin etähallinta, tarvittaessa myös ilman seniorin aktiivista osallistumista</li>
                            <li>Tietoturva ja päivitykset</li>
                        </ul>
                    </Card.Body>
                </Col>
                <Col xs={12} md={6} className='icon-col'>
                    <div className='icon-box'>
                        <i className='fas fa-phone-square'/>
                    </div>
                </Col>
            </Row>

            <Row className='box-row'>
                <Col xs={12} md={6} className='icon-col'>
                    <div className='icon-box'>
                        <i className='fas fa-heartbeat'/>
                    </div>
                </Col>
                <Col xs={12} md={6} className='info-col'>
                    <Card.Body>
                        <Card.Title className='services-info-title'>
                            <strong>Hoivapalveluissa ylivoimainen kokonaisuus seniorille</strong>
                        </Card.Title>
                        <ul className='services-info-text'>
                            <li>Helpot videopuhelut hoitajan ja lääkärin kanssa</li>
                            <li>Videokuulutukset, terveystestit, aktivoivaa tekemistä</li>
                            <li>Samalla helpot  perhevideopuhelut </li>
                        </ul>
                    </Card.Body>
                </Col>
            </Row>
        </>
    )
}

export default ServicesInfo2
