import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  ListGroup,
  Row,
} from 'react-bootstrap';

import StepsHeader from '../components/StepsHeader';
import { loadStripe } from '@stripe/stripe-js';
import { AppState } from '../redux/types';
import { Elements } from '@stripe/react-stripe-js';
import OrderForm from '../components/OrderForm';
import React, { useState } from 'react';
import { setCouponMethod } from '../redux/actions';
import axios from 'axios';
import StepsNotification from '../components/StepsNotification';
import { toast } from 'react-toastify';

const PlaceOrder = () => {
  const cart = useSelector((state: AppState) => state.cart);
  const user = useSelector((state: AppState) => state.user);
  const history = useHistory();
  const [coupon, setCoupon] = useState(cart.coupon);
  const dispatch = useDispatch();
  const [couponData, setCouponData] = useState(cart.couponData);
  const { list } = useSelector((state: AppState) => state.seniorHomes);
  const stripePromise = loadStripe(user.stripePublicKey, { locale: 'fi' });
  const resources = useSelector((state: AppState) => state.resources);
  const { services } = resources;
  const shipping_direct = services?.find(
    (el) => el.shippingCategory === 'direct'
  );
  const shipping_posti = services?.find(
    (el) => el.shippingCategory === 'posti'
  );

  // back button
  const handleClick = () => {
    history.push(`/maksutapa`);
  };

  const setCouponHandler = (value: any) => {
    axios
      .get(`/coupons/verify/${value}`)
      .then((resp: any) => {
        console.log(resp);
        dispatch(setCouponMethod(resp?.data?.payload));
        setCoupon(coupon);
        setCouponData(resp?.data?.payload);
      })
      .catch((error) => {
        dispatch(setCouponMethod(null));
        setCoupon(null);
        setCouponData(null);
        toast.error('Alennuskoodia ei löydy', {
          hideProgressBar: true,
          position: 'bottom-center',
          theme: 'colored',
        });
      })
      .finally(() => {});
  };

  //@ts-ignore
  cart.price = cart.inCart.reduce(
    (acc: number, item: any) =>
      acc +
      (couponData?.data?.period && item.priceType === 'recurring'
        ? 0
        : item.price),
    0
  );
  let shipping_price: any = 0;
  if (cart?.shippingMethod === 'direct') {
    shipping_price = shipping_direct?.price;
  } else if (cart?.shippingMethod === 'posti') {
    shipping_price = shipping_posti?.price;
  }
  cart.shippingPrice = couponData?.data?.free_shipping ? 0 : shipping_price;
  // cart.taxPrice = addDecimals(Number((0.15 * cart.price).toFixed(2)))
  //@ts-ignore
  cart.totalPrice = Number(cart.price) + Number(cart.shippingPrice);

  // @ts-ignore
  return (
    <>
      <StepsHeader step1 step2 step3 step4 user={2} payment={2} account={1} />
      <h1 className='placeorder-header text-center'>Yhteenveto</h1>
      <Container>
        <Row>
          <Col md={10} className='order-info-col'>
            <ListGroup variant='flush'>
              <ListGroup.Item>
                <h2 className='placeorder-headers'>Toimitustapa </h2>
                <Row>
                  <Col xs={8} sm={9}>
                    <p className='placeorder-texts selected-product'>
                      {cart.shippingMethod === 'direct' &&
                        'Toimitus seniorille kotisairaanhoitoon, palveluasuntoon tai yhteistyöhoivakotiin'}
                      {cart.shippingMethod === 'posti' &&
                        'Toimitus asiakkaalle Postin noutopisteeseen'}
                    </p>
                  </Col>
                  <Col xs={4} sm={3}>
                    <p className='placeorder-texts selected-product selected-product-price'>
                      {cart.shippingPrice} €
                    </p>
                  </Col>
                </Row>
                {cart.shippingMethod === 'posti' && cart.usePickupPoint && (
                  <p className='placeorder-texts'>
                    {`${cart.pickupPoint?.name} (${cart.pickupPoint?.address1}, ${cart.pickupPoint?.city})`}
                  </p>
                )}
                {cart.shippingMethod === 'posti' && (
                  <p className='placeorder-texts'>
                    <b>Osoite:</b> {cart.address}, {cart.postalCode},{' '}
                    {cart.city}
                  </p>
                )}
                {cart.shippingMethod === 'direct' && (
                  <p className='placeorder-texts'>
                    <b>Toimituskohde:</b>{' '}
                    {list.find((el) => el.reference === cart.nursingHome)?.name}
                  </p>
                )}
              </ListGroup.Item>

              <ListGroup.Item>
                <h2 className='placeorder-headers'>Maksu</h2>
                <p className='placeorder-texts'>
                  {cart.paymentMethod === 'card' && 'Pankki- tai luottokortti'}
                  {cart.paymentMethod === 'email_billing' && 'Sähköpostilasku'}
                  {cart.paymentMethod === 'contract_billing' &&
                    'Laskutussopimus kotisairaanhoidon, hoivakodin tai palveluasunnon kautta'}
                </p>
              </ListGroup.Item>

              <ListGroup.Item>
                <h2 className='placeorder-headers'>Tilaus</h2>
                {cart.inCart.length === 0 ? (
                  <Alert
                    style={{
                      background: 'rgba(78,81,128,0.8)',
                      color: '#ffffff',
                      marginBottom: 42,
                    }}
                  >
                    Ostoskorisi on tyhjä{' '}
                    <Link
                      to='/tilaa'
                      style={{
                        color: '#ffffff',
                      }}
                    >
                      Valitse palvelu
                    </Link>
                  </Alert>
                ) : (
                  <ListGroup variant='flush'>
                    {cart.inCart.map((item: any, index) => (
                      <ListGroup.Item key={index} className='selected-item'>
                        <Row>
                          <Col xs={8} sm={9}>
                            <p className='placeorder-texts selected-product'>
                              {item.name}
                            </p>
                          </Col>
                          <Col xs={4} sm={3}>
                            <p className='placeorder-texts selected-product selected-product-price'>
                              {couponData?.data?.period &&
                              item.priceType === 'recurring'
                                ? 0
                                : item.price}{' '}
                              €
                            </p>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                )}
              </ListGroup.Item>
              <ListGroup.Item>
                <h2 className='placeorder-headers'>
                  Maksuton testijakso – koodi
                </h2>
                <Row>
                  <Col xs={12} sm={8} md={7}>
                    <Form.Group controlId='member-username'>
                      <Form.Control
                        type='text'
                        placeholder='Maksuton testijakso – koodi'
                        name='coupon'
                        value={coupon || ''}
                        // @ts-ignore
                        onChange={(event) => setCoupon(event.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  {coupon && coupon !== cart.coupon && (
                    <Col xs={12} sm={4} md={2}>
                      <Button
                        className='tallenna'
                        style={{ margin: 0 }}
                        onClick={() => {
                          setCouponHandler(coupon);
                        }}
                      >
                        Käytä
                      </Button>
                    </Col>
                  )}
                  {cart.coupon && (
                    <Col xs={2}>
                      <Button
                        className='tallenna'
                        style={{ margin: 0 }}
                        onClick={() => {
                          dispatch(setCouponMethod(null));
                          setCoupon(null);
                          setCouponData(null);
                        }}
                      >
                        Poista
                      </Button>
                    </Col>
                  )}
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <h2 className='placeorder-headers'>Loppusumma</h2>
                <Row>
                  <Col xs={9} sm={10} className='placeorder-texts'>
                    <strong>Loppusumma</strong>
                    {cart.coupon && (
                      <div>
                        <strong>
                          Sinulla on maksuton testijakso. Testijakson ajan
                          palvelun hinta on 0 €.
                        </strong>
                      </div>
                    )}
                  </Col>
                  <Col
                    xs={3}
                    sm={2}
                    className='placeorder-texts selected-product-price'
                  >
                    <strong>{cart.totalPrice} €</strong>
                  </Col>
                </Row>
              </ListGroup.Item>

              <Row>
                <Col sm={6}>
                  <Button
                    type='button'
                    className='placeorder-back-button'
                    onClick={handleClick}
                  >
                    Takaisin
                  </Button>
                </Col>
                <Col sm={6}>
                  <Elements stripe={stripePromise}>
                    <OrderForm disabled={coupon && coupon !== cart.coupon} />
                  </Elements>
                </Col>
              </Row>
            </ListGroup>
          </Col>
        </Row>
      </Container>
      <StepsNotification />
    </>
  );
};

export default PlaceOrder;
