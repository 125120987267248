import {HashLink} from 'react-router-hash-link';

const ServicesAccordionTable = () => {
    return (
        <div className="palvelut-table-div">
            <table
                className="palvelut-table"
            >
                <colgroup>
                    <col
                        style={{
                            width: '40%',
                        }}
                    />
                    <col
                        style={{
                            width: '20%',
                        }}
                    />
                    <col
                        style={{
                            width: '20%',
                        }}
                    />
                    <col
                        style={{
                            width: '20%',
                        }}
                    />
                </colgroup>
                <thead>
                <tr>
                    <th/>
                    <th>
                        <h1
                            className='accordion-button-header'
                        >
                            <strong>
                                Huoleton
                            </strong>
                        </h1>
                        <div
                            className='accordion-button-text'
                        >
                            Kun tabletin käyttö on vaikeaa ja toiveena on lähinnä videopuhelut. Edullisin ja pienin.
                        </div>
                    </th>
                    <th>
                        <h1
                            className='accordion-button-header'
                        >
                            <strong>
                                Helppo
                            </strong>
                        </h1>
                        <div
                            className='accordion-button-text'
                        >
                            Kun tabletin käyttö on vaikeaa tai onnistuu osittain. Suosituin.
                        </div>
                    </th>
                    <th>
                        <h1
                            className='accordion-button-header'
                        >
                            <strong>
                                Mestari
                            </strong>
                        </h1>
                        <div
                            className='accordion-button-text'
                        >
                            Kun tabletin käyttö onnistuu vaivatta. Aktiiviselle seniorille.
                        </div>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>
                        Tabletti<sup>
                        <HashLink className="linkki" to='#footnote-1'>
                            1
                        </HashLink>
                    </sup> ja jalusta
                    </td>
                    <td>Samsung Galaxy Tab A7 Lite, LTE</td>
                    <td>Samsung Galaxy Tab A7/A8, LTE</td>
                    <td>IPad 10,2”, LTE tai<br/> Samsung Galaxy Tab<br/> A7/A8, LTE</td>
                </tr>
                <tr>
                    <td>
                        Näytön koko
                    </td>
                    <td>8,7” (pienin)</td>
                    <td>10,4” / 10,5”</td>
                    <td>10,2” / 10,4” / 10,5”</td>
                </tr>
                <tr>
                    <td>Sim-&shy;rajaton data</td>
                    <td>
                        <i className='icon fas fa-check-circle fa-4x'/>
                    </td>
                    <td>
                        <i className='icon fas fa-check-circle fa-4x'/>
                    </td>
                    <td>
                        <i className='icon fas fa-check-circle fa-4x'/>
                    </td>
                </tr>
                <tr>
                    <td>Seniori&shy;ystävälliset asetukset auto&shy;matisoitu<sup>
                        <HashLink className="linkki" to='#footnote-2'>
                            2
                        </HashLink>
                    </sup></td>
                    <td>
                        <i className='icon fas fa-check-circle fa-4x'/>
                    </td>
                    <td>
                        <i className='icon fas fa-check-circle fa-4x'/>
                    </td>
                    <td>osittain</td>
                </tr>
                <tr>
                    <td>Helppo video&shy;puhelu Digihappy apilla</td>
                    <td>
                        <i className='icon fas fa-check-circle fa-4x'/>
                    </td>
                    <td>
                        <i className='icon fas fa-check-circle fa-4x'/>
                    </td>
                    <td>
                        <i className='icon fas fa-check-circle fa-4x'/>
                    </td>
                </tr>
                <tr>
                    <td>Video&shy;puhelu avautuu halutta&shy;essa ilman koske&shy;tusta</td>
                    <td>
                        <i className='icon fas fa-check-circle fa-4x'/>
                    </td>
                    <td>
                        <i className='icon fas fa-check-circle fa-4x'/>
                    </td>
                    <td>
                        <i className='icon fas fa-check-circle fa-4x'/> (Samsung)
                    </td>
                </tr>
                <tr>
                    <td>Valitut, suositut sovel&shy;lukset</td>
                    <td>rajatusti</td>
                    <td>
                        <i className='icon fas fa-check-circle fa-4x'/>
                    </td>
                    <td>
                        <i className='icon fas fa-check-circle fa-4x'/>
                    </td>
                </tr>
                <tr>
                    <td>
                        Omat lisä&shy;sovel&shy;lukset<sup>
                        <HashLink className="linkki" to='#footnote-3'>
                            3
                        </HashLink>
                    </sup>
                    </td>
                    <td/>
                    <td/>
                    <td>
                        <i className='icon fas fa-check-circle fa-4x'/>
                    </td>
                </tr>
                <tr>
                    <td>Yhte&shy;ystiedot valmiina</td>
                    <td>
                        <i className='icon fas fa-check-circle fa-4x'/>
                    </td>
                    <td>
                        <i className='icon fas fa-check-circle fa-4x'/>
                    </td>
                    <td>
                        <i className='icon fas fa-check-circle fa-4x'/>
                    </td>
                </tr>
                <tr>
                    <td>
                        Aloitus&shy;neuvonta, etähallinta, jatkuva it-tuki<sup>
                        <HashLink className="linkki" to='#footnote-4'>
                            4
                        </HashLink>
                    </sup>
                    </td>
                    <td>
                        <i className='icon fas fa-check-circle fa-4x'/><sup>
                        <HashLink className="linkki" to='#footnote-4'>
                            4
                        </HashLink>
                    </sup>
                    </td>
                    <td>
                        <i className='icon fas fa-check-circle fa-4x'/><sup>
                        <HashLink className="linkki" to='#footnote-4'>
                            4
                        </HashLink>
                    </sup>
                    </td>
                    <td>
                        <i className='icon fas fa-check-circle fa-4x'/><sup>
                        <HashLink className="linkki" to='#footnote-4'>
                            4
                        </HashLink>
                    </sup>
                    </td>
                </tr>
                <tr>
                    <td>Päivi&shy;tykset, tietoturva</td>
                    <td>
                        <i className='icon fas fa-check-circle fa-4x'/>
                    </td>
                    <td>
                        <i className='icon fas fa-check-circle fa-4x'/>
                    </td>
                    <td>
                        <i className='icon fas fa-check-circle fa-4x'/>
                    </td>
                </tr>
                <tr>
                    <td>Perhetili, myös ruotsin&shy;kielinen palvelu</td>
                    <td>
                        <i className='icon fas fa-check-circle fa-4x'/>
                    </td>
                    <td>
                        <i className='icon fas fa-check-circle fa-4x'/>
                    </td>
                    <td>
                        <i className='icon fas fa-check-circle fa-4x'/>
                    </td>
                </tr>
                <tr>
                    <td>
                        Maksut&shy;tomat äänikirjat<sup>
                        <HashLink className="linkki" to='#footnote-5'>
                            5
                        </HashLink>
                    </sup>
                    </td>
                    <td>
                        <i className='icon fas fa-check-circle fa-4x'/>
                    </td>
                    <td>
                        <i className='icon fas fa-check-circle fa-4x'/>
                    </td>
                    <td>
                        <i className='icon fas fa-check-circle fa-4x'/>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}

export default ServicesAccordionTable
