import {Link} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {Card, Col} from 'react-bootstrap'

import {addToShoppingcart, removeFromShoppingcart} from '../redux/actions'
import {AppState} from "../redux/types";

const Service = ({service}: any) => {
    const dispatch = useDispatch()
    const selectedServices = useSelector(
        (state: AppState) => state.cart.inCart
    )

    let inBasket = selectedServices.find(element => element?.id === service.id);
    console.log(inBasket)

    const addToShoppingcartHandler = () => {
        dispatch(addToShoppingcart(service))
        // inBasket = selectedServices.find(element => element?.id === service.id);
        console.log(inBasket)
    }

    const removeFromShoppingcartHandler = () => {
        dispatch(removeFromShoppingcart(service.id))
        // inBasket = selectedServices.find(element => element?.id === service.id);
        console.log(inBasket)
    }

    return (
        <>
            <Col
                sm={12}
                md={4}
                lg={5}
                className='my-2 products-col'
            >
                <Card.Body
                    className='product-body'
                >
                    <Card.Title
                        className='product-title'
                    >
                        <strong>
                            {service.name}
                        </strong>
                    </Card.Title>
                    <Card.Text
                        as='div'
                        className='product product-text-left'
                    >
                        {service.descriptionFirst}
                    </Card.Text>
                </Card.Body>
            </Col>
            <Col
                sm={12}
                md={4}
                lg={5}
                className='my-2 next-to-price-col'
            >
                <Card.Body>
                    <Card.Text
                        as='div'
                        className='product product-text-right'
                    >
                        {service.descriptionSecond}
                        <br/>
                        <br/>
                        {service.isActive && service.price > 0 && (service.category === 'main' || service.category === 'main2') && service.priceType === 'recurring' &&
                            <p>
                                Laskutusjakso {service.period} kk. Palvelu on helppo irtisanoa, sitoudut aina
                                vain {service.period} kk.
                                <br/>
                                <Link
                                    to='/palveluehdot'
                                    target="_blank"
                                    className='order-conditions'
                                >
                                    Tutustu laskutus- ja palveluehtoihin.
                                </Link>
                            </p>
                        }
                    </Card.Text>
                </Card.Body>
            </Col>
            <Col
                sm={12}
                md={4}
                lg={2}
                className='price-col my-2'
            >
                {service.isActive ?
                    <Card.Body
                        className='buy-card'
                    >
                        {inBasket ?
                            <a
                                href={`#service_item-${service.id}`}
                                className='add-to-shoppingcart'
                                onClick={removeFromShoppingcartHandler}
                                style={{
                                    fontSize: '1.5rem',
                                    fontWeight: 600,
                                    lineHeight: 1.2,
                                    display: "block",
                                }}
                            >
                                Poista ostoskorista
                            </a>
                            : <a
                                href={`#service_item-${service.id}`}
                                className='add-to-shoppingcart'
                                onClick={addToShoppingcartHandler}
                                style={{
                                    fontSize: '1.5rem',
                                    fontWeight: 600,
                                    lineHeight: 1.2,
                                    display: "block",
                                }}
                            >
                                Lisää ostoskoriin
                            </a>
                        }

                        {service.photoShow && service.photoLink ? <img
                                onClick={inBasket ? removeFromShoppingcartHandler : addToShoppingcartHandler}
                            src={service.photoLink}
                            alt={service.photoDescription}
                            style={{
                                width: '92%',
                                height: 'auto',
                                margin: '4%',
                                cursor: 'pointer'
                            }}
                            /> :
                            <button
                                className="buy-button"
                                onClick={inBasket ? removeFromShoppingcartHandler : addToShoppingcartHandler}
                                type='button'
                            >
                                {inBasket ?
                                    <i
                                        className="fas fa-trash-alt"
                                    /> : <i
                                        className="fas fa-shopping-cart"
                                    />
                                }
                            </button>
                        }
                        <Card.Text
                            as='h4'
                            style={{
                                textAlign: 'center',
                            }}
                        >
                            <button
                                className='three-month-price'
                                style={{
                                    border: 'none',
                                }}
                                onClick={inBasket ? removeFromShoppingcartHandler : addToShoppingcartHandler}
                                type='button'
                            >
                                {`${(service.price / (service.priceType === 'recurring' ? service.period : 1)).toFixed((service.price / (service.priceType === 'recurring' ? service.period : 1)) % 1 === 0 ? 0 : 2)} € ${service.category === 'main' || service.category === 'main2' ? '/ kk' : ''}`}
                            </button>
                        </Card.Text>
                        {service.priceType === 'recurring' &&
                            <Card.Text
                                style={{
                                    textAlign: 'center',
                                    padding: 8,
                                    color: '#4e5180'
                                }}
                            >
                                <div>
                                    {service.price} € {service.category === 'main' || service.category === 'main2' ? `/ ${service.period} kk` : ''}
                                </div>
                                <div>
                                    Laskutusjakso ja
                                    <br/>
                                    sitoutuminen {service.period} kk
                                </div>
                            </Card.Text>
                        }
                    </Card.Body>
                    :
                    <Card.Body
                        className='buy-card'
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            height: '100%'
                        }}
                    >
                        <h4
                            className='add-to-shoppingcart'
                            style={{margin: '0 auto'}}
                        >
                            Palvelu tulossa pian
                        </h4>
                    </Card.Body>
                }
            </Col>
            <hr
                className='border-between-products'
            />
        </>
    )
}

export default Service
