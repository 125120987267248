import {
  AlertItem,
  GET_ALERTS_FAIL,
  GET_ALERTS_REQUEST,
  GET_ALERTS_SUCCESS,
  GET_SERVICES_FAIL,
  GET_SERVICES_REQUEST,
  GET_SERVICES_SUCCESS,
  Service
} from '../types'

export const getServicesRequest = () => {
    return {
        type: GET_SERVICES_REQUEST,
    }
}

export const getAlertsRequest = () => {
    return {
        type: GET_ALERTS_REQUEST,
    }
}

export const getServicesSuccess = (services: Service[]) => {
    return {
        type: GET_SERVICES_SUCCESS,
        payload: services
    }
}

export const getAlertsSuccess = (alerts: AlertItem[]) => {
    return {
        type: GET_ALERTS_SUCCESS,
        payload: alerts
    }
}

export const getServicesFail = (error: any) => {
    return {
        type: GET_SERVICES_FAIL,
        payload: {
            error
        }
    }
}
export const getAlertsFail = (error: any) => {
    return {
        type: GET_ALERTS_FAIL,
        payload: {
            error
        }
    }
}

  
  
  
  
  