import {call, put, select, takeLatest} from 'redux-saga/effects'
import axios from 'axios'

import {CreateOrderRequestAction, DeleteOrderRequestAction} from '../types'
import {
    createOrderFail,
    createOrderSuccess,
    deleteOrderFail,
    deleteOrderSuccess,
    getOrdersFail,
    getOrdersSuccess
} from '../actions'

function* getOrdersSaga() {
    try {
        //@ts-ignore
        const res = yield axios.get('/user/orders/all')
        console.log(res)
        yield put(getOrdersSuccess(res.data.payload))
    } catch (error) {
        console.log(error)
        yield put(getOrdersFail(error))
    }
}

function* createOrderSaga(action: CreateOrderRequestAction) {
    const order = action.payload

    try {
        console.log('action payload in saga', action.payload)
        //@ts-ignore
        const res = yield axios.post('/user/orders', {
            order,
        })
        console.log('response in saga', res)
        console.log('res.data', res.data)
        yield put(createOrderSuccess(res.data))
        yield call(getOrdersSaga)
    } catch (error) {
        yield put(createOrderFail(error))
    }
}

function* deleteOrderSaga(action: DeleteOrderRequestAction) {
    const orderId = action.payload
    try {
        //@ts-ignore
        const res = yield axios.delete(`/user/orders/${orderId}`)
        if (res.status === 200) {
            yield put(deleteOrderSuccess())
            yield call(getOrdersSaga)
        }
    } catch (error) {
        yield put(deleteOrderFail(error))
    }
}

function* saveState() {
    //@ts-ignore
    const state = yield select()
    yield localStorage.setItem('initState', JSON.stringify(state))
}

const sagaWatcher = [
    takeLatest('GET_ORDERS_REQUEST', getOrdersSaga),
    takeLatest('CREATE_ORDER_REQUEST', createOrderSaga),
    takeLatest('DELETE_ORDER_REQUEST', deleteOrderSaga),
    takeLatest('*', saveState),
]

export default sagaWatcher
