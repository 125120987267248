import {
    ADD_TO_CART,
    REMOVE_FROM_CART,
    RESET_CART,
    SAVE_PAYMENT_METHOD,
    SAVE_SHIPPING_ADDRESS,
    SAVE_STRIPE_PAYMENT_METHOD,
    SET_COUPON,
    Service,
} from '../types'


export const addToShoppingcart = (service: Service) => {
    return {
        type: ADD_TO_CART,
        payload: {
            service,
        }
    }
}

export const removeFromShoppingcart = (id: number) => {
    return {
        type: REMOVE_FROM_CART,
        payload: {
            id,
        }
    }
}

export const ResetShoppingcart = () => {
    return {
        type: RESET_CART,
        payload: {}
    }
}

export const saveShippingAddress = (data: any) => {
    return {
        type: SAVE_SHIPPING_ADDRESS,
        payload: data,
    }
}

export const savePaymentMethod = (data: any) => {
    return {
        type: SAVE_PAYMENT_METHOD,
        payload: data,
    }
}

export const saveStripePaymentMethod = (data: any) => {
    return {
        type: SAVE_STRIPE_PAYMENT_METHOD,
        payload: data,
    }
}

export const setCouponMethod = (data: any) => {
    return {
        type: SET_COUPON,
        payload: data,
    }
}