import {
  getSeniorHomesRequest,
  getUsersRequest,
  getUserEditFormData,
  setUserEditFormDataRequest,
} from '../redux/actions';
import { AppState } from '../redux/types';
import { LinkContainer } from 'react-router-bootstrap';
import Autocomplete from './Autocomplete';
import SwitchButton from './SwitchButton';
import { getOptions, getDefaultOptions } from '../utils/autocomplete';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Container, Form, Row, InputGroup } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
// import axios from "axios";
// import { toast } from 'react-toastify';

const UserEditForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const user = useSelector((state: AppState) => state.user);
  // const resources = useSelector((state: AppState) => state.seniorHomes);
  const nurseStaff: any = useSelector((state: AppState) => state.nurses.list);
  const users: any = useSelector((state: AppState) => state.users);
  const { profile } = users;

  const [formData, setFormData] = useState({
    id: '',
    username: '',
    email: '',
    firstName: '',
    lastName: '',
    mobileNumber: '',
    skypeEmail: '',
    role: '',
    isAdmin: false,
    readOnly: true,
    isSenior: false,
    customerId: '',
    department: '',
    nursingHome: '',
    homeAddress: '',
    homeCity: '',
    homePostalCode: '',
    nursingHomeAddress: profile?.nursingHomeAddress,
    password: '',
    hasItSupport: true,
    hasNurseSupport: true,
    nurses: [],
    chosenService: profile?.chosenService,
    tabletLanguage: profile?.tabletLanguage,
    autoAnswer: profile?.autoAnswer,
    serviceOption: profile?.serviceOption,
    hasNurseCalling: false,
  });
  const [assignedNurses, setAssignedNurses] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (user && (user.isAdmin || user.role === 'it_support')) {
      dispatch(getUserEditFormData(id));
      dispatch(getUsersRequest());
      dispatch(getSeniorHomesRequest());
    } else {
      history.push('/kirjaudu');
    }
    return () => {};
  }, [dispatch, history, user, id]);

  useEffect(() => {
    const _nurses = profile.isSenior
      ? getDefaultOptions(nurseStaff, profile.nurses)
      : [];
    const _hasItSupport = profile.hasOwnProperty('hasItSupport')
      ? profile.hasItSupport === 1
        ? true
        : false
      : true;
    const _hasNurseSupport = profile.hasOwnProperty('hasNurseSupport')
      ? profile.hasNurseSupport === 1
        ? true
        : false
      : true;
    const _hasNurseCalling = profile.hasOwnProperty('hasNurseCalling')
      ? profile.hasNurseCalling === 1
        ? true
        : false
      : true;

    setFormData((prevValue: any) => {
      return {
        ...prevValue,
        ...profile,
        nurses: _nurses,
        hasItSupport: _hasItSupport,
        hasNurseSupport: _hasNurseSupport,
        hasNurseCalling: _hasNurseCalling,
      };
    });
    setAssignedNurses(_nurses);
  }, [users, profile, nurseStaff]);

  const [showPass, setShowPass] = useState(false);
  const showPassHandler = () => {
    setShowPass((current) => !current);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setFormData((prevValue: any) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const handleChangeBoolean = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.value === 'true');
    setFormData((prevValue: any) => {
      return {
        ...prevValue,
        [e.target.name]: e.target.value === 'true',
      };
    });
  };

  const handleAssignedNurses = (_nurses: any) => {
    setFormData((prevValue: any) => {
      return {
        ...prevValue,
        nurses: _nurses,
        hasNurseSupport:
          _nurses.length === 0 ? false : prevValue.hasNurseSupport,
      };
    });
    setAssignedNurses(_nurses);
    if (_nurses.length > 0) {
      setError(false);
    }
  };

  const handleItSupport = () => {
    setFormData((prevValue: any) => {
      return {
        ...prevValue,
        hasItSupport: !formData.hasItSupport,
      };
    });
  };

  const handleToggleButton = (field: string) => {
    if (field === 'nurseSupport') {
      setFormData((prevValue: any) => {
        return {
          ...prevValue,
          hasNurseSupport: !formData.hasNurseSupport,
          nurses: !formData.hasNurseSupport === true ? assignedNurses : [],
          hasNurseCalling: !formData.hasNurseSupport
            ? prevValue.hasNurseCalling
            : false,
        };
      });
    } else if (field === 'nurseCalling') {
      setFormData((prevValue: any) => {
        return {
          ...prevValue,
          hasNurseCalling: !formData.hasNurseCalling,
        };
      });
    }
  };

  const submitHandler = (e: React.FormEvent) => {
    e.preventDefault();
    const { hasItSupport, hasNurseSupport, nurses, ...userEditFormData } =
      formData;
    let _formData: any = {
      id: formData.id,
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      username: formData.username,
      mobileNumber: formData.mobileNumber,
    };
    if (formData.role === 'senior' || formData.isSenior === true) {
      _formData = {
        ..._formData,
        nurses: formData.nurses.map((nurse: any) => nurse.value),
        hasItSupport: formData.hasItSupport ? 1 : 0,
        hasNurseSupport: formData.hasNurseSupport ? 1 : 0,
        hasNurseCalling: formData.hasNurseCalling ? 1 : 0,
      };
    }
    if (_formData.hasNurseSupport === 1 && _formData.nurses.length === 0) {
      setError(true);
    } else {
      dispatch(
        setUserEditFormDataRequest({
          _formData: _formData,
          userEditFormData: userEditFormData,
        })
      );
      history.push('/admin/users');
    }
  };
  console.log('dept', formData);
  if (profile) {
    return (
      <Container className='register-form-container'>
        <Form onSubmit={submitHandler} className='service-form'>
          <Form.Group controlId='user-id'>
            <Form.Label>ID</Form.Label>
            <Form.Control
              type='number'
              placeholder='ID'
              value={formData.id}
              readOnly={true}
            />
          </Form.Group>
          <Form.Group controlId='user-id'>
            <Form.Label>R-ID</Form.Label>
            <Form.Control
              type='number'
              placeholder='R-ID'
              value={profile?.groupId}
              readOnly={true}
            />
          </Form.Group>
          <hr />
          <Form.Group controlId='service-role'>
            <Form.Label>Rooli</Form.Label>
            <Form.Control
              as='select'
              name='role'
              value={formData.role}
              onChange={handleChange}
              disabled={user.readOnly || !user.isAdmin}
            >
              <option value='customer'>Asiakas (customer)</option>
              <option value='senior'>Senior (senior)</option>
              <option value='member'>Jäsen (member)</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId='service-isAdmin'>
            <Form.Label>Admin?</Form.Label>
            <Form.Control
              as='select'
              name='isAdmin'
              value={
                typeof formData.isAdmin === 'boolean'
                  ? formData.isAdmin.toString()
                  : 'false'
              }
              disabled={
                user.readOnly || user.id === profile.id || !user.isAdmin
              }
              onChange={handleChangeBoolean}
            >
              <option value='true'>Joo</option>
              <option value='false'>Ei</option>
            </Form.Control>
          </Form.Group>
          {formData.isAdmin && (
            <Form.Group controlId='service-readOnly'>
              <Form.Label>readOnly?</Form.Label>
              <Form.Control
                as='select'
                name='readOnly'
                value={
                  typeof formData.readOnly === 'boolean'
                    ? formData.readOnly.toString()
                    : 'false'
                }
                disabled={user.readOnly || user.id === profile.id}
                onChange={handleChangeBoolean}
              >
                <option value='true'>Joo</option>
                <option value='false'>Ei</option>
              </Form.Control>
            </Form.Group>
          )}
          <hr />
          <Form.Group controlId='service-username'>
            <Form.Label>Käyttäjänimi</Form.Label>
            <Form.Control
              type='text'
              placeholder='Käyttäjänimi'
              name='username'
              value={formData.username}
              onChange={handleChange}
              readOnly={user.readOnly || !user.isAdmin}
            />
          </Form.Group>
          <Form.Group controlId='service-email'>
            <Form.Label>Sähköposti</Form.Label>
            <Form.Control
              type='text'
              placeholder='Sähköposti'
              name='email'
              value={formData.email}
              onChange={handleChange}
              readOnly={user.readOnly || !user.isAdmin}
            />
          </Form.Group>
          <Form.Group controlId='service-firstName'>
            <Form.Label>Etunimi</Form.Label>
            <Form.Control
              type='text'
              placeholder='Etunimi'
              name='firstName'
              value={formData.firstName}
              onChange={handleChange}
              readOnly={user.readOnly || !user.isAdmin}
            />
          </Form.Group>
          <Form.Group controlId='service-lastName'>
            <Form.Label>Sukunimi</Form.Label>
            <Form.Control
              type='text'
              placeholder='Sukunimi'
              name='lastName'
              value={formData.lastName}
              onChange={handleChange}
              readOnly={user.readOnly || !user.isAdmin}
            />
          </Form.Group>
          <Form.Group controlId='service-mobileNumber'>
            <Form.Label>Matkapuhelinnumero</Form.Label>
            <Form.Control
              type='text'
              placeholder='Matkapuhelinnumero'
              name='mobileNumber'
              value={formData.mobileNumber}
              onChange={handleChange}
              readOnly={user.readOnly || !user.isAdmin}
            />
          </Form.Group>
          <Form.Group controlId='service-skypeEmail'>
            <Form.Label>Skype</Form.Label>
            <Form.Control
              type='text'
              placeholder='Skype'
              name='skypeEmail'
              value={formData.skypeEmail}
              onChange={handleChange}
              readOnly={user.readOnly || !user.isAdmin}
            />
          </Form.Group>

          <Form.Group controlId='service-isSenior'>
            <Form.Label>Itse seniori</Form.Label>
            <Form.Control
              as='select'
              name='isSenior'
              value={
                typeof formData.isSenior === 'boolean'
                  ? formData.isSenior.toString()
                  : 'false'
              }
              onChange={handleChangeBoolean}
              disabled={user.readOnly || !user.isAdmin}
            >
              <option value='true'>Joo</option>
              <option value='false'>Ei</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId='service-customerId'>
            <Form.Label>Customer ID (Stripe)</Form.Label>
            <Form.Control
              type='text'
              placeholder='Customer ID (Stripe)'
              name='customerId'
              value={formData.customerId}
              onChange={handleChange}
              readOnly={user.readOnly || !user.isAdmin}
            />
          </Form.Group>

          <Form.Group controlId='service-department'>
            <Form.Label>Osoitetiedot</Form.Label>
            <Form.Control
              as='select'
              name='department'
              value={formData.department}
              onChange={handleChange}
              disabled={user.readOnly || !user.isAdmin}
            >
              <option value={undefined}>Ei valittu</option>
              <option value='homeAddress'>Kotona</option>
              <option value='nursingHome'>Vanhainkodissa</option>
            </Form.Control>
          </Form.Group>

          {formData.department === 'homeAddress' && (
            <div>
              <Form.Group>
                <Form.Label>Katuosoite</Form.Label>
                <Form.Control
                  type='text'
                  name='homeAddress'
                  placeholder='Katuosoite'
                  value={formData.homeAddress}
                  onChange={handleChange}
                  readOnly={user.readOnly || !user.isAdmin}
                />
              </Form.Group>
              <Form.Group controlId='city'>
                <Form.Label>Postinumero</Form.Label>
                <Form.Control
                  type='text'
                  name='homePostalCode'
                  placeholder='Postinumero'
                  value={formData.homePostalCode}
                  onChange={handleChange}
                  readOnly={!user.isAdmin}
                />
              </Form.Group>
              <Form.Group controlId='city'>
                <Form.Label>Kaupunki</Form.Label>
                <Form.Control
                  type='text'
                  name='homeCity'
                  placeholder='Kaupunki'
                  value={formData.homeCity}
                  onChange={handleChange}
                  readOnly={!user.isAdmin}
                />
              </Form.Group>
            </div>
          )}
          {formData.department === 'nursingHome' && (
            <div>
              <Form.Group>
                <Form.Label>Vanhainkodin nimi</Form.Label>
                <Form.Control
                  type='text'
                  name='nursingHome'
                  placeholder='Vanhainkodin nimi'
                  value={formData.nursingHome}
                  onChange={handleChange}
                  readOnly={!user.isAdmin}
                />
              </Form.Group>
              <Form.Group controlId='city'>
                <Form.Label>Paikkakunta</Form.Label>
                <Form.Control
                  type='text'
                  name='nursingHomeAddress'
                  placeholder='Paikkakunta'
                  value={formData.nursingHomeAddress}
                  onChange={handleChange}
                  readOnly={!user.isAdmin}
                />
              </Form.Group>
            </div>
          )}

          {!user.readOnly &&
            (formData.role === 'senior' || formData.isSenior) && (
              <Form.Group controlId='service-password'>
                <Form.Label>
                  Salasana (Jätä tyhjäksi välttääksesi käyttäjän salasanan
                  nollaamisen)
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    type={showPass ? 'text' : 'password'}
                    placeholder='Salasana'
                    name='password'
                    value={formData.password}
                    onChange={handleChange}
                    disabled={user.isAdmin ? false : true}
                  />
                  <Button
                    variant='outline-secondary shadow-none show-password-btn'
                    onClick={showPassHandler}
                    disabled={user.isAdmin ? false : true}
                  >
                    <i
                      className={showPass ? 'fas fa-eye-slash' : 'fas fa-eye'}
                    ></i>
                  </Button>
                </InputGroup>
                <div className='show-password'>
                  Näet salasanasi klikkaamalla silmäkuvaketta
                </div>
                <small className='password-length'>Vähintään 11 merkkiä.</small>
              </Form.Group>
            )}

          {(formData.role === 'senior' || formData.isSenior) && (
            <>
              <Form.Group controlId='service-nurseSupport'>
                <Form.Label style={{ marginRight: '5rem' }}>
                  Nurse Support
                </Form.Label>
                <SwitchButton
                  isChecked={formData.hasNurseSupport}
                  handleChecked={() => handleToggleButton('nurseSupport')}
                  disabled={user.isAdmin ? false : true}
                />
              </Form.Group>
              <Form.Group controlId='service-nurseCalling'>
                <Form.Label style={{ marginRight: '5rem' }}>
                  Nurse Calling
                </Form.Label>
                <SwitchButton
                  isChecked={formData.hasNurseCalling}
                  handleChecked={() => handleToggleButton('nurseCalling')}
                  disabled={
                    user.isAdmin && formData.hasNurseSupport ? false : true
                  }
                />
              </Form.Group>
              <Form.Group controlId='service-addEditNurse'>
                <Form.Label>Add/Edit nurse</Form.Label>
                <Autocomplete
                  options={getOptions(nurseStaff, formData.nurses)}
                  handleOptions={handleAssignedNurses}
                  value={formData.nurses}
                  placeholder={'Enter Nurse Name/ID'}
                  isDisabled={!formData.hasNurseSupport || !user.isAdmin}
                />
                {error && (
                  <p style={{ color: 'red' }}>Please select any nurse</p>
                )}
              </Form.Group>

              <Form.Group controlId='service-itSupport'>
                <Form.Label style={{ marginRight: '7rem' }}>
                  IT Support
                </Form.Label>
                <SwitchButton
                  isChecked={formData.hasItSupport}
                  handleChecked={handleItSupport}
                  disabled={user.isAdmin ? false : true}
                />
              </Form.Group>
            </>
          )}

          {formData.role === 'customer' && (
            <Form.Group controlId='service-language'>
              <Form.Label>Kieli</Form.Label>
              <Form.Control
                as='select'
                name='tabletLanguage'
                value={formData.tabletLanguage}
                disabled={user.isAdmin ? false : true}
              >
                <option value=''>-</option>
                <option value='suomi'>Suomi</option>
                <option value='ruotsi'>Ruotsi</option>
              </Form.Control>
            </Form.Group>
          )}

          {formData.role === 'customer' && (
            <Form.Group controlId='service-autoanswer'>
              <Form.Label>Autoanswer</Form.Label>
              <Form.Control
                as='select'
                name='autoAnswer'
                value={formData.autoAnswer}
                disabled={user.isAdmin ? false : true}
              >
                <option value=''>-</option>
                <option value='true'>{`Videopuhelu avautuu automaattisesti ilman kosketusta${
                  formData.chosenService === 'mestari'
                    ? ' (mahdollinen Samsung tabletissa)'
                    : ''
                }`}</option>
                <option value='false'>{`Videopuhelu ei avaudu automaattisesti ilman kosketusta${
                  formData.chosenService === 'mestari'
                    ? ' (Samsung tai Apple)'
                    : ''
                }`}</option>
              </Form.Control>
            </Form.Group>
          )}

          {formData.role === 'customer' &&
            ['helppo', 'huoleton', 'suosikkipalvelu'].includes(
              formData?.chosenService
            ) && (
              <Form.Group controlId='service-option'>
                <Form.Label>Sovellukset</Form.Label>
                <Form.Control
                  as='select'
                  name='serviceOption'
                  value={formData.serviceOption}
                  disabled={user.isAdmin ? false : true}
                >
                  <option value=''>-</option>
                  <option value='huoleton1'>Videopuhelu</option>
                  <option value='huoleton2'>Videopuhelu, radio</option>
                  <option value='huoleton3'>Videopuhelu, äänikirja</option>
                  <option value='huoleton4'>Videopuhelu, Yle Areena</option>
                  <option value='huoleton5'>
                    Videopuhelu, radio, äänikirja, Yle Areena
                  </option>
                  <option value='helppo1'>Videopuhelu</option>
                  <option value='helppo2'>Videopuhelu, äänikirja, radio</option>
                  <option value='helppo3'>
                    Videopuhelu, äänikirja, radio, Yle Areena, lehdet
                  </option>
                  <option value='helppo4'>
                    Videopuhelu, äänikirja, radio, Yle Areena, lehdet, pelit
                  </option>
                  <option value='helppo5'>
                    Videopuhelu, äänikirja, radio, Yle Areena, lehdet, taide
                  </option>
                  <option value='helppo6'>
                    Videopuhelu, äänikirja, radio, Yle Areena, lehdet, pelit,
                    taide, Spotify
                  </option>
                  <option value='helppo7'>
                    Videopuhelu, äänikirja, radio, Yle Areena, lehdet, pelit,
                    taide, Spotify, Whatsapp
                  </option>
                </Form.Control>
              </Form.Group>
            )}

          <Row>
            <Col>
              <LinkContainer to={`/admin/users`}>
                <Button className='tallenna paymentmethod-back-button'>
                  Takaisin
                </Button>
              </LinkContainer>
            </Col>
            {!user.readOnly && user.isAdmin && (
              <Col
                style={{
                  textAlign: 'right',
                }}
              >
                <Button
                  className='create-service-button tallenna'
                  type='submit'
                >
                  Tallenna
                </Button>
              </Col>
            )}
          </Row>
        </Form>
      </Container>
    );
  } else {
    return (
      <Container>
        <div>Käyttäjää ei löydy</div>
        <LinkContainer to={`/admin/users`}>
          <Button>Takaisin</Button>
        </LinkContainer>
      </Container>
    );
  }
};

export default UserEditForm;
