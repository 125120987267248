import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import axios from 'axios';
import { CookiesProvider } from 'react-cookie';
import App from './App';
import makeStore from './redux/store';
import LocalStorage from './local-storage';

axios.defaults.baseURL =
  process.env.REACT_APP_API_ENDPOINT || 'https://api.digihappy.fi/';

const store = makeStore();

axios.interceptors.request.use((config: any) => {
  const baseUrl =
    process.env.REACT_APP_API_ENDPOINT || 'https://api.digihappy.fi/';
  const token = LocalStorage.getToken();
  if (config.baseURL?.startsWith(baseUrl) && token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

function PageToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

// ReactDOM.render(
//   <CookiesProvider>
//     <React.Fragment>
//       <Router>
//         <Provider store={store}>
//           <PageToTop />
//           <App />
//         </Provider>
//       </Router>
//     </React.Fragment>
//   </CookiesProvider>,
//   document.getElementById('root')
// );

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <CookiesProvider>
    <React.Fragment>
      <Router>
        <Provider store={store}>
          <PageToTop />
          <App />
        </Provider>
      </Router>
    </React.Fragment>
  </CookiesProvider>
);
