import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Button, Col, Container, Form, InputGroup, Row} from 'react-bootstrap'
import {useHistory, useParams} from "react-router-dom";
import {createBlogRequest, getBlogsRequest} from '../redux/actions'
import {AppState} from "../redux/types";
import MDEditor from "@uiw/react-md-editor";
import {LinkContainer} from "react-router-bootstrap";
import replaceSpecialCharacters from "replace-special-characters"

const blogURL = (title: any) => `${replaceSpecialCharacters(title).trim().split(/[\W]+/).join('-')}`;

const BlogForm = () => {
    const {id} = useParams<{ id: string }>()
    const resources = useSelector((state: AppState) => state.blogs)
    const {list} = resources
    let blog = undefined;
    try {
        blog = list?.find(o => o.id === parseInt(id));
    } catch (error) {
    }
    console.log(blog)
    const [formData, setFormData] = useState({
        id: blog ? parseInt(blog.id) : -1,
        title: blog?.title || '',
        cover: blog?.cover || '',
        cover_type: blog?.cover_type || 'image',
        topics: blog?.topics.map(function (i: any) {
            return i.name
        }).join() || '',
        content_preview: blog?.content_preview || '',
        author_id: undefined,
        featured: blog?.featured || false,
        createdAt: undefined,
        updatedAt: undefined,
        url: blog?.url
    })

    const [content, setContent] = useState(blog?.content || '');

    const dispatch = useDispatch()
    const history = useHistory()

    const user = useSelector((state: AppState) => state.user)
    useEffect(() => {
        if (user && user.isAdmin) {
            dispatch(getBlogsRequest())
        } else {
            history.push('/kirjaudu')
        }
        return () => {
        }
    }, [dispatch, history, user])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let {value, name} = e.target;
        if (name === 'url') {
            value = blogURL(value)
        }
        setFormData((prevValue: any) => {
            return {
                ...prevValue,
                [name]: value,
            }
        })

        if (name === 'title') {
            setFormData((prevValue: any) => {
                return {
                    ...prevValue,
                    url: blogURL(value),
                }
            })
        }
    }

    const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {checked, name} = e.target
        console.log(name)
        console.log(checked)
        setFormData((prevValue: any) => {
            return {
                ...prevValue,
                [name]: checked,
            }
        })
    }

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault()
        dispatch(createBlogRequest({
            id: formData.id === -1 ? undefined : formData.id,
            title: formData.title,
            cover: formData.cover,
            cover_type: formData.cover_type,
            topics: formData.topics,
            content_preview: formData.content_preview,
            content: content,
            author_id: formData.author_id,
            featured: formData.featured,
            createdAt: formData.createdAt,
            updatedAt: formData.updatedAt,
            url: formData.url
        }))
        history.push('/admin/blogs')
        dispatch(getBlogsRequest())
    }

    const [editURL, setEditURL] = useState(false);
    const editURLHandler = () => {
        setEditURL(current => !current)
    }

    const generateURLHandler = () => {
        setFormData((prevValue: any) => {
            return {
                ...prevValue,
                url: blogURL(prevValue?.title),
            }
        })
    }

    return (
        <Container className='register-form-container'>
            <Form onSubmit={submitHandler} className='service-form'>
                <Form.Group controlId='blog-title'>
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                        placeholder='Text'
                        name='title'
                        value={formData.title}
                        onChange={handleChange}
                        readOnly={user.readOnly}
                    />
                </Form.Group>
                <Form.Group controlId='blog-url'>
                    <Form.Label>URL</Form.Label>
                    <InputGroup>
                        <Form.Control
                            type='text'
                            name='url'
                            placeholder='Autogenerated URL'
                            value={formData.url}
                            onChange={handleChange}
                            readOnly={user.readOnly || !editURL}
                        />
                        <Button
                            variant="outline-secondary shadow-none show-refresh-btn"
                            onClick={generateURLHandler}
                            disabled={user.readOnly}
                        >
                            <i className="fas fa-sync-alt"></i>
                        </Button>
                        <Button
                            variant="outline-secondary shadow-none show-password-btn"
                            onClick={editURLHandler}
                            disabled={user.readOnly}
                        >
                            <i className={editURL ? 'fas fa-lock' : 'fas fa-unlock-alt'}></i>
                        </Button>
                    </InputGroup>
                    <div
                        style={{
                            padding: '8px 0',
                            color: '#666555',
                            fontSize: '0.8rem',
                        }}
                    >
                        {formData.url ?
                            `${window.location.origin}/blog/${formData.url}` :
                            `${window.location.origin}/blog/${formData.id}`
                        }
                    </div>
                </Form.Group>
                <Form.Group controlId="blog-featured">
                    <Form.Check
                        label="Featured"
                        type="checkbox"
                        name='featured'
                        checked={formData.featured}
                        onChange={handleCheck}
                        disabled={user.readOnly}
                    />
                </Form.Group>
                <Form.Group controlId="blog-cover_type">
                    <Form.Label>Cover type</Form.Label>
                    <Form.Control
                        as="select"
                        name="cover_type"
                        aria-label="Image"
                        value={formData.cover_type}
                        onChange={handleChange}
                    >
                        <option
                            id='image'
                            value="image"
                        >
                            Image
                        </option>
                        <option
                            id='video'
                            value="video"
                        >
                            Video
                        </option>
                        <option
                            id='youtube_video'
                            value="youtube_video"
                        >
                            YouTube video
                        </option>
                    </Form.Control>
                </Form.Group>
                <Form.Group controlId='blog-cover'>
                    <Form.Label>Cover</Form.Label>
                    <Form.Control
                        placeholder='Cover'
                        name='cover'
                        value={formData.cover}
                        onChange={handleChange}
                        readOnly={user.readOnly}
                    />
                    {formData.cover_type === 'video' && <small className="for-example">
                        Paste full link: <span
                        style={{color: 'lightgrey'}}>https://senioritabletti-image-bucket.s3.eu-north-1.amazonaws.com/C0009.MP4</span>
                    </small>}
                    {formData.cover_type === 'youtube_video' && <small className="for-example">
                        Insert YouTube video ID: <span style={{color: 'lightgrey'}}>foOL310imec</span>
                    </small>}
                </Form.Group>
                <Form.Group controlId='blog-end'>
                    <Form.Label>Topics (use <code>,</code> as separator)</Form.Label>
                    <Form.Control
                        placeholder={"Topics"}
                        name='topics'
                        value={formData.topics}
                        onChange={handleChange}
                        readOnly={user.readOnly}
                    />
                </Form.Group>
                <Form.Group controlId='blog-cover'>
                    <Form.Label>Content preview</Form.Label>
                    <Form.Control
                        placeholder='Content preview'
                        name='content_preview'
                        value={formData.content_preview}
                        onChange={handleChange}
                        readOnly={user.readOnly}
                    />
                </Form.Group>
                {user.readOnly ? <MDEditor.Markdown
                    source={content}
                /> : <MDEditor
                    value={content}
                    // @ts-ignore
                    onChange={setContent}
                />
                }
                <Row>
                    <Col>
                        <LinkContainer to={`/admin/blogs`}>
                            <Button className='tallenna paymentmethod-back-button'>
                                Takaisin
                            </Button>
                        </LinkContainer>
                    </Col>
                    {!user.readOnly &&
                        <Col
                            style={{
                                textAlign: 'right',
                            }}
                        >
                            <Button
                                className='create-service-button tallenna' type='submit'
                                disabled={!formData?.title}
                            >
                                Tallenna
                            </Button>
                        </Col>
                    }
                </Row>
            </Form>
        </Container>
    )
}

export default BlogForm
