import {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Button, Container, Form} from 'react-bootstrap'

import Message from '../components/Message'
import {sendContactMailRequest} from '../redux/actions'
import {AppState} from '../redux/types'

const ContactForm = () => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [text, setText] = useState('')
    const [message, setMessage] = useState('')
    const error = useSelector((state: AppState) => state.user.error)

    const dispatch = useDispatch()

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault()
        dispatch(sendContactMailRequest(email, name, text))
        setName('')
        setEmail('')
        setText('')
        setMessage('Kiitos, viestinne on lähetetty. Vastaamme teille mahdollisimman pian.')
    }

    return (
        <Container className='contact-container justify-content-md-center'>
            <div className='contact-form'>
                {!error && <Message variant="success">{message}</Message>}
                {error && <Message variant="danger">{error}</Message>}
                <Form onSubmit={submitHandler}>
                    <Form.Group controlId='contact-name'>
                        <Form.Label className='label-name'>Nimi</Form.Label>
                        <Form.Control
                            type='text'
                            placeholder='Nimi'
                            name='name'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId='contact-email'>
                        <Form.Label className='label'>Sähköpostiosoite</Form.Label>
                        <Form.Control
                            type='email'
                            name='email'
                            placeholder='Sähköpostiosoite'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId='contact-text'>
                        <Form.Label>Kirjoita viesti</Form.Label>
                        <Form.Control
                            className='textarea'
                            as='textarea'
                            name='text'
                            value={text}
                            onChange={(e) => setText(e.target.value)}
                            rows={3}
                        />
                    </Form.Group>
                    <Button type='submit' className='contact-button tallenna'>
                        Lähetä viesti
                    </Button>
                </Form>
                <p>
                    Tavoitat meidät myös sähköpostilla <a className='linkki'
                                                          href="mailto:digihappy@mediti.fi">digihappy@mediti.fi</a> tai
                    soita <a className='linkki' href="tel:010 517 2070">010 517 2070</a> tai WhatsAppaa <a
                    className='linkki' href=" https://wa.me/358503210100" target="_blank">050 321 0100</a>.
                </p>
                <p style={{marginBottom: 40,}}>
                    Otamme Sinuun henkilökohtaisesti yhteyttä.
                </p>
            </div>
        </Container>
    )
}

export default ContactForm
