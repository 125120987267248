import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Button, Col, Container, Row} from 'react-bootstrap'
import TextOnImage from '../components/TextOnImage'
import Loader from '../components/Loader'
import Message from '../components/Message'
import Service from '../components/Service'
import texts from '../texts.js'
import {getServicesRequest} from '../redux/actions'
import {AppState} from '../redux/types'
import {useHistory} from "react-router-dom";
import {Helmet} from "react-helmet";
import pic1 from "../images/pic1.png"
import pic2 from "../images/pic2.png"
import pic3 from "../images/pic3.png"
import pic4 from "../images/pic4.png"
import icon1 from "../images/icon1.jpg";
import icon2 from "../images/icon2.jpg";
import icon3 from "../images/icon3.jpg";

const Subscribe = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const currentDate = Date.now();

    useEffect(() => {
        dispatch(getServicesRequest())
        return () => {
        }
    }, [dispatch])

    const resources = useSelector((state: AppState) => state.resources)
    const {services, loading, error} = resources

    const resources2 = useSelector((state: AppState) => state.alerts)
    const {list} = resources2

    const selectedServices = useSelector(
        (state: AppState) => state.cart.inCart
    )

    const toCart = () => {
        history.push('/ostoskori')
    }

    const [fabWidth, setFabWidth] = useState(0)
    const ref = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (ref.current) {
            console.log(ref.current?.['offsetWidth']);
            const {current} = ref;
            const boundingRect = current?.getBoundingClientRect();
            const {width} = boundingRect;
            setFabWidth((width || 0) / 2);
        }
    }, [ref, selectedServices]);

    // @ts-ignore
    return (
        <>
            <Helmet>
                <title>Tilaa helppo Senioritabletti-palvelu | Digihappy</title>
                <meta
                    name="description"
                    content="Tilaa kuukausimaksullinen Senioritabletti-palvelu. Valitse kolmesta vaihtoehdosta seniorin toimintakyvyn mukaan ja liitä perheesi perhetiliin."
                />
                <link rel="canonical" href="https://digihappy.fi/tilaa"/>
            </Helmet>
            <TextOnImage
                height={`calc(100vh - ${56 + 56 * list?.filter(el => {
                    console.log(el)
                    console.log(currentDate)
                    // @ts-ignore
                    return (!el.start || (new Date(el.start)) <= currentDate) && (!el.end || (new Date(el.end)) >= currentDate);

                })?.length - 1}px)`}
                width='100vw'
                text4={texts[10].text4}
                href={texts[10].href}
                backgroundImage={'https://senioritabletti-image-bucket.s3.eu-north-1.amazonaws.com/grandmagrandson.jpg'}
                backgroundSize='cover'
                backgroundPosition='center top'
                backgroundRepeat='no-repeat'
                imgClassName='subscribepic'
                className4='subscribe-text-on-pic'
            />

            <Container fluid className='services-container services-container-80'
                       style={{
                           position: "relative",
                       }}
            >
                <div id='products' className='product-text-left subscribe-header'
                     style={{
                         fontWeight: 'normal',
                         fontSize: '1.3rem',
                         textAlign: 'left',
                         margin: 0,
                         padding: 15
                     }}
                >
                    <p>
                        Senioritabletti-palvelun voi tilata seniorin läheinen tai seniori itse.
                    </p>
                    <p>
                        Miksi Digihappy on edullinen?
                    </p>
                    <p>
                        Meillä palveluun kuuluu kaikki ja teemme kaiken puolestasi! Tabletti, jalusta, sim, rajaton
                        data, helpot videopuhelut, suositut sovellukset. Kaikki senioriystävälliseksi muokattuna ja
                        käyttövalmiina. Lisäksi jatkuva it-tuki ja etähallinta.
                    </p>
                    <p>
                        Tilaa palvelu! Muistathan valita myös palveluun kuuluvan jalustan.
                    </p>
                </div>
            </Container>
            <Container fluid className=' '
                       style={{
                           padding: 0
                       }}
            >
                <h2 id='products' className='subscribe-header subscribe-header-blue'>
                    <div
                        className='subscribe-header-blue-icon'
                        style={{
                            backgroundImage: `url(${icon1})`
                        }}
                    />
                    Tablettipalvelut seniorille ja perheelle
                </h2>
                <Container fluid className='services-container services-container-80'
                           style={{
                               padding: 0
                           }}
                >
                    {loading ? (
                        <Loader/>
                    ) : error ? (
                        <Message variant='danger'>{error}</Message>
                    ) : (
                        <>
                            {services &&
                                services.filter(function (el) {
                                    return el.category === 'main'
                                }).sort((a, b) => a.sortNumber - b.sortNumber).map((service: any, index) => (
                                    <div key={index}>
                                        <Row className='product-row'>
                                            <Service key={index} service={service}/>
                                        </Row>
                                        <hr/>
                                    </div>
                                ))}
                        </>
                    )}
                </Container>
                <h2 id='products' className='subscribe-header subscribe-header-blue'>
                    <div
                        className='subscribe-header-blue-icon'
                        style={{
                            backgroundImage: `url(${icon3})`
                        }}
                    />
                    Tablettipalvelut kotisairaanhoitoon ja palveluasuntoihin
                </h2>

                <Container fluid className='services-container services-container-80'
                           style={{
                               padding: 0
                           }}
                >
                    {loading ? (
                        <Loader/>
                    ) : error ? (
                        <Message variant='danger'>{error}</Message>
                    ) : (
                        <>
                            {services &&
                                services.filter(function (el) {
                                    return el.category === 'main2'
                                }).sort((a, b) => a.sortNumber - b.sortNumber).map((service: any, index) => (
                                    <div key={index}>
                                        <Row className='product-row'>
                                            <Service key={index} service={service}/>
                                        </Row>
                                        <hr/>
                                    </div>
                                ))}
                        </>
                    )}
                </Container>
                <h2 id='products' className='subscribe-header subscribe-header-blue'>
                    <div
                        className='subscribe-header-blue-icon'
                        style={{
                            backgroundImage: `url(${icon2})`
                        }}
                    />
                    Jalustat
                </h2>

                <Container fluid className='services-container services-container-80'
                           style={{
                               padding: 0
                           }}
                >
                    {loading ? (
                        <Loader/>
                    ) : error ? (
                        <Message variant='danger'>{error}</Message>
                    ) : (
                        <>
                            {services &&
                                services.filter(function (el) {
                                    return el.category === 'additional'
                                }).sort((a, b) => a.sortNumber - b.sortNumber).map((service: any, index) => (
                                    <div key={index}>
                                        <Row className='product-row'>
                                            <Service key={index} service={service}/>
                                        </Row>
                                        <hr/>
                                    </div>
                                ))}
                        </>
                    )}
                </Container>
                {selectedServices?.length > 0 && <div
                    className='sticky-div'
                >
                    <Container fluid className='services-container services-container-80'
                               style={{
                                   padding: 0
                               }}
                    >
                        <Row className='product-row'
                             style={{
                                 paddingBottom: 36,
                             }}
                        >
                            <Col
                                sm={0}
                                md={8}
                                lg={10}
                            >
                            </Col>
                            <Col
                                sm={12}
                                md={4}
                                lg={2}
                                style={{
                                    margin: 0,
                                    padding: 0,
                                }}
                            >
                                <Button
                                    type='button'
                                    className='sticky-btn'
                                    onClick={toCart}
                                >
                                    Siirry ostoskoriin
                                    <div
                                        className="subscribe-fab-xs d-sm-none"
                                    >
                                        <i className="fas fa-shopping-cart"
                                           style={{
                                               backgroundImage: 'none',
                                               color: "#ffffff",
                                           }}
                                        ></i>
                                        <span className="badge">{selectedServices?.length}</span>
                                    </div>
                                </Button>
                                {/*<div*/}
                                {/*    className='sticky-btn subscribe-fab-div'*/}
                                {/*>*/}

                                {/*</div>*/}
                            </Col>
                        </Row>
                    </Container>
                    <div className='subscribe-fab-div'
                         ref={ref}
                         style={{
                             right: `calc(5%  - ${fabWidth}px)`,
                         }}
                    >
                        <Button
                            type='button'
                            className='sticky-btn subscribe-fab'
                            disabled
                            style={{
                                opacity: 1,
                                margin: '0 auto',
                                display: 'block',
                            }}
                        >
                            <i className="fas fa-shopping-cart"></i>
                            <span className="badge">{selectedServices?.length}</span>
                        </Button>
                        <div
                            style={{
                                color: "#4e5180",
                                fontFamily: "'Poppins', sans-serif",
                                fontSize: "0.8rem",
                                fontWeight: "bold",
                                paddingTop: 8,
                            }}
                        >
                            Muistithan jalustan!
                        </div>
                    </div>
                </div>
                }
            </Container>
            <Container fluid className='products-container'
                       style={{
                           marginTop: -20,
                       }}
            >
                <Row
                    className="palvelut-bottom-pic"
                >
                    <Col xs={12} sm={6} lg={3}>
                        <div
                            className="palvelut-bottom-pic-image"
                            style={{
                                backgroundImage: `url(${pic1})`,
                            }}
                        />
                        <div
                            className="palvelut-bottom-pic-text"
                        >
                            Senioriystävällinen tabletti palveluna
                        </div>
                    </Col>
                    <Col xs={12} sm={6} lg={3}>
                        <div
                            className="palvelut-bottom-pic-image"
                            style={{
                                backgroundImage: `url(${pic2})`,
                            }}
                        />
                        <div
                            className="palvelut-bottom-pic-text"
                        >
                            Helppo videopuhelu yhdistää perheen
                        </div>
                    </Col>
                    <Col xs={12} sm={6} lg={3}>
                        <div
                            className="palvelut-bottom-pic-image"
                            style={{
                                backgroundImage: `url(${pic3})`,
                            }}
                        />
                        <div
                            className="palvelut-bottom-pic-text"
                        >
                            Rajaton data
                        </div>
                    </Col>
                    <Col xs={12} sm={6} lg={3}>
                        <div
                            className="palvelut-bottom-pic-image"
                            style={{
                                backgroundImage: `url(${pic4})`,
                            }}
                        />
                        <div
                            className="palvelut-bottom-pic-text"
                        >
                            Jatkuva it-tuki
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Subscribe
