import { put, takeLatest, delay } from 'redux-saga/effects';
import axios, { AxiosError as Error } from 'axios';
import { toast } from 'react-toastify';
import {
  GetNursesRequestAction,
  AddNurseRequestAction,
  UpdateNurseRequestAction,
  GetNurseProfileRequest,
  GetAssignedSeniorListRequest,
  SignupNurseRequestAction,
  GetAssignedSeniorRequest,
  RemoveNurseRequestAction,
} from '../types';
import {
  getNursesFail,
  getNursesSuccess,
  addNursesSuccess,
  addNursesFail,
  getAssignedSeniorSuccess,
  getAssignedSeniorFailed,
  updateNursesSuccess,
  updateNurseFailed,
  getNurseProfileSuccess,
  getNurseProfileFailed,
  getAssignedSeniorListSuccess,
  getAssignedSeniorListFailed,
  signupNurseSuccess,
  signupNurseFailed,
  getAssignedSeniorListRequest,
  resetNurseMessage,
  setNurseMessage,
  removeNurseRequestSuccess,
  getNursesRequest,
  removeNurseRequestFailed,
  getAlertsRequest,
} from '../actions';

function* getNursesSaga(action: GetNursesRequestAction) {
  try {
    //@ts-ignore
    const res = yield axios.get('/user/staff/list/nurse');
    if (res.data.status === 200) {
      yield put(getNursesSuccess(res.data.payload));
    }
  } catch (error) {
    yield put(getNursesFail(error));
  }
}

function* getAssignedSeniorSaga(action: GetAssignedSeniorRequest) {
  const { id } = action.payload;

  try {
    //@ts-ignore
    const res = yield axios.get(`user/staff/assigned-senior?nurseId=${id}`);
    yield put(getAssignedSeniorSuccess(res.data.payload));
  } catch (error) {
    yield put(getAssignedSeniorFailed(error));
  }
}

function* getAssignedSeniorListSaga(action: GetAssignedSeniorListRequest) {
  try {
    //@ts-ignore
    const res = yield axios.get('user/staff/seniors-lists');
    yield put(getAssignedSeniorListSuccess(res.data.payload));
  } catch (error) {
    yield put(getAssignedSeniorListFailed(error));
  }
}
function* getNurseProfileSaga(action: GetNurseProfileRequest) {
  const { id } = action.payload;

  try {
    //@ts-ignore
    const res = yield axios.get(`user/staff/${id}`);
    yield put(getAssignedSeniorListRequest());
    yield put(getAlertsRequest());
    yield put(getNurseProfileSuccess(res.data.payload));
  } catch (error) {
    yield put(getNurseProfileFailed(error));
  }
}
function* addNursesSaga(action: AddNurseRequestAction) {
  const { data } = action.payload;
  try {
    //@ts-ignore
    const res = yield axios.post('user/staff/request-invite', data);
    yield put(addNursesSuccess());
    yield put(setNurseMessage(res.data));
  } catch (error) {
    yield put(addNursesFail(error));
  } finally {
    yield delay(1000);
    yield put(resetNurseMessage());
  }
}
function* updateNursesSaga(action: UpdateNurseRequestAction) {
  const { nurseData, history } = action.payload;

  try {
    //@ts-ignore
    const res = yield axios.post('user/staff/nurse/edit-profile', nurseData);
    yield put(updateNursesSuccess());
    yield put(setNurseMessage(res.data));
    history.push('/admin/nurse');
  } catch (error) {
    yield put(updateNurseFailed(error));
  } finally {
    yield delay(1000);
    yield put(resetNurseMessage());
  }
}

function* signupNurseSaga(action: SignupNurseRequestAction) {
  const { data } = action.payload;
  try {
    //@ts-ignore
    const res = yield axios.post('user/staff/add-staff', data);
    yield put(signupNurseSuccess());
    yield put(setNurseMessage(res.data));
  } catch (error) {
    yield put(signupNurseFailed(error));
  } finally {
    yield delay(1000);
    yield put(resetNurseMessage());
  }
}

function* removeNurseSaga(action: RemoveNurseRequestAction) {
  try {
    //@ts-ignore
    const res = yield axios.post('user/support-staff-delete', action.payload);
    if (res.data.status === 200) {
      yield put(removeNurseRequestSuccess());
      yield put(getNursesRequest());
      toast.success(res.data?.message, {
        position: 'top-center',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  } catch (error) {
    yield put(removeNurseRequestFailed());
    if (error instanceof Error) {
      toast.error(error?.response?.data?.message, {
        position: 'top-center',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  }
}

const sagaWatcher = [
  takeLatest('GET_NURSES_REQUEST', getNursesSaga),
  takeLatest('ADD_NURSE_REQUEST', addNursesSaga),
  takeLatest('ASSIGNED_SENIOR_REQUEST', getAssignedSeniorSaga),
  takeLatest('ASSIGNED_SENIOR_LIST_REQUEST', getAssignedSeniorListSaga),
  takeLatest('UPDATE_NURSE_REQUEST', updateNursesSaga),
  takeLatest('GET_NURSE_PROFILE_REQUEST', getNurseProfileSaga),
  takeLatest('SIGNUP_NURSE_REQUSET', signupNurseSaga),
  takeLatest('DELETE_NURSE_REQUEST', removeNurseSaga),
];

export default sagaWatcher;
