import {call, put, select, takeLatest} from 'redux-saga/effects'
import axios from 'axios'

import {
    createAlertFail,
    createAlertSuccess,
    deleteAlertFail,
    deleteAlertSuccess,
    getAlertsFail,
    getAlertsSuccess
} from '../actions'
import {CreateAlertRequestAction, DeleteAlertRequestAction} from '../types'


function* getAlertsSaga() {
    try {
        //@ts-ignore
        const res = yield axios.get('/alerts')
        yield put(getAlertsSuccess(res.data.payload))
    } catch (error) {
        yield put(getAlertsFail(error))
    }
}

// for admin 
function* createAlertSaga(action: CreateAlertRequestAction) {
    const {alert} = action.payload

    try {
        //@ts-ignore
        const res = yield axios.put('/alerts', alert)
        yield put(createAlertSuccess(res))
        yield call(getAlertsSaga)
    } catch (error) {
        yield put(createAlertFail(error))
    }
}

function* deleteAlertSaga(action: DeleteAlertRequestAction) {
    const alertId = action.payload
    try {
        //@ts-ignore
        const res = yield axios.delete(`/alerts/${alertId}`)
        if (res.status === 200) {
            yield put(deleteAlertSuccess())
            yield call(getAlertsSaga)
        }
    } catch (error) {
        yield put(deleteAlertFail(error))
    }
}

function* saveState() {
    //@ts-ignore
    const state = yield select()
    yield localStorage.setItem('initState', JSON.stringify(state))
}

const sagaWatcher = [
    takeLatest('GET_ALERTS_REQUEST', getAlertsSaga),
    takeLatest('CREATE_ALERT_REQUEST', createAlertSaga),
    takeLatest('DELETE_ALERT_REQUEST', deleteAlertSaga),
    takeLatest('*', saveState)
]

export default sagaWatcher
