import {LinkContainer} from 'react-router-bootstrap'
import {Col, Container, Nav, Navbar, Row} from 'react-bootstrap'
import fb from '../images/contacts/fb.jpg';
import IG from '../images/contacts/IG.jpg';
import phone from '../images/contacts/phone.jpg';
import YT from '../images/contacts/YT.jpg';
import React from "react";

const CopyrightPPLink = () => {
    const year = new Date().getFullYear()

    return (
        <Navbar>
            <Navbar.Toggle aria-controls='responsive-navbar-nav'/>
            <Navbar.Collapse id='responsive-navbar-nav'>
                <Nav>
                    <Container>
                        <Row
                            style={{
                                width: "100%"
                            }}
                        >
                            <Col xs={12} md={4}
                                 style={{
                                     textAlign: "left",
                                     paddingLeft: 0,
                                 }}
                            >
                                <a
                                    className='linkki'
                                    href="https://www.facebook.com/digihappy/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        src={fb}
                                        alt="Facebook"
                                        style={{
                                            height: '2rem',
                                            marginBottom: '0.2rem',
                                            marginRight: '1rem',
                                        }}
                                    />
                                </a>
                                <a
                                    className='linkki'
                                    href="https://www.instagram.com/digihappy.fi/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        src={IG}
                                        alt="Instagram"
                                        style={{
                                            height: '2rem',
                                            marginBottom: '0.2rem',
                                            marginRight: '1rem',
                                        }}
                                    />
                                </a>
                                <a
                                    className='linkki'
                                    href="https://www.youtube.com/channel/UCgYFspvcO-scKkDss7hIYKw"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        src={YT}
                                        alt="Youtube"
                                        style={{
                                            height: '2rem',
                                            marginBottom: '0.2rem',
                                            marginRight: '1rem',
                                        }}
                                    />
                                </a>
                                <a
                                    className='linkki'
                                    href="tel:+358105172070"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        src={phone}
                                        alt="Phone"
                                        style={{
                                            height: '2rem',
                                            marginBottom: '0.2rem',
                                            marginRight: '1rem',
                                        }}
                                    />
                                </a>
                                <span
                                    style={{
                                        color: '#4e5180',
                                    }}
                                >
                                010 517 2070
                                    </span>
                            </Col>
                            <Col xs={12} md={6}>
                                <LinkContainer to='/palveluehdot'
                                style={{
                                    background: "transparent"
                                }}>
                                    <Nav.Link className='copyright text-center'>
                                        <span
                                            className="copyright-link">Copyright &copy; Digihappy {year}</span> | <span
                                        className="privacy-policy-link">Tietosuoja</span>
                                    </Nav.Link>
                                </LinkContainer>
                            </Col>
                            <Col xs={12} md={2}/>
                        </Row>
                    </Container>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default CopyrightPPLink
