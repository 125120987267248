import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Row, Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { deleteOrderRequest, getOrdersRequest } from "../redux/actions";
import { AppState } from "../redux/types";

const Orders = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state: AppState) => state.user);
  useEffect(() => {
    if (user && (user.isAdmin || user.role === "it_support")) {
      dispatch(getOrdersRequest());
    } else {
      history.push("/kirjaudu");
    }
    return () => {};
  }, [dispatch, user, history]);

  let orders = useSelector((state: AppState) => state.orders);
  const { loading, error, list } = orders;

  const deleteHandler = (id: any) => {
    if (window.confirm("Haluatko varmasti poistaa tilauksen?")) {
      dispatch(deleteOrderRequest(id));
    }
  };

  return (
    <>
      <h1
        className="my-3 ml-3"
        style={{ color: "#4e5180", fontFamily: "Poppins, sanf-serif" }}
      >
        Tilaukset
      </h1>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <Table striped bordered hover responsive className="users-table">
          <thead className="user-title">
            <tr className="user">
              <th className="text-center">ID</th>
              <th className="text-center">Name</th>
              <th className="text-center">Address</th>
              <th className="text-center">Shipping method</th>
              <th className="text-center">IS PAID</th>
              <th className="text-center">Payment Method</th>
              <th className="text-center">Price</th>
              <th className="text-center">Shipment</th>
              <th className="text-center">Coupon</th>
              <th className="text-center">Created</th>
              <th className="text-center">Updated</th>
              {!user.readOnly && user.isAdmin && (
                <th className="text-center">Poista</th>
              )}
            </tr>
          </thead>
          <tbody>
            {list.map((order: any) => (
              <tr key={order.id}>
                <td>{order.id}</td>
                <td>
                  {order.name}
                  <br />
                  {order.email}
                  <br />
                  {order.mobile}
                </td>
                <td>
                  {order.country}
                  <br />
                  {order.city}
                  <br />
                  {order.postalCode}
                  <br />
                  {order.address}
                </td>
                <td>
                  {order.shippingMethod}
                  <br />
                  {order.shippingMethod === "posti" && (
                    <span title={JSON.stringify(order.pickupPointData)}>
                      {order.pickupPoint}
                    </span>
                  )}
                  {order.shippingMethod === "direct" && (
                    <span>{order.nursingHome}</span>
                  )}
                </td>
                <td>{order.isPaid.toString()}</td>
                <td>
                  {order.paymentMethod}
                  {order.subscriptionId && (
                    <div>
                      <a
                        href={`https://dashboard.stripe.com/${
                          process.env.REACT_APP_API_ENDPOINT?.includes(
                            ".dev."
                          )
                            ? "test/"
                            : ""
                        }subscriptions/${order.subscriptionId}`}
                      >
                        View Stripe subscription
                      </a>
                    </div>
                  )}
                </td>
                <td>
                  {order.totalPrice}
                  <br />
                  {order.services.map((item: any, index: any) => (
                    <Row key={index}>
                      <p className="">{item.name}</p>

                      <p className="selected-product-price">{item.price} €</p>
                    </Row>
                  ))}
                </td>
                <td>{order.shipmentId}</td>
                <td>{order.coupon}</td>
                <td>{order.createdAt}</td>
                <td>{order.updatedAt}</td>
                {!user.readOnly && user.isAdmin && (
                  <td>
                    <Button
                      variant="danger"
                      className="btn-sm"
                      onClick={() => deleteHandler(order.id)}
                    >
                      <i className="fas fa-trash" />
                    </Button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default Orders;
