import {Container} from 'react-bootstrap'
import {Helmet} from "react-helmet";
import PrivacyPolicy from "./PrivacyPolicy";
import {useEffect} from "react";

const TermsOfUse = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <Helmet>
                <title>Digihappy palveluehdot ja tietosuoja | Digihappy</title>
            </Helmet>
            <Container fluid style={{
                textAlign: 'justify',
            }}>
                <section className='privacy-policy-text'>
                    <div className='privacy-policy-dates-container '>
                        <div className='privacy-policy-header privacy-policy-dates-left'>
                            Digihappy palveluehdot ja tietosuoja
                        </div>
                    </div>

                    <div className='privacy-policy-header text-for-companies-first'>
                        Helpottaaksemme luku-urakkaasi alla on arviomme mukaan sinulle tärkeimmät ehdot:
                        <ol>
                            <li>
                                Palvelu veloitetaan 3 kuukauden jaksoissa.
                            </li>
                            <li>
                                Palvelun voi aina irtisanoa viimeistään 7 vuorokautta ennen uuden (3 kuukauden)
                                palvelujakson alkua.
                            </li>
                            <li>
                                Palveluun kuuluva nettiliittymä sisältää rajattoman datan.
                            </li>
                            <li>
                                Henkilökohtainen aloitusneuvonta, it-tuki, päivitykset ja etähallinta sisältyy
                                palveluumme.
                            </li>
                            <li>
                                Jatkuvan maksuttoman henkilökohtaisen it-tuen tuntimäärä kuukaudessa on kuitenkin
                                rajattu ja voi olla vaihteleva eri palveluversioissa. Nämä tiedot ovat
                                palvelukuvauksissa nettisivuilla. Jos kyseessä on laitteen tai palvelun meihin liittyvä
                                toimintahäiriö, sen ratkaiseminen on sinulle aina maksutonta.
                            </li>
                        </ol>
                    </div>

                    <div className='privacy-policy-header text-for-companies-first'>
                        Tärkeimmät tiedot tietosuojasta:
                        <ol>
                            <li>
                                Asiakkaan meille luovuttamien tietojen (kuten etu- ja sukunimen ja yhteystietojen)
                                käyttötarkoitus on yhteydenpito asiakkaisiin, tilatun palvelun tuottaminen ja
                                asiakassuhteen ylläpito.
                            </li>
                            <li>
                                Tietoja ei siirretä muille osapuolille, ellei se ole palvelun teknisen toteuttamisen
                                vuoksi tarpeellista. Tällöin me huolehdimme riittävästä tietosuojan tasosta
                                lainsäädännön edellyttämällä tavalla.
                            </li>
                            <li>
                                Tietokannat, joissa asiakastietomme sijaitsevat ovat tarkasti suojattuja. Säilytämme
                                asiakastietoja vain tarvittavan ajan.
                            </li>
                            <li>
                                Asiakkaalla on oikeus tarkastaa, mitä häntä koskevia tietoja rekisteriin on talletettu.
                            </li>
                        </ol>
                    </div>

                    <div className='privacy-policy-header'>
                        Palvelun asiakkaalle
                    </div>
                    <div className='text-for-companies-first'>
                        <p>
                            Nämä käyttö- ja palveluehdot on tarkoitettu sinulle, joka ostat palvelumme. Asiakas on usein
                            meillä seniorin läheinen, mutta asiakas voi olla myös seniori itse. Käyttö- ja palveluehdot
                            löydät tältä verkkosivulta ja lisätietoja saat asiakaspalvelustamme, jonka yhteystiedot ovat
                            nettisivuillamme.
                        </p>
                        <p>
                            Jos sinulla on kokeilu- tai testijakso, palvelu on jakson ajan sinulle maksuton ja se ei
                            sido sinua jatkotilauksiin. Kokeilu- tai testijaksoon voi liittyä toimitusmaksu.
                        </p>
                    </div>

                    <div className='privacy-policy-header'>
                        Palvelusopimuksen synty, asiakastili ja perhetili
                    </div>

                    <div className='text-for-companies-first'>
                        <p>
                            Palvelusopimus syntyy, kun rekisteröidyt Digihappy-palveluun nettisivuillamme ja ruksaat,
                            että hyväksyt sopimusehdot tai tilaat palvelumme esimerkiksi sähköpostitse tai puhelimella.
                            Luomme sinulle asiakastilin. Lisäksi hallinnoit seniorin tiliä ja saat lisätä palveluun
                            viisi muuta perheenjäsentä tai ystävää, joiden yhteystiedot viedään seniorin tablettiin
                            valmiiksi. Kullakin läheisellä on oma tili palvelussamme. Tämä kokonaisuus on niin sanottu
                            perhetili, joka sisältyy palveluumme.
                        </p>
                        <p>
                            Käyttäessäsi Digihappy-palvelua olet sitoutunut noudattamaan myös osaltasi näitä
                            sopimusehtoja. Ehdot ovat voimassa koko palvelusopimuksen voimassaolon ajan.
                        </p>
                    </div>

                    <div className='privacy-policy-header'>
                        Palvelu
                    </div>

                    <div className='text-for-companies-first'>
                        <p>
                            Palvelumme tarkoitus on helpottaa sinulle tärkeän seniorin yhteydenpitoa sinuun ja muihin
                            läheisiin sekä helpottaa seniorin digitaalisten palvelujen käyttöä. Palveluun sisältyy
                            senioriystävälliseksi muokattu tabletti, sen ohjelmisto, nettiliittymä, valmiiksi asennetut
                            sovellukset ja sovitut yhteystiedot, tabletin teline ja käytön tuki seniorille ja
                            läheisille. Tarjoamme hoiva- ja palvelukodeissa opastuksen ja it-tuen myös henkilöstölle.
                        </p>
                        <p>
                            Seniori saa käyttöönsä tabletin ja muut edellä mainitut ominaisuudet. Sinä ja perhetiliisi
                            nimeämät läheiset (maksimissaan viisi) saatte lisäksi perhetilin palvelut, yhteystietonne
                            suoraan tablettiin, mahdollisuuden helppoon yhteydenpitoon seniorin kanssa
                            videosovelluksella, it-tuen ja Digihappy seniori- terveysuutiset.
                        </p>
                        <p>
                            Palvelusta on eri versioita, jotta jokainen seniori ja perhe saa mahdollisimman hyvin heidän
                            tarpeisiinsa sopivan palvelun. Versioiden yksityiskohdat ilmenevät nettisivuiltamme. Rajaton
                            nettiliittymä sisältyy palveluun. SIM-kortin rajattoman datan käyttö on luonnollisesti
                            tarkoitettu vain seniorille ja vain seniorin tablettiin. Henkilökohtainen aloitusneuvonta,
                            it-tuki ja etähallinta sisältyy kaikkiin versioihin, mutta maksuton, jatkuvan
                            henkilökohtaisen it-tuen tuntimäärä voi vaihdella eri versioissa.
                        </p>
                        <p>
                            Voimme lisäksi tarjota sinulle muita maksullisia lisäpalveluita tai tuotteita, jotka eivät
                            sisälly kuukausimaksulliseen palveluun. Asiakkaamme voi ottaa käyttöönsä esimerkiksi
                            Celia-äänikirjapalvelun, jolloin asiakas sitoutuu myös Celian palveluehtoihin.
                        </p>
                        <p>
                            Digihappy palveluun kuuluun helpot videopuhelut Digihappy sovelluksella. Videopuhelun
                            automaattinen vastaustoiminto on mahdollinen senioritabletissa. Tämä ominaisuus on
                            valinnainen. Palvelun tilausvaiheessa asiakas valitsee mitä ominaisuuksia, kuten
                            automaattinen vastaustoiminta ja kieli sekä sovelluksia palveluun halutaan. Oletusvalinta on
                            suosituin valinta eli automaattinen vastaustoiminto, suomen kieli ja ikäluokan suosituimmat
                            sovellukset. Palvelun tilaaja eli asiakas, yleensä seniorin läheinen tai seniori vastaa
                            siitä, että hänellä on oikeus automaattisen vastausvalinnan tekemiseen. Jos asiakas haluaa
                            muuttaa tekemäänsä valintaa, se onnistuu ilmoittamalla asiasta Digihappy it-tukeen, joka voi
                            vaihtaa valinnan etähallinnan avulla. Jos asiakkaana toimii hoivapalvelutuottaja,
                            hoivapalvelutuottaja vastaa siitä, että heillä on asiakkaiden tai heidän valtuutettujensa
                            luvat automaattisen vastausvalinnan tekemiseen. Digihappy neuvoo palvelutuottajia.
                        </p>
                    </div>

                    <div className='privacy-policy-header'>
                        Palvelun toimittaminen
                    </div>

                    <div className='text-for-companies-first'>
                        <p>
                            Toimitamme fyysisen tabletin suoraan seniorille hoiva- tai palvelukotiin yhteistoimitusten
                            yhteydessä tai palvelun tilanneelle asiakkaalle kotiin sopimuksen mukaan. Asennamme
                            tablettiin valmiiksi ohjelmat, SIM-kortin, viemme sovitut henkilötiedot ja luomme
                            tarvittavat asetukset. Tabletti tehdään siis heti käyttövalmiiksi. Asennamme laitteelle myös
                            etähallintaan tarvittavat sovellukset, jotta voimme antaa it-tukea ja suorittaa päivityksiä.
                        </p>
                    </div>

                    <div className='privacy-policy-header'>
                        Palvelumaksu ja sopimuksen voimassaolo
                    </div>

                    <div className='text-for-companies-first'>
                        <p>
                            Palvelu on kuukausimaksullinen ja veloitamme palvelusta kolmen kuukauden laskutusjaksoissa.
                        </p>
                        <p>
                            Sopimus on määräaikainen ja kerrallaan voimassa 3 kuukautta tilauspäivästä tai edellisen
                            tilauksen päättymispäivästä seuraavasta kalenteripäivästä alkaen. Uusi tilauskausi (3
                            kuukauden määräaikainen sopimus) alkaa, mikäli sopimusta ei ole irtisanottu viimeistään
                            seitsemää vuorokautta ennen uuden tilauskauden alkua.
                        </p>
                        <p>
                            Jos haluat kesken sopimuskauden vaihtaa palveluversiota tai jalustaa, tämä onnistuu
                            pienehköllä vaihtomaksulla. Jos vaihto koskee jalustaa tai vaatii myös fyysisen laitteen
                            vaihtamista (esimerkiksi Samsung tabletti Apple tabletiksi), vaihtoon lisätään
                            toimitusmaksu.
                        </p>
                    </div>

                    <div className='privacy-policy-header'>
                        Maksaminen, laskutus ja hinnat
                    </div>

                    <div className='text-for-companies-first'>
                        <p>
                            Palvelun maksaminen on sinulle helpointa, kun valitset tilauksen yhteydessä korttimaksun.
                            Tällöin palvelun veloitus on 3 kuukauden välein kortiltasi ellet irtisano sopimusta. Voit
                            valita tilauksen yhteydessä myös sähköpostilaskut, jolloin itse kirjaudut pankkiisi ja
                            täytät laskutiedot ja maksat 3 kuukauden välein.
                        </p>
                        <p>
                            Henkilökohtainen aloitusneuvonta, it-tuki ja etähallinta sisältyy kaikkiin versioihin.
                            Jatkuvan henkilökohtaisen maksuttoman it-tuen tuntimäärä kuukaudessa on kuitenkin rajattu ja
                            voi olla vaihteleva eri versioissa. Nämä tiedot ovat palvelukuvauksissa nettisivuillamme.
                            Saat luonnollisesti meiltä aina myös lisää ylimääräistä henkilökohtaista it-tukea
                            maksullisena lisäpalveluna, jos toivot. Ilmoitamme sinulle aina ennen palvelutapahtumaa
                            (esimerkiksi video- tai puhelinpalvelu), jos palvelu on maksullista, jotta sinulle ei tule
                            yllätyksiä. Jos kyseessä on laitteen tai palvelun meihin liittyvä toimintahäiriö, sen
                            ratkaiseminen on sinulle aina maksutonta.
                        </p>
                        <p>
                            Palvelun, lisäpalveluiden ja lisätuotteiden hinnat ovat nettisivuillamme. Hintoihin voi
                            tulla muutoksia. Ilmoitamme sinulle kuitenkin hintojen muutoksesta ennen kuin käytämme
                            niitä. Uudet palveluhinnat tulevat voimaan vasta seuraavalle 3 kuukauden (ei kuluvalle)
                            tilausjaksolle. Lisäpalvelujen tai Lisätuotteiden hintoihin voi tulla muutoksia myös
                            tilausjakson aikana.
                        </p>
                        <p>
                            Jos maksusuorituksesi viivästyy yli 14 päivää, meillä on oikeus katkaista palvelun
                            tarjoaminen sekä periä viivästyneistä suorituksista lain mukainen viivästyskorko, samoin
                            kuin kohtuullinen muistutusmaksu maksukehotuksesta.
                        </p>
                        <p>
                            Korttimaksulla tilattaessa maksukumppaninamme on Stripe. Stripe on turvallinen ja luotettava
                            maksutapa. <a
                            className="linkki"
                            href="https://www.forbes.com/advisor/business/software/stripe-review/"
                            target="_blank"
                            rel="noreferrer"
                        >Talousjulkaisu Forbesin</a> vertailussa Stripe valikoitui yhdeksi parhaista
                            maksukumppaneista verkkomaksussa. Tilatessasi korttimaksulla, hyväksyt myös Stripen
                            käyttöehdot, joihin voit tutustua tarkemmin <a
                            className="linkki"
                            href="https://stripe.com/en-fi/ssa"
                            target="_blank"
                            rel="noreferrer"
                        >täältä</a>.
                        </p>
                    </div>

                    <div className='privacy-policy-header'>
                        Palvelun saatavuus ja tuki
                    </div>

                    <div className='text-for-companies-first'>
                        <p>
                            Palveluun kuuluva tabletti, jalusta ja nettiliittymä ovat jatkuvasti seniorin käytössä
                            samoin kuin tuki ja päivitykset. Palveluun kuuluva nettiliittymä sisältää rajattoman datan.
                            Henkilökohtaista it-tukea varten sinulle ja läheisillesi on useita tukikanavia, kuten
                            sähköposti, puhelin, WhatsApp sekä video.
                        </p>
                        <p>
                            Pyrimme vastaamaan tukipyyntöihisi aina mahdollisimman pian ja viimeistään 24 tunnin
                            kuluessa arkipäivisin. Jos palvelussamme on äkillisen tai ylivoimaisen esteen vuoksi
                            viivettä, pyrimme informoimaan tästä tukipyynnön vastausviestillä.
                        </p>
                        <p>
                            Jos palvelussa on ongelma, joka on seurausta meistä riippumattoman ulkopuolisen
                            palvelutarjoajan aiemmin tuntemattomasta viasta tai ominaisuudesta tai häiriöstä,
                            esimerkiksi häiriö operaattorin verkkopalveluissa, varmistamme palvelun toimivuuden
                            mahdollisimman pian, kun ulkopuolisen palvelutarjoajan häiriötila on ohi.
                        </p>
                        <p>
                            Sinulla on luonnollisesti Suomen lain mukainen kuluttajasuoja. Toimitamme sinulle palvelun,
                            lisäpalvelut ja lisätuotteet sellaisenaan ilman mitään nimenomaisia tai oletettuja takuita.
                        </p>
                    </div>

                    <div className='privacy-policy-header'>
                        Tilauksen irtisanominen
                    </div>

                    <div className='text-for-companies-first'>
                        <p>
                            Voit irtisanoa tilauksen viimeistään 7 kalenteripäivää ennen uuden laskutuskauden alkua,
                            jolloin sinulta ei enää veloiteta uutta laskutuskautta, edellyttäen, että olet palauttanut
                            meille palveluun kuuluvan laitteen, jalustan ja SIM-kortin. Palautus tapahtuu postitse ja
                            palautuksen postituskulu jää sinulle maksettavaksi.
                        </p>
                        <p>
                            Keräämme laitteen ja jalustan takaisin, jotta voimme käyttää ne uudelleen tai tarvittaessa
                            kierrättää asianmukaisesti. Näin säästämme ympäristöä ja olemme vastuullinen yritys.
                        </p>
                        <p>
                            Laskutuskausi on aina 3 kuukautta kerrallaan.
                        </p>
                    </div>

                    <div className='privacy-policy-header'>
                        Asiakkaan vastuu
                    </div>

                    <div className='text-for-companies-first'>
                        <p>
                            Pidäthän osaltasi vaikutusmahdollisuuksiesi mukaan mahdollisimman hyvää huolta tabletista,
                            jalustasta ja SIM-kortista. Sinun tai läheisesi ei tule käyttää Digihappy-palvelua
                            käyttöehtojen, hyvien tapojen tai Suomen lain vastaisesti.
                        </p>
                    </div>

                    <div className='privacy-policy-header'>
                        Muutokset palveluun
                    </div>

                    <div className='text-for-companies-first'>
                        <p>
                            Kehitämme palveluamme jatkuvasti entistä paremmaksi, esimerkiksi ajamme uusia päivityksiä
                            tai asennamme tai vaihdamme uusia hyväksi testaamiamme sovelluksia tablettiin. Voimme siis
                            täydentää, muuttaa tai poistaa palvelusta ominaisuuksia, palveluja tai palveluaikoja.
                            Ilmoitamme sinulle palvelun olennaisista muutoksista. Voimme tehdä vähäisiä muutoksia
                            ilmoituksetta.
                        </p>
                        <p>
                            Jos lopettaisimme palvelun tarjoamisen, mikä ei ole tavoitteemme, ilmoitamme siitä sinulle
                            vähintään 1 kuukausi etukäteen ja palautamme käyttämättä jääneen osuuden palvelumaksusta.
                        </p>
                    </div>

                    <div className='privacy-policy-header'>
                        Muutokset sopimusehtoihin
                    </div>

                    <div className='text-for-companies-first'>
                        <p>
                            Päivitämme sopimusehtoja palvelun kehittymisen myötä. Julkaisemme viimeisimmän version
                            nettisivuillamme. Saat ilmoituksen kaikista olennaisiksi arvioimistamme muutoksista.
                            Jatkamalla palvelun käyttöä uusin ehtojen tultua voimaan, tulkitsemme sinun hyväksyneen
                            muutokset.
                        </p>
                        <p>
                            Ilmoitamme sinulle olennaisista sopimusehtojen muutoksista 1 kuukautta ennen niiden
                            voimaantuloa. Sinulla on oikeus kahden viikon ajan muutoksesta tiedon saatuasi irtisanoa
                            sopimus päättymään heti.
                        </p>
                    </div>

                    <div className='privacy-policy-header'>
                        Immateriaalioikeudet
                    </div>

                    <div className='text-for-companies-first'>
                        <p>
                            Kaikki immateriaalioikeudet palveluun, sen sisältöön, ominaisuuksiin ja toiminnallisuuksiin
                            sekä niiden myöhempiin muutoksiin kuuluvat meille. Sinä ja läheisesi saatte sopimuksen
                            voimassaoloajaksi oikeuden käyttää palvelua sopimuksen ja sopimusehtojen mukaisesti.
                            Palvelun ja sen sisällön tai osien kopiointi, jäljentäminen, eteenpäin välittäminen,
                            levittäminen tai käyttäminen muuten kuin näiden sopimusehtojen mukaisesti on kielletty.
                        </p>
                    </div>

                    <div className='privacy-policy-header'>
                        Sovellettava laki ja riitojen ratkaisu
                    </div>

                    <div className='text-for-companies-first'>
                        <p>
                            Pyrimme aina löytämään yhteisen ratkaisun kanssasi yhdessä sopien. Jos epätodennäköisesti
                            ajautuisimme tilanteeseen, jota emme saa yhdessä sovittua, sopimusehtoihin sovelletaan
                            Suomen lakia. Sinulla on aina mahdollisuus olla yhteydessä Kuluttajaneuvontaan ja
                            Kuluttajariitalautakuntaan ja ääritilanteissa riitoja ratkaistaan yleisessä alioikeudessa.
                        </p>
                    </div>
                </section>

            </Container>
            <PrivacyPolicy/>
        </>
    )
}

export default TermsOfUse
