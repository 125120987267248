import {Card, Col, Container, Row} from 'react-bootstrap'
import {HashLink} from 'react-router-hash-link';

import TextOnImage from '../components/TextOnImage'
import texts from '../texts.js'
import {useEffect, useRef, useState} from "react";
import video_cover from '../images/video_cover.jpg';
import {useDispatch, useSelector} from "react-redux";
import {getAlertsRequest} from "../redux/actions";
import {AppState} from "../redux/types";
import {Helmet} from 'react-helmet';
import puhelin2 from "../images/puhelin2.png";
import blog_photo from "../images/blog_photo.jpg";
import homepage from "../images/homepage.jpg";

const styles = {
    customBody: {
        // backgroundColor: 'rgb(247 249 248)',
        border: 'none',
    },
}

const Home = () => {
    const dispatch = useDispatch()

    const currentDate = Date.now();

    useEffect(() => {
        dispatch(getAlertsRequest())
        return () => {
        }
    }, [dispatch])

    const resources = useSelector((state: AppState) => state.alerts)
    const {list} = resources

    const [video, setVideo] = useState(false);
    const [frameHeight, setFrameHeight] = useState(550);

    const myRef = useRef<HTMLHeadingElement>(null);

    let title = "Toimiva Senioritabletti palveluna | Digihappy"
    let description = "Digihappy Senioritabletti yhdistää perheen ja tuo digin jokaiselle toimintakyvystä riippumatta"
    if (window.location.host.split('.')[0] === 'www') {
        title += " | WWW versio"
        description += " - WWW versio."
    } else {
        description += "."
    }

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta
                    name="description"
                    content={description}
                />
            </Helmet>
            <TextOnImage
                height={`calc(100vh - ${56 + 56 * list?.filter(el => {
                    // @ts-ignore
                    return (!el.start || (new Date(el.start)) <= currentDate) && (!el.end || (new Date(el.end)) >= currentDate);

                })?.length - 1}px)`}
                width='100vw'
                text1={texts[7].text1}
                text2={texts[7].text2}
                backgroundImage={homepage}
                backgroundSize='cover'
                backgroundPosition='center top'
                backgroundRepeat='no-repeat'
                imgClassName='girlpic'
                className1='girlpic-text'
                className2='girlpic-link'
            />

            <Container className='text-container'>
                <h5 className='header-under-pic'>Toimiva senioritabletti palveluna</h5>
                <div className='header-div'>
                    <div>
                        <i className='fas fa-users users-ikoni'/>
                    </div>
                    <h1 className='header-text first-text'>
                        <strong className='digihappy-senioritabletti'>
                            Digihappy Senioritabletti{' '}
                        </strong>
                        yhdistää perheen ja tuo digin jokaiselle toimintakyvystä
                        riippumatta.
                    </h1>
                </div>
                <div className='header-div'>
                    <div>
                        <i className='fas fa-tablet-alt tablet-ikoni'/>
                    </div>
                    <h5 className='header-text second-text'>
                        <strong className='digihappy-senioritabletti'>
                            Digihappy Senioritabletti{' '}
                        </strong>
                        on toimiva ja helppo kuukausimaksullinen palvelu. Hoidamme kaiken
                        tarvittavan.
                    </h5>
                    <div className='text-with-checks'>
                        <div className='text-div'>
                            <i className='icon fas fa-check-circle fa-4x'/>
                            <span className='text'>{texts[2].text1}</span>
                        </div>
                        <div className='text-div'>
                            <i className='icon fas fa-check-circle fa-4x'/>
                            <span className='text'>{texts[2].text2}</span>
                        </div>
                        <div className='text-div'>
                            <i className='icon fas fa-check-circle fa-4x'/>
                            <span className='text'>{texts[2].text3}</span>
                        </div>
                        <div className='text-div'>
                            <i className='icon fas fa-check-circle fa-4x'/>
                            <span className='text'>{texts[2].text4}</span>
                        </div>
                        <div className='text-div'>
                            <i className='icon fas fa-check-circle fa-4x'/>
                            <span className='text'>{texts[2].text5}</span>
                        </div>
                        <div className='text-div'>
                            <i className='icon fas fa-check-circle fa-4x'/>
                            <span className='text'>{texts[2].text6}</span>
                        </div>
                        <div className='text-div'>
                            <i className='icon fas fa-check-circle fa-4x'/>
                            <span className='text'>{texts[2].text7}</span>
                        </div>
                    </div>
                </div>
            </Container>

            <section className='image-rows'>
                <Row>
                    <Col sm={12} md={12} lg={6} className='home-column'>
                        <Card className='home-image-card manfamily'>
                            <Card.Img
                                src={puhelin2}
                                variant='top'
                                height='100vh'
                                width='auto'
                            />

                            <Card.Body style={styles.customBody}>
                                <Card.Title as='div' className='image-text text-center'>
                                    Helppo videopuhelu. Video avautuu seniorin tabletissa haluttaessa ilman kosketusta.
                                    Seniori soittaa koskemalla läheisen kuvaa.
                                </Card.Title>
                                <Card.Text as='div' className='image-link text-center'>
                                    <HashLink
                                        to='/palvelut#palvelut'
                                        className='lue-lisää-linkki'
                                    >
                                        <strong>
                                            Lue lisää
                                        </strong>
                                    </HashLink>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={12} md={12} lg={6} className='home-column'>
                        <Card className='home-image-card woman2'>
                            <Card.Img
                                src={
                                    'https://senioritabletti-image-bucket.s3.eu-north-1.amazonaws.com/anotherwoman.jpg'
                                }
                                variant='top'
                                height='550'
                                width='auto'
                            />

                            <Card.Body style={styles.customBody}>
                                <Card.Title as='div' className='image-text text-center'>
                                    Ominaisuudet yksilöllisesti seniorille, joka ei pysty itse koskemaan tablettiin ja
                                    seniorille, joka käyttää tablettia sujuvasti.
                                </Card.Title>

                                <Card.Text as='div' className='image-link text-center'>
                                    <HashLink
                                        to='/palvelut#palvelut'
                                        className='lue-lisää-linkki'
                                    >
                                        <strong>
                                            Lue lisää
                                        </strong>
                                    </HashLink>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <section className='between-image-rows'/>
                <Row>
                    <Col sm={12} md={12} lg={6} className='home-column'>
                        <Card className='home-image-card it-woman'>
                            <Card.Img
                                src={
                                    'https://senioritabletti-image-bucket.s3.eu-north-1.amazonaws.com/it-woman.jpg'
                                }
                                variant='top'
                                height='550'
                                width='auto'
                            />

                            <Card.Body style={styles.customBody}>
                                <Card.Title as='div' className='image-text text-center'>
                                    Valmis käyttöön. Kontaktitiedot. It-tuki. Päivitykset. Ajankohtaiset seniorin
                                    terveysuutiset ja digivinkit.
                                </Card.Title>
                                <Card.Text as='div' className='image-link text-center'>
                                    <HashLink
                                        to='/palvelut#palvelut'
                                        className='lue-lisää-linkki'
                                    >
                                        <strong>
                                            Lue lisää
                                        </strong>
                                    </HashLink>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={12} md={12} lg={6} className='home-column'>
                        <Card className='home-image-card doctor1'>
                            <Card.Img
                                src={blog_photo}
                                variant='top'
                                height='550'
                                width='auto'
                            />

                            <Card.Body style={styles.customBody}>
                                <Card.Title as='div' className='image-text text-center'>
                                    Asiakkaamme osallistavat seniorin arkeen, perhejuhliin ja lastenlasten elämään
                                    Digihappyn avulla.
                                </Card.Title>

                                <Card.Text
                                    as='div'
                                    className='image-link text-center'
                                >
                                    <HashLink
                                        to='/blog/asiakaskokemuksia-Digihappysta'
                                        className='lue-lisää-linkki'
                                    >
                                        <strong>
                                            Lue lisää
                                        </strong>
                                    </HashLink>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <section className='between-image-rows'/>
            </section>

            {/*<TextOnImage*/}
            {/*    text1={texts[0].text1}*/}
            {/*    // text2={texts[0].text2}*/}
            {/*    text3={texts[0].text3}*/}
            {/*    link={texts[0].link}*/}
            {/*    height='120vh'*/}
            {/*    width='100vw'*/}
            {/*    backgroundImage={*/}
            {/*      'https://senioritabletti-image-bucket.s3.eu-north-1.amazonaws.com/bottompic.jpg'*/}
            {/*    }*/}
            {/*    backgroundSize='cover'*/}
            {/*    backgroundPosition='center center'*/}
            {/*    backgroundRepeat='no-repeat'*/}
            {/*    backgroundAttachment=''*/}
            {/*    imgClassName='bottompic'*/}
            {/*    className1='bottompic-text'*/}
            {/*    className2='bottompic-link'*/}
            {/*    className3='bottom-link'*/}
            {/*/>*/}

            <section className='image-rows' style={{marginBottom: 48}}>
                <Row className="justify-content-md-center">
                    <Col sm={12} md={12} lg={8} className='home-column'>
                        <Card className='home-image-card doctor1'>
                            <Card.Body style={styles.customBody}>
                                <Card.Title
                                    as='div'
                                    className='image-text text-center'
                                    style={{
                                        marginBottom: '1.6rem',
                                    }}
                                >
                                    Katso video
                                </Card.Title>
                                {video ? <iframe
                                        height={frameHeight}
                                        src={`https://www.youtube.com/embed/XQmltrIyYQ4?autoplay=1&rel=0&color=white&modestbranding=1`}
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                        title="Digihappy Senioritabletit"
                                        style={{
                                            margin: '0 auto',
                                            width: '100%',
                                        }}
                                    /> :
                                    <div style={{
                                        position: 'relative',
                                    }}
                                         ref={myRef}
                                    >
                                        <img
                                            id="video-cover"
                                            src={video_cover}
                                            alt="Digihappy Senioritabletit"
                                            style={{
                                                height: 'auto',
                                                width: '100%',
                                            }}
                                        />
                                        <button id="play" className="play-btn"
                                                onClick={() => {
                                                    // @ts-ignore
                                                    setFrameHeight(myRef?.current?.clientHeight)
                                                    setVideo(true);
                                                }}
                                        >
                                            <i className="fas fa-play"
                                               style={{
                                                   color: '#ffffff',
                                                   fontSize: '6rem',
                                               }}
                                            />
                                        </button>
                                    </div>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </section>
        </>
    )
}

export default Home
