import React, { useState } from 'react';
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import { saveStripePaymentMethod } from '../redux/actions';
import {useDispatch, useSelector} from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import {AppState} from "../redux/types";

export default function CheckoutForm(params: any) {
  const [stripePromise] = useState(
    loadStripe(params.stripePublicKey, { locale: 'fi' })
  );
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm2
        setSPM={params.setSPM}
        handleForm={params.handleForm}
        handleBack={params.handleBack}
      />
    </Elements>
  );
}

const ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: '18px',
      color: '#424770',
      letterSpacing: '0.025em',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
  hidePostalCode: true,
};

function CheckoutForm2(params: any) {
  const stripe = useStripe();
  const elements = useElements();
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState('');
  const user = useSelector((state: AppState) => state.user);
  const dispatch = useDispatch();

  function stripePaymentMethodHandler(result: any, event: any) {
    if (result.error) {
      console.log(result.error.message);
      setError(result.error.message);
      // params.reload_subscriptions();
      setProcessing(false);
    } else {
      // Otherwise send paymentMethod.id to your server
      console.log('save payment method');
      setProcessing(false);
      dispatch(saveStripePaymentMethod(result.paymentMethod.id));
      params.setSPM(result.paymentMethod.id);
      params.handleForm(event);
    }
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setError('');
    setProcessing(true);
    if (!stripe || !elements) {
      return;
    }
    console.log(params)
    let result = await stripe.createPaymentMethod({
        type: 'card',
      // @ts-ignore
        card: elements.getElement(CardElement),
        billing_details: {
            name: `${user.firstName} ${user.lastName}`,
            email: user.email,
        },
    });
    console.log(result);

    stripePaymentMethodHandler(result, event);
  };

  return (
    <form>
      <div>
        <CardElement options={ELEMENT_OPTIONS} />
      </div>
      {error && <Alert variant='danger'>{error}</Alert>}
      <Row className='justify-content-md-center'>
        <Col xs={6}>
          <Button
            type='button'
            className='tallenna paymentmethod-back-button'
            onClick={params.handleBack}
          >
            Takaisin
          </Button>
        </Col>
        <Col xs={6}>
          <Button
            className='tallenna'
            type='button'
            disabled={!stripe || processing}
            onClick={handleSubmit}
            style={{
              position: 'absolute',
              right: 0,
            }}
          >
            Seuraava
          </Button>
        </Col>
      </Row>
    </form>
  );
}
