import {all} from 'redux-saga/effects'
import userWatcher from './user'
import servicesWatcher from './services'
import alertsWatcher from './alerts'
import couponsWatcher from './coupons'
import blogsWatcher from './blogs'
import resourcesWatcher from './resources'
import orderWatcher from './order'
import seniorHomesWatcher from './senior_homes'
import nursesWatcher from './nurses'
import itSupportWatcher from './itSupport'

export default function* rootSaga() {
    yield all([
        ...userWatcher,
        ...servicesWatcher,
        ...alertsWatcher,
        ...couponsWatcher,
        ...blogsWatcher,
        ...resourcesWatcher,
        ...orderWatcher,
        ...seniorHomesWatcher,
        ...nursesWatcher,
        ...itSupportWatcher
    ])
}