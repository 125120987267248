import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Redirect, useHistory} from 'react-router-dom'
import {Button, Col, Container, Form, InputGroup, Row} from 'react-bootstrap'

import {createGroupRequest, getSeniorHomesRequest, getUserRequest, updateUserRequest} from '../redux/actions'
import Message from './Message'
import Loader from './Loader'
import {AppState} from '../redux/types'

const UserForm = ({onSelect}: any) => {
    const dispatch = useDispatch()
    const history = useHistory()

    const user = useSelector((state: AppState) => state.user)
    useEffect(() => {
        if (user.id) {
            dispatch(getSeniorHomesRequest())
        } else {
            history.push('/kirjaudu')
        }
        return () => {
        }
    }, [dispatch, history, user])

    const group = useSelector((state: AppState) => state.user.group)
    const {error, loading, firstName, isLoggedIn} = user
    const [message, setMessage] = useState('')
    const [isSenior, setIsSenior] = useState(user.isSenior || false)
    const [department, setDepartment] = useState(user.department || 'homeAddress')
    const [formData, setFormData] = useState({
        id: user?.id,
        username: user.username || '',
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        mobileNumber: user.mobileNumber || '',
        email: user.email || '',
        password: '',
        isSenior: isSenior || false,
        department: department || 'homeAddress',
        homeAddress: user?.homeAddress || '',
        homeCity: user?.homeCity || '',
        homePostalCode: user?.homePostalCode || '',
        nursingHome: user?.nursingHome || '',
        nursingHomeAddress: user?.nursingHomeAddress || '',
    })

    const [showPass, setShowPass] = useState(false)
    const showPassHandler = () => {
        setShowPass(current => !current)
    }

    if (!isLoggedIn) <Redirect to='/kirjaudu'/>

    // back button
    const back = () => {
        onSelect('orders')
    }

    useEffect(() => {
        if (!isLoggedIn) {
            history.push('/kirjaudu')
        } else {
            if (!user.firstName && user.id !== null) {
                dispatch(getUserRequest(user.id))
            } else {
                // user data from db shown in the form
                setFormData((prevValue: any) => {
                    // console.log(prevValue)
                    return {
                        ...prevValue,
                        username: user.username || '',
                        firstName: user.firstName || '',
                        lastName: user.lastName || '',
                        email: user.email || '',
                        password: '',
                        mobileNumber: user.mobileNumber || '',
                        department: user.department || 'homeAddress',
                        homeAddress: user?.homeAddress || '',
                        homeCity: user?.homeCity || '',
                        homePostalCode: user?.homePostalCode || '',
                    }
                })
            }
        }
    }, [dispatch, history, user, isLoggedIn])

    useEffect(() => {
        if (!group) {
            dispatch(createGroupRequest(firstName))
        }
    }, [group, dispatch, firstName])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {value, name} = e.target

        setFormData((prevValue: any) => {
            return {
                ...prevValue,
                [name]: value,
            }
        })
    }

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault()
        if (formData.password.length > 0 && formData.password.length < 11) {
            setMessage('Salasana on liian lyhyt')
        } else {
            let data = {
                id: formData.id,
                username: formData.username,
                firstName: formData.firstName,
                lastName: formData.lastName,
                mobileNumber: formData.mobileNumber,
                email: formData.email,
                isSenior: isSenior,
                nursingHome: formData.nursingHome,
                nursingHomeAddress: formData.nursingHomeAddress,
                department: department,
                homeAddress: formData?.homeAddress,
                homeCity: formData?.homeCity,
                homePostalCode: formData?.homePostalCode,
                password: formData.password,
            }
            console.log(data)
            dispatch(
                updateUserRequest(data)
            )
            if (isSenior) {
                onSelect('member')
            } else {
                onSelect('senior')
            }
        }
    }

    return (
        <Container fluid>
            <Row className="justify-content-md-left">
                <Col xs={12} md={6}>
                    {loading && <Loader/>}
                    <h1 className='user-form-header'>1 Omat yhteystiedot</h1>
                    <Form onSubmit={submitHandler} className='user-form' autoComplete="off">
                        <Form.Group controlId='username-customer'>
                            <Form.Label>*Sähköpostiosoite</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Sähköpostiosoite'
                                name='username'
                                value={formData.username}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group controlId='customer-email'>
                            <Form.Label>*Toista sähköpostiosoite</Form.Label>
                            <Form.Control
                                type='email'
                                placeholder='Toista sähköpostiosoite'
                                name='email'
                                value={formData.email}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        {user.role === 'customer' && (
                            <>
                                <Form.Group controlId='customer-password'>
                                    <Form.Label>Salasana</Form.Label>
                                    <div>
                                        <small className="for-example">
                                            Salasanasi on jo tallennettu ja voit siirtyä seuraavaan kohtaan. Jos haluat
                                            vaihtaa salasanasi, kirjoita uusi salasana kenttään (vähintään 11 merkkiä).
                                        </small>
                                    </div>
                                    <InputGroup>
                                        <Form.Control
                                            id="new-password"
                                            type={showPass ? 'text' : 'password'}
                                            name='password'
                                            value={formData.password}
                                            onChange={handleChange}
                                            autoComplete="new-password"
                                            style={{
                                                background: '#d7dadd'
                                            }}
                                        />
                                        <Button
                                            variant="outline-secondary shadow-none show-password-btn"
                                            style={{
                                                background: '#d7dadd'
                                            }}
                                            onClick={showPassHandler}
                                        >
                                            <i className={showPass ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                                        </Button>
                                    </InputGroup>
                                </Form.Group>
                            </>
                        )}

                        <Form.Group as={Row}>
                            <Col sm={6}>
                                <Form.Label>*Etunimi</Form.Label>
                                <Form.Control
                                    required
                                    className='text-field'
                                    type='text'
                                    name='firstName'
                                    placeholder='Etunimi'
                                    value={formData.firstName}
                                    onChange={handleChange}
                                />
                            </Col>

                            <Col sm={6}>
                                <Form.Label>*Sukunimi</Form.Label>
                                <Form.Control
                                    className='text-field'
                                    type='text'
                                    name='lastName'
                                    placeholder='Sukunimi'
                                    value={formData.lastName}
                                    onChange={handleChange}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group controlId='customer-mobile-number'>
                            <Form.Label>
                                *Matkapuhelinnumero
                            </Form.Label>
                            <Form.Control
                                required
                                className='text-field'
                                type='text'
                                name='mobileNumber'
                                placeholder='Matkapuhelinnumero'
                                value={formData.mobileNumber}
                                onChange={handleChange}
                            />
                            <small className="for-example">Esimerkiksi +358 50 123456</small>
                        </Form.Group>

                        <Row>
                            <Col md={isSenior ? 6 : 12}>
                                <Form.Group>
                                    <Form.Label>Valitse jompikumpi vaihtoehdoista</Form.Label>
                                    <Form.Check
                                        className='radio-button'
                                        type='radio'
                                        label='Olen seniorin läheinen ja ostan palvelun seniorille'
                                        id='senior_no'
                                        name='isSenior'
                                        value={'false'}
                                        checked={!isSenior}
                                        onChange={(e) => setIsSenior(false)}
                                    />
                                    <Form.Check
                                        className='radio-button'
                                        type='radio'
                                        label='Olen seniori ja ostan palvelun itselleni'
                                        id='senior_yes'
                                        name='isSenior'
                                        value={'true'}
                                        checked={isSenior}
                                        onChange={(e) => setIsSenior(true)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                {isSenior && <Form.Group>
                                    <Form.Label>Seniorin asuinpaikka</Form.Label>
                                    <Form.Check
                                        className='radio-button'
                                        type='radio'
                                        label='Koti'
                                        id='homeAddress'
                                        name='department'
                                        value={'homeAddress'}
                                        checked={department === 'homeAddress'}
                                        onChange={(e) => setDepartment('homeAddress')}
                                    />
                                    <Form.Check
                                        className='radio-button'
                                        type='radio'
                                        label='Vanhainkoti'
                                        id='nursingHome'
                                        name='department'
                                        value={'nursingHome'}
                                        checked={department === 'nursingHome'}
                                        onChange={(e) => setDepartment('nursingHome')}
                                    />
                                </Form.Group>
                                }
                            </Col>
                        </Row>
                        {isSenior && department === 'nursingHome' && <Form.Group>
                            <Form.Group>
                                <Form.Label>Vanhainkodin nimi</Form.Label>
                                <Form.Control
                                    className='text-field lives-at-home-input'
                                    type='text'
                                    name='nursingHome'
                                    placeholder='Vanhainkodin nimi'
                                    value={formData.nursingHome}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Paikkakunta</Form.Label>
                                <Form.Control
                                    className='text-field lives-at-home-input'
                                    type='text'
                                    name='nursingHomeAddress'
                                    placeholder='Paikkakunta'
                                    value={formData.nursingHomeAddress}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Form.Group>
                        }

                        {isSenior && department === 'homeAddress' &&
                            <div>
                                <Form.Group>
                                    <Form.Label>Katuosoite</Form.Label>
                                    <Form.Control
                                        className='text-field lives-at-home-input'
                                        type='text'
                                        name='homeAddress'
                                        placeholder='Katuosoite'
                                        value={formData.homeAddress}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Postinumero</Form.Label>
                                    <Form.Control
                                        className='text-field lives-at-home-input'
                                        type='text'
                                        name='homePostalCode'
                                        placeholder='Postinumero'
                                        value={formData.homePostalCode}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Kaupunki</Form.Label>
                                    <Form.Control
                                        className='text-field lives-at-home-input'
                                        type='text'
                                        name='homeCity'
                                        placeholder='Kaupunki'
                                        value={formData.homeCity}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </div>
                        }

                        <Row className="justify-content-md-center" style={{minHeight: 100}}>
                            <Col xs={6}>
                                <Button style={{
                                    float: 'left',
                                }} className='tallenna user-form-button'
                                        onClick={back}
                                >
                                    {`◄ Edellinen`}
                                </Button>
                            </Col>
                            <Col xs={6}>
                                <Button style={{
                                    float: 'right',
                                }} type='submit' className='tallenna user-form-button'>
                                    {isSenior ? `Seuraava Lisää läheiset ►` : `Seuraava Lisää seniori ►`}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                    {error && <Message variant='danger'>{error}</Message>}
                    {!error && message && <Message variant='success'>{message}</Message>}
                </Col>
            </Row>
        </Container>
    )
}
export default UserForm