import {call, put, select, takeLatest} from 'redux-saga/effects'
import axios from 'axios'

import {
    createServiceFail,
    createServiceSuccess,
    deleteServiceFail,
    deleteServiceSuccess,
    getServicesFail,
    getServicesSuccess
} from '../actions'
import {CreateServiceRequestAction, DeleteServiceRequestAction} from '../types'

function* getServicesSaga() {
    try {
        //@ts-ignore
        const res = yield axios.get('/services')
        yield put(getServicesSuccess(res.data.payload))
    } catch (error) {
        yield put(getServicesFail(error))
    }
}

// for admin 
function* createServiceSaga(action: CreateServiceRequestAction) {
    const {service} = action.payload

    try {
        //@ts-ignore
        const res = yield axios.put('/services', service)
        yield put(createServiceSuccess(res))
        yield call(getServicesSaga)
    } catch (error) {
        yield put(createServiceFail(error))
    }
}

function* deleteServiceSaga(action: DeleteServiceRequestAction) {
    const serviceId = action.payload
    try {
        //@ts-ignore
        const res = yield axios.delete(`/services/${serviceId}`)
        if (res.status === 200) {
            yield put(deleteServiceSuccess())
            yield call(getServicesSaga)
        }
    } catch (error) {
        yield put(deleteServiceFail(error))
    }
}

function* saveState() {
    //@ts-ignore
    const state = yield select()
    yield localStorage.setItem('initState', JSON.stringify(state))
}

const sagaWatcher = [
    takeLatest('GET_SERVICES_REQUEST', getServicesSaga),
    takeLatest('CREATE_SERVICE_REQUEST', createServiceSaga),
    takeLatest('DELETE_SERVICE_REQUEST', deleteServiceSaga),
    takeLatest('*', saveState)
]

export default sagaWatcher
