import {call, put, select, takeLatest} from 'redux-saga/effects'
import axios from 'axios'

import {
    createBlogFail,
    createBlogSuccess,
    deleteBlogFail,
    deleteBlogSuccess,
    getBlogsFail,
    getBlogsSuccess
} from '../actions'
import {CreateBlogRequestAction, DeleteBlogRequestAction} from '../types'

// for admin
function* getBlogsSaga() {
    try {
        //@ts-ignore
        const res = yield axios.get('/blogs')
        yield put(getBlogsSuccess(res.data.payload))
    } catch (error) {
        yield put(getBlogsFail(error))
    }
}

function* createBlogSaga(action: CreateBlogRequestAction) {
    const {blog} = action.payload

    try {
        //@ts-ignore
        const res = yield axios.put('/blogs', blog)
        yield put(createBlogSuccess(res))
        yield call(getBlogsSaga)
    } catch (error) {
        yield put(createBlogFail(error))
    }
}

function* deleteBlogSaga(action: DeleteBlogRequestAction) {
    const blogId = action.payload
    try {
        //@ts-ignore
        const res = yield axios.delete(`/blogs/${blogId}`)
        if (res.status === 200) {
            yield put(deleteBlogSuccess())
            yield call(getBlogsSaga)
        }
    } catch (error) {
        yield put(deleteBlogFail(error))
    }
}

function* saveState() {
    //@ts-ignore
    const state = yield select()
    yield localStorage.setItem('initState', JSON.stringify(state))
}

const sagaWatcher = [
    takeLatest('GET_BLOGS_REQUEST', getBlogsSaga),
    takeLatest('CREATE_BLOG_REQUEST', createBlogSaga),
    takeLatest('DELETE_BLOG_REQUEST', deleteBlogSaga),
    takeLatest('*', saveState)
]

export default sagaWatcher
