import NavBar from './NavBar'
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {getAlertsRequest} from "../redux/actions";
import {AppState} from "../redux/types";

const Header = () => {
    const dispatch = useDispatch()

    const currentDate = Date.now();

    useEffect(() => {
        dispatch(getAlertsRequest())
        return () => {
        }
    }, [dispatch])

    const resources = useSelector((state: AppState) => state.alerts)
    const {list} = resources
    // console.log(list)
    return (
        <header>
            <NavBar/>
            {list?.filter(el => {
                // console.log(el)
                // console.log(currentDate)
                // @ts-ignore
                return (!el.start || (new Date(el.start)) <= currentDate) && (!el.end || (new Date(el.end)) >= currentDate);

            })?.map((item) => {
                return <div className='sub-header-text'
                            key={`alert_${item.id}`}
                >
                    {item?.text}
                </div>
            })}
        </header>
    )
}

export default Header
