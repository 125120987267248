import {Card, Col, Row} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import TextOnImage from '../components/TextOnImage'
import texts from '../texts.js'
import {Helmet} from "react-helmet";
import nurse_calling from "../images/nurse_calling.jpg";
import tablet_stand from "../images/tablet_stand.jpg";

const styles = {
    customBody: {
        border: 'none',
    },
}

const ForCompanies = () => {
    return (
        <>
            <Helmet>
                <title>Senioritabletti palveluna hoivakodeille helposti | Digihappy</title>
                <meta
                    name="description"
                    content="Senioritabletti sopii loistavasti hoivakotiin tai palveluasuntoon. Helppo video, joka avautuu automaattisesti. Rentouttavat äänikirjat. Ei sido hoitajan aikaa."
                />
            </Helmet>
            <TextOnImage
                height={`calc(100vh - 66px)`}
                width='100vw'
                text1={texts[11].text1}
                text2={texts[11].text2}
                text5={texts[11].text3}
                backgroundImage={
                    'https://senioritabletti-image-bucket.s3.eu-north-1.amazonaws.com/nursinghomecropped.jpg'
                }
                backgroundSize='cover'
                backgroundPosition='center center'
                backgroundRepeat='no-repeat'
                imgClassName='forcompaniespic'
                className1='forcompanies-text-on-image forcompanies-first'
                className2='forcompanies-text-on-image forcompanies-second'
            />

            <section className='texts-for-companies'>
                <h5 className='for-companies-header'>
                    Hoivakodin asiakkaalle toimiva yhteys läheisiin ja kiinnostavaa tekemistä
                </h5>
                <div className='text-div-for-companies'>
                    <ul className='services-info-text for-companies-ul'>
                        <li className='for-companies-li'>
                            <strong className='digihappy'>Digihappy Kotisairaanhoito</strong> mahdollistaa toimivat
                            etäpalvelut kokonaisratkaisuna helposti ja edullisesti.
                        </li>
                        <li className='for-companies-li'>
                            <strong className='digihappy'>Digihappy Palvelukoti</strong> tuo ylivoimaisen
                            viestintäalustan hoitajalta kaikille senioreille sekä päivittäisistä asioista että
                            hätätilanteista.
                        </li>
                        <li className='for-companies-li'>
                            <strong className='digihappy'>Digihappy Hoivakoti</strong> toimii viestintäalustana sekä
                            tuo hauraimmillekin vanhuksille yhteyden läheisiin kun senioripuhelimen käyttö ei enää
                            onnistu. Hoivakodissa perhe on usein palvelun tilaaja.
                        </li>
                        <li className='for-companies-li'>
                            <strong className='digihappy'>Digihappy Hoitajatabletti</strong> tuo hoitajan käyttöön
                            terveystestit, aktivoivat sovellukset ja helpon kirjaamisen.
                        </li>
                    </ul>
                </div>
                <div className='text-div-for-companies'>
                    <p className='text-for-companies-second'>
                        Seniori on aina meille tärkein. Palveluihin sisältyy haluttaessa helpot videopuhelut myös
                        läheisille ja kivaa tekemistä. Tabletti on käyttövalmis ja senioriystävälliseksi muokattu.
                        Toimintavarmuutta tuo it-tuki, etähallinta, sim-kortti ja jalusta. Palvelu on myös ruotsiksi.
                    </p>
                </div>
            </section>

            <h2 className='why-digihappy'>
                Miksi <span className='digihappy'>Digihappy </span> hoivapalveluihin?
            </h2>

            <section className='image-rows'>
                <Row>
                    <Col sm={12} md={12} lg={6} className='home-column'>
                        <Card className='home-image-card manfamily'>
                            <Card.Img
                                src={
                                    'https://senioritabletti-image-bucket.s3.eu-north-1.amazonaws.com/manfamily.jpg'
                                }
                                variant='top'
                                height='100vh'
                                width='auto'
                            />

                            <Card.Body className='company-body' style={styles.customBody}>
                                <Card.Title as='div' className='image-text text-center'>
                                    Helppo yhteys läheisiin. Toimintakyvyn mukaiset aktivoivat sovellukset.
                                </Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={12} md={12} lg={6} className='home-column'>
                        <Card className='home-image-card woman2'>
                            <Card.Img
                                src={nurse_calling}
                                variant='top'
                                height='550'
                                width='auto'
                            />

                            <Card.Body className='company-body' style={styles.customBody}>
                                <Card.Title as='div' className='image-text text-center'>
                                    Kotisairaanhoitoon toimiva ja edullinen palvelukokonaisuus.
                                </Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <section className='between-image-rows'/>
                <Row>
                    <Col sm={12} md={12} lg={6} className='home-column'>
                        <Card className='home-image-card it-woman'>
                            <Card.Img
                                src={tablet_stand}
                                variant='top'
                                height='550'
                                width='auto'
                            />

                            <Card.Body className='company-body' style={styles.customBody}>
                                <Card.Title as='div' className='image-text text-center'>
                                    Palveluasuntoihin moderni viestintäalusta ja turvarannekkeet.
                                </Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={12} md={12} lg={6} className='home-column'>
                        <Card className='home-image-card doctor1'>
                            <Card.Img
                                src={
                                    'https://senioritabletti-image-bucket.s3.eu-north-1.amazonaws.com/hand.jpg'
                                }
                                variant='top'
                                height='550'
                                width='auto'
                            />

                            <Card.Body className='company-body' style={styles.customBody}>
                                <Card.Title as='div' className='image-text text-center'>
                                    Hoitajatabletti terveystesteihin, aktivoivaan tekemiseen ja kirjaamiseen.
                                </Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <div className='contact-link'>
                    <Link className='ota-yhteyttä-linkki' to='/yhteydenotto'>
                        Ota yhteyttä
                    </Link>
                </div>
                <section className='between-image-rows'/>
            </section>
        </>
    )
}

export default ForCompanies
