import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Redirect, useHistory} from 'react-router-dom'
import {Button, Col, Container, Form, InputGroup, Row} from 'react-bootstrap'

import Message from './Message'
import Loader from './Loader'
import {AppState} from '../redux/types'
import {getSeniorHomesRequest, getUserRequest, updateGroupRequest, updateUserRequest} from '../redux/actions'

const SeniorDetail = ({onSelect}: any) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const user = useSelector((state: AppState) => state.user)
    // console.log(user)
    const {error, loading, isLoggedIn} = user

    useEffect(() => {
        if (user.id) {
            dispatch(getSeniorHomesRequest())
        } else {
            history.push('/kirjaudu')
        }
        return () => {
        }
    }, [dispatch, history, user])

    const senior = user.role === 'senior' ? user : user.group?.members?.filter(obj => {
        return obj.role === 'senior'
    })[0]

    // console.log(senior)

    const [message, setMessage] = useState('')
    const [saved, setSaved] = useState(false)
    const [formData, setFormData] = useState({
        // @ts-ignore
        id: senior?.id,
        username: senior?.username || '',
        password: '',
        confirmPassword: '',
        firstName: senior?.firstName || '',
        lastName: senior?.lastName || '',
        mobileNumber: senior?.mobileNumber || '',
        nursingHome: senior?.nursingHome || '',
        department: senior?.department || '',
        homeAddress: senior?.homeAddress || '',
        homeCity: senior?.homeCity || '',
        homePostalCode: senior?.homePostalCode || '',
        nursingHomeAddress: senior?.nursingHomeAddress || '',
        role: 'senior',
    })
    const [btnDisabled, setBtnDisabled] = useState(true)
    const [showPass, setShowPass] = useState(false)
    const [showPass2, setShowPass2] = useState(false)
    const showPassHandler = () => {
        setShowPass(current => !current)
    }
    const showPassHandler2 = () => {
        setShowPass2(current => !current)
    }
    // console.log(formData)

    if (!isLoggedIn) <Redirect to='/kirjaudu'/>

    // back button
    const back = () => {
        onSelect('customer')
    }

    useEffect(() => {
        if (!isLoggedIn) {
            history.push('/kirjaudu')
        } else {
            if (!user.firstName && user.id !== null) {
                dispatch(getUserRequest(user.id))
            } else {
                // user data from db shown in the form
                setFormData((prevValue: any) => {
                    return {
                        ...prevValue,
                        username: formData.username,
                        password: formData.password,
                        email: '',
                        firstName: formData.firstName,
                        lastName: formData.lastName,
                        mobileNumber: formData.mobileNumber,
                        nursingHome: formData.nursingHome,
                        department: formData.department,
                        homeAddress: formData.homeAddress,
                        homeCity: formData.homeCity,
                        homePostalCode: formData.homePostalCode,
                        nursingHomeAddress: formData.nursingHomeAddress,
                    }
                })
            }
        }
        return () => {
        }
    }, [
        dispatch,
        history,
        user,
        isLoggedIn,
        formData.department,
        formData.firstName,
        formData.username,
        formData.lastName,
        formData.mobileNumber,
        formData.nursingHome,
        formData.homeAddress,
        formData.homeCity,
        formData.homePostalCode,
        formData.nursingHomeAddress,
        formData.password,
    ])

    useEffect(() => {
        // console.log(formData)
        if (!formData.firstName || !formData.lastName || !formData.department.length
            || (formData.department === 'nursingHome' && !formData.nursingHome && !formData.nursingHomeAddress)
            || (formData.department === 'homeAddress' && !formData.homeAddress && !formData.homeCity
                && !formData.homePostalCode)
        ) {
            setBtnDisabled(true)
        } else {
            setBtnDisabled(false)
        }
        if (!saved) {
            let msg = ''
            if (formData.password.length > 0 && formData.password.length < 11) {
                msg += 'Salasana on liian lyhyt'
            } else if (formData.password.length > 0 && formData.confirmPassword.length > 0 && formData.password !== formData.confirmPassword) {
                msg += 'Salasanat eivät täsmää\n'
            }
            setMessage(msg)
        }
    }, [formData, saved])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {value, name} = e.target
        // console.log(name)
        // console.log(value)
        setSaved(false)

        setFormData((prevValue: any) => {
            return {
                ...prevValue,
                [name]: value,
            }
        })
    }

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault()

        if (user.role !== 'senior') {
            // console.log(formData)
            dispatch(
                updateGroupRequest({
                    id: formData.id,
                    username: formData.username,
                    password: formData.password,
                    email: formData.username,
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    mobileNumber: formData.mobileNumber,
                    nursingHome: formData.nursingHome,
                    department: formData.department,
                    homeAddress: formData.homeAddress,
                    homeCity: formData.homeCity,
                    homePostalCode: formData.homePostalCode,
                    nursingHomeAddress: formData.nursingHomeAddress,
                    role: 'senior',
                })
            )
            // console.log(user)
            setSaved(true)
            onSelect('member')
        }

        if (user.role === 'senior') {
            if (!formData.password || formData.password !== formData.confirmPassword) {
                // console.log(formData.password)
                // console.log(formData.confirmPassword)
                setMessage('Salasanat eivät täsmää')
            } else {
                setMessage('')
            }
            if (user.password || formData.password === formData.confirmPassword) {
                dispatch(
                    updateUserRequest({
                        id: formData.id,
                        username: formData.username,
                        password: formData.password,
                        email: formData.username,
                        firstName: formData.firstName,
                        lastName: formData.lastName,
                        mobileNumber: formData.mobileNumber,
                        nursingHome: formData.nursingHome,
                        department: formData.department,
                        homeAddress: formData.homeAddress,
                        homePostalCode: formData.homePostalCode,
                        homeCity: formData.homeCity,
                        role: 'senior',
                    })
                )
            }
        }
    }

    return (
        <Container fluid>
            <Row className="justify-content-md-left">
                <Col xs={12} md={6}>
                    {loading && <Loader/>}
                    <h1 className='senior-detail-header'>{user.role === 'senior' ? 'Seniorin tiedot' : '2 Lisää seniori'}</h1>
                    <Form onSubmit={submitHandler} className='user-form'>
                        <Form.Group as={Row}>
                            <Col sm={6}>
                                <Form.Label>
                                    {/* {formData.firstName === '' && <FcHighPriority />}  */}
                                    *Etunimi
                                </Form.Label>
                                <Form.Control
                                    required
                                    className='text-field'
                                    type='text'
                                    placeholder='Etunimi'
                                    name='firstName'
                                    value={formData.firstName}
                                    onChange={handleChange}
                                />
                            </Col>
                            <Col sm={6}>
                                <Form.Label>
                                    {/* {formData.lastName === '' && <FcHighPriority />}  */}
                                    *Sukunimi
                                </Form.Label>
                                <Form.Control
                                    required
                                    className='text-field'
                                    type='text'
                                    placeholder='Sukunimi'
                                    name='lastName'
                                    value={formData.lastName}
                                    onChange={handleChange}
                                />
                            </Col>
                        </Form.Group>
                        {/*<Form.Group controlId='username-senior'>*/}
                        {/*    <Form.Label>*/}
                        {/*        /!* {formData.username === '' && <FcHighPriority />}  *!/*/}
                        {/*        *Seniorin käyttäjänimi*/}
                        {/*    </Form.Label>*/}
                        {/*    <Form.Control*/}
                        {/*        required*/}
                        {/*        type='text'*/}
                        {/*        placeholder='Seniorin käyttäjänimi'*/}
                        {/*        name='username'*/}
                        {/*        value={formData.username}*/}
                        {/*        onChange={handleChange}*/}
                        {/*    />*/}
                        {/*</Form.Group>*/}
                        {user.role === 'senior' && <Form.Group controlId='senior-password'>
                            <Form.Label>
                                {/* {formData.password === '' && <FcHighPriority />}  */}
                                *Salasana
                            </Form.Label>
                            <InputGroup>
                                <Form.Control
                                    type={showPass ? 'text' : 'password'}
                                    placeholder='Salasana'
                                    name='password'
                                    value={formData.password}
                                    onChange={handleChange}
                                />
                                <Button variant="outline-secondary shadow-none show-password-btn"
                                        onClick={showPassHandler}>
                                    <i className={showPass ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                                </Button>
                            </InputGroup>
                            <div
                                className="show-password"
                            >
                                Näet salasanasi klikkaamalla silmäkuvaketta
                            </div>
                            <small className='password-length'>Voit vaihtaa salasanan kirjoittamalla uuden salasanan molempiin kenttiin.</small>
                            <br/>
                            <small className='password-length'>Vähintään 11 merkkiä</small>
                        </Form.Group>
                        }
                        {user.role === 'senior' &&
                            <Form.Group controlId='confirm-password-senior'>
                                <Form.Label>*Toista salasana</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        type={showPass2 ? 'text' : 'password'}
                                        placeholder='Toista salasana'
                                        name='confirmPassword'
                                        value={formData.confirmPassword}
                                        onChange={handleChange}
                                    />
                                    <Button variant="outline-secondary shadow-none show-password-btn"
                                            onClick={showPassHandler2}>
                                        <i className={showPass2 ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                                    </Button>

                                </InputGroup>
                                <div
                                    className="show-password"
                                >
                                    Näet salasanasi klikkaamalla silmäkuvaketta
                                </div>
                                {user?.role === 'senior' &&
                                    <small className="for-example">
                                        Vaihtaaksesi salasanan kirjoita uusi salasana molempiin kenttiin. Mikäli et
                                        halua vaihtaa
                                        salasanaa, voit jättää tämän huomiotta.
                                    </small>
                                }
                            </Form.Group>
                        }
                        <Form.Group controlId='senior-mobile'>
                            <Form.Label>Matkapuhelinnumero (vapaaehtoinen)</Form.Label>
                            <Form.Control
                                className='text-field'
                                type='text'
                                name='mobileNumber'
                                placeholder='Matkapuhelinnumero'
                                value={formData.mobileNumber}
                                onChange={handleChange}
                            />
                            <small>Esimerkiksi +358 40 123456</small>
                        </Form.Group>

                        <Form.Label>
                            {/* {formData.nursingHome === '' &&
            formData.homeAddress === '' && <FcHighPriority />} */}
                            *Osoitetiedot
                        </Form.Label>
                        <Form.Group>
                            <Form.Check
                                className='radio-button'
                                type='radio'
                                label='Asuu kotona'
                                id='homeAddress'
                                name='department'
                                value={'homeAddress'}
                                checked={formData.department === 'homeAddress'}
                                onChange={handleChange}
                            />
                            <Form.Check
                                className='radio-button'
                                type='radio'
                                label='Asuu vanhainkodissa'
                                id='nursingHome'
                                name='department'
                                value={'nursingHome'}
                                checked={formData.department === 'nursingHome'}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        {formData.department === 'homeAddress' &&
                            <div>
                                <Form.Group>
                                    <Form.Label>Katuosoite</Form.Label>
                                    <Form.Control
                                        className='text-field lives-at-home-input'
                                        type='text'
                                        name='homeAddress'
                                        placeholder='Katuosoite'
                                        value={formData.homeAddress}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                                <Form.Group controlId='city'>
                                    <Form.Label>Postinumero</Form.Label>
                                    <Form.Control
                                        className='text-field lives-at-home-input'
                                        type='text'
                                        name='homePostalCode'
                                        placeholder='Postinumero'
                                        value={formData.homePostalCode}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                                <Form.Group controlId='city'>
                                    <Form.Label>Kaupunki</Form.Label>
                                    <Form.Control
                                        className='text-field lives-at-home-input'
                                        type='text'
                                        name='homeCity'
                                        placeholder='Kaupunki'
                                        value={formData.homeCity}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </div>
                        }
                        {formData.department === 'nursingHome' && <div>
                            <Form.Group>
                                <Form.Label>Vanhainkodin nimi</Form.Label>
                                <Form.Control
                                    className='text-field lives-at-home-input'
                                    type='text'
                                    name='nursingHome'
                                    placeholder='Vanhainkodin nimi'
                                    value={formData.nursingHome}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Paikkakunta</Form.Label>
                                <Form.Control
                                    className='text-field lives-at-home-input'
                                    type='text'
                                    name='nursingHomeAddress'
                                    placeholder='Paikkakunta'
                                    value={formData.nursingHomeAddress}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </div>
                        }

                        <Row className="justify-content-md-center" style={{minHeight: 100}}>
                            <Col xs={6}>
                                {user.role === 'customer' && <Button
                                    style={{
                                        float: 'left',
                                    }}
                                    className='tallenna user-form-button'
                                    onClick={back}
                                >
                                    {`◄ Edellinen`}
                                </Button>
                                }
                            </Col>
                            <Col xs={6}>
                                <Button
                                    style={{
                                        float: 'right',
                                    }}
                                    type='submit'
                                    className='tallenna senior-button'
                                    disabled={btnDisabled}
                                >
                                    {user.role === 'senior' ? 'Tallenna' : 'Seuraava Lisää läheiset ►'}
                                </Button>
                                <div
                                    style={{
                                        float: 'right',
                                    }}
                                >
                                    {error && <Message variant='danger'>{error.message}</Message>}
                                    {!error && message && <Message variant='danger'>{message}</Message>}
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}
export default SeniorDetail
