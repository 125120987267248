import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { AppState } from '../redux/types';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

export default function OrderForm(params: any) {
  const cart = useSelector((state: AppState) => state.cart);
  let [btnDisabled, setBtnDisabled] = useState(false);

  const placeOrderHandler = () => {
    setBtnDisabled(true);
    const data = {
      name: cart.name,
      address: cart.address,
      postalCode: cart.postalCode,
      city: cart.city,
      country: cart.country,
      email: cart.email,
      mobile: cart.mobile,
      paymentMethod: cart.paymentMethod,
      stripePaymentMethod: cart.stripePaymentMethod,
      price: cart.price,
      shippingPrice: cart.shippingPrice,
      shippingMethod: cart.shippingMethod,
      taxPrice: cart.taxPrice,
      totalPrice: cart.totalPrice,
      services: cart.inCart,
      pickupPoint: cart.pickupPoint?.id || '',
      pickupPointData: cart.pickupPoint,
      usePickupPoint: cart.usePickupPoint,
      coupon: cart.coupon,
      nursingHome: cart.nursingHome,
    };
    axios
      .post('/user/orders', { order: data })
      .then((resp: any) => {
        if (resp.data.payload.payment_type === 'card') {
          if (resp.data.payload.payment_intent?.status === 'requires_action') {
            console.log(
              resp.data.payload.payment_intent?.next_action?.redirect_to_url.url
            );
            window.open(
              resp.data.payload.payment_intent?.next_action?.redirect_to_url
                .url,
              '_self'
            );
          } else {
            window.open(
              `/user/orders?order_id=${resp.data.payload.order_id}`,
              '_self'
            );
          }
        } else {
          window.open(
            `/user/orders?order_id=${resp.data.payload.order_id}`,
            '_self'
          );
        }
      })
      .catch((error) => {
        const body = error.response.data;
        console.log(body);
        if (body?.message?.type === 'StripeInvalidRequestError') {
          // const message = body?.message?.raw?.message;
          const message = (
            <div>
              <div>Ostoskorissa on ongelma,</div>
              <Link
                to='/yhteydenotto'
                style={{
                  color: '#ffffff',
                  textDecoration: 'underline',
                }}
              >
                ota yhteyttä asiakaspalveluun
              </Link>
            </div>
          );
          toast.error(message, {
            hideProgressBar: true,
            position: 'bottom-center',
            theme: 'colored',
          });
        } else if (body?.message?.type === 'StripeCardError') {
          toast.error(body?.message?.raw?.message, {
            hideProgressBar: true,
            position: 'bottom-center',
            theme: 'colored',
          });
        } else {
          toast.error(body?.message, {
            hideProgressBar: true,
            position: 'bottom-center',
            theme: 'colored',
          });
        }
      })
      .finally(() => {
        setBtnDisabled(false);
      });
  };

  return (
    <Button
      type='button'
      className='btn-block tallenna'
      disabled={params.disabled || btnDisabled || cart.inCart.length === 0}
      onClick={placeOrderHandler}
    >
      Seuraava
    </Button>
  );
}
