import {useSelector} from 'react-redux'
import {Accordion, Tabs} from 'react-bootstrap'

import {AppState} from '../redux/types'
import {useEffect, useRef, useState} from "react";

const Tab = ({key1, onSelect, userForm, memberForm, seniorDetail, featureChoices, orders}: any) => {
    const user = useSelector((state: AppState) => state.user)
    // const members = useSelector((state: AppState) => state.user.group?.members)
    // const hasSeniorMember = members?.find(el => el.role === 'senior')
    const tab4Ref = useRef<HTMLHeadingElement>(null);
    const [width, setWidth] = useState(1000);
    useEffect(() => {
        if (tab4Ref.current) {
            console.log(tab4Ref?.current?.clientWidth);
            setWidth(tab4Ref?.current?.clientWidth - 32);
        }

    }, [tab4Ref]);
    return (
        <>
            {key1 && user.role === 'customer' && (
                <Tabs
                    id='controlled-tab'
                    activeKey={key1 || 'orders'}
                    onSelect={onSelect}
                >
                    <Tab
                        eventKey='orders'
                        title='Tilaustiedot'
                    >
                        {orders}
                    </Tab>
                    <Tab
                        eventKey='customer'
                        title='1 Omat yhteystiedot'
                    >
                        {userForm}
                    </Tab>
                    <Tab
                        eventKey='senior'
                        title='2 Lisää seniori'
                        disabled={user.isSenior}
                    >
                        {seniorDetail}
                    </Tab>
                    <Tab
                        eventKey='member'
                        title='3 Lisää läheiset'
                    >
                        {memberForm}
                    </Tab>
                    <Tab
                        id="tab4"
                        style={{
                            flexShrink: 'max-content'
                        }}
                        eventKey='features'
                        title={
                            <div
                                ref={tab4Ref}
                                style={{
                                    display: 'flex',
                                    flexFlow: 'wrap',
                                    width: '100%',
                                    maxWidth: width,
                                }}
                            >
                                <div
                                    style={{
                                        width: '100%',
                                    }}
                                >
                                    4 Räätälöi tabletin ominaisuudet halutessasi
                                </div>
                                <Accordion
                                    style={{
                                        paddingLeft: 22,
                                        width: '100%',
                                    }}
                                >
                                    <Accordion.Toggle
                                        eventKey='0'
                                        as="span"
                                        onClick={function (event) {
                                            event.stopPropagation();
                                        }}
                                    >
                                        <small>
                                            Lue lisää
                                        </small>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse
                                        eventKey='0'
                                        style={{
                                            width: 'inherit',
                                        }}
                                    >
                                        <small>
                                            Tabletin oletusasetuksina on suomi, videopuhelun automaattinen avautuminen
                                            (Helppo ja Huoleton) ja valitun palvelun suosituin sovellusvalikoima.
                                        </small>
                                    </Accordion.Collapse>
                                </Accordion>
                            </div>
                        }
                    >
                        {featureChoices}
                    </Tab>
                </Tabs>
            )}
            <>{user.role === 'member' && memberForm}</>
            <>{user.role === 'senior' && seniorDetail}</>
        </>
    )
}

export default Tab
