import {put, select, takeLatest, call} from 'redux-saga/effects'
import axios from 'axios'

import {
    createCouponFail,
    createCouponSuccess,
    deleteCouponFail,
    deleteCouponSuccess, getCouponsFail,
    getCouponsSuccess
} from '../actions'
import {CreateCouponRequestAction, DeleteCouponRequestAction} from '../types'

// for admin
function* getCouponsSaga() {
    try {
        //@ts-ignore
        const res = yield axios.get('/coupons')
        yield put(getCouponsSuccess(res.data.payload))
    } catch (error) {
        yield put(getCouponsFail(error))
    }
}

function* createCouponSaga(action: CreateCouponRequestAction) {
    const {coupon} = action.payload

    try {
        //@ts-ignore
        const res = yield axios.put('/coupons', coupon)
        yield put(createCouponSuccess(res))
        yield call(getCouponsSaga)
    } catch (error) {
        yield put(createCouponFail(error))
    }
}

function* deleteCouponSaga(action: DeleteCouponRequestAction) {
    const couponId = action.payload
    try {
        //@ts-ignore
        const res = yield axios.delete(`/coupons/${couponId}`)
        if (res.status === 200) {
            yield put(deleteCouponSuccess())
            yield call(getCouponsSaga)
        }
    } catch (error) {
        yield put(deleteCouponFail(error))
    }
}

function* saveState() {
    //@ts-ignore
    const state = yield select()
    yield localStorage.setItem('initState', JSON.stringify(state))
}

const sagaWatcher = [
    takeLatest('GET_COUPONS_REQUEST', getCouponsSaga),
    takeLatest('CREATE_COUPON_REQUEST', createCouponSaga),
    takeLatest('DELETE_COUPON_REQUEST', deleteCouponSaga),
    takeLatest('*', saveState)
]

export default sagaWatcher
