import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { createServiceRequest, getServicesRequest } from "../redux/actions";
import { AppState } from "../redux/types";
import { LinkContainer } from "react-router-bootstrap";

const ServiceForm = () => {
  const { id } = useParams<{ id: string }>();
  const resources = useSelector((state: AppState) => state.resources);
  const { services } = resources;
  let service = undefined;
  try {
    service = services?.find((o) => o.id === parseInt(id));
  } catch (error) {}
  const [formData, setFormData] = useState({
    id: service ? parseInt(service.id) : -1,
    sortNumber: service?.sortNumber || "",
    name: service?.name || "",
    descriptionFirst: service?.descriptionFirst || "",
    descriptionSecond: service?.descriptionSecond || "",
    price: service?.price || 0,
    category: service?.category || "main",
    shippingCategory: service?.shippingCategory || "",
    isActive: service?.isActive ? 1 : service?.isActive === undefined ? 1 : 0,
    stripeProductId: service?.stripeProductId || "",
    stripeShippingRateId: service?.stripeShippingRateId || "",
    stripeShippingRateIdFree: service?.stripeShippingRateIdFree || "",
    priceType: service?.priceType || "one_time",
    period: service?.period,
    photoShow: service?.photoShow ? 1 : 0,
    photoLink: service?.photoLink || "",
    photoDescription: service?.photoDescription || ""
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector((state: AppState) => state.user);
  useEffect(() => {
    if (user && (user.isAdmin || user.role === "it_support")) {
      dispatch(getServicesRequest());
    } else {
      history.push("/kirjaudu");
    }
    return () => {};
  }, [dispatch, history, user]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    console.log(name)
    console.log(value)
    setFormData((prevValue: any) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const submitHandler = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(
      createServiceRequest({
        id: formData.id === -1 ? undefined : formData.id,
        sortNumber: formData.sortNumber,
        name: formData.name,
        descriptionFirst: formData.descriptionFirst,
        descriptionSecond: formData.descriptionSecond,
        price: formData.price,
        category: formData.category,
        isActive: !!+formData.isActive,
        stripeProductId: formData.stripeProductId,
        shippingCategory: formData.shippingCategory,
        stripeShippingRateId: formData.stripeShippingRateId,
        stripeShippingRateIdFree: formData.stripeShippingRateIdFree,
        priceType: formData.priceType,
        period: formData.period,
        photoShow: !!formData.photoShow,
        photoLink: formData.photoLink,
        photoDescription: formData.photoDescription
      })
    );
    dispatch(getServicesRequest());
    history.push("/admin/services");
  };

  return (
    <Container className="register-form-container">
      {/* {error && <Message variant='danger'>{error}</Message>}
          {!error && message && <Message variant='success'>{message}</Message>} */}
      <Form onSubmit={submitHandler} className="service-form">
        <Form.Group controlId="service-name">
          <Form.Label>Lajittele numero</Form.Label>
          <Form.Control
            type="number"
            placeholder="Lajittele numero"
            name="sortNumber"
            value={formData.sortNumber}
            onChange={handleChange}
            readOnly={user.readOnly || !user.isAdmin}
          />
        </Form.Group>
        <Form.Group controlId="service-name">
          <Form.Label>Palvelun nimi</Form.Label>
          <Form.Control
            type="text"
            placeholder="Palvelun nimi"
            name="name"
            value={formData.name}
            onChange={handleChange}
            readOnly={user.readOnly || !user.isAdmin}
          />
        </Form.Group>
        <Form.Group controlId="service-descriptionFirst">
          <Form.Label>Palvelun kuvaus vasen sarake</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            type="text"
            placeholder="Palvelun kuvaus vasen sarake"
            name="descriptionFirst"
            value={formData.descriptionFirst}
            onChange={handleChange}
            readOnly={user.readOnly || !user.isAdmin}
          />
        </Form.Group>
        <Form.Group controlId="servie-descriptionSecond">
          <Form.Label>Palvelun kuvaus oikea sarake</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            type="text"
            placeholder="Palvelun kuvaus oikea sarake"
            name="descriptionSecond"
            value={formData.descriptionSecond}
            onChange={handleChange}
            readOnly={user.readOnly || !user.isAdmin}
          />
        </Form.Group>
        <Form.Group controlId="service-three-month-price">
          <Form.Label>Kolmen kuukauden hinta</Form.Label>
          <Form.Control
            type="text"
            placeholder="Kolmen kuukauden hinta"
            name="price"
            value={formData.price}
            onChange={handleChange}
            readOnly={user.readOnly || !user.isAdmin}
          />
        </Form.Group>
        <Form.Group controlId="service-category">
          <Form.Label>Kategoria</Form.Label>
          <Form.Control
              as="select"
              name="category"
              value={formData.category}
              onChange={handleChange}
              disabled={user.readOnly || !user.isAdmin}
          >
            <option value="main">Main</option>
            <option value="main2">Main2</option>
            <option value="additional">Additional</option>
            <option value="shipping">Shipping</option>
          </Form.Control>
        </Form.Group>
        {formData.category === "shipping" && (
          <Form.Group controlId="service-shipping-category">
            <Form.Label>Shipping category</Form.Label>
            <Form.Control
              as="select"
              name="shippingCategory"
              value={formData.shippingCategory}
              onChange={handleChange}
              disabled={user.readOnly || !user.isAdmin}
            >
              <option value="">None</option>
              <option value="direct">Direct</option>
              <option value="posti">Posti</option>
            </Form.Control>
          </Form.Group>
        )}
        <Form.Group controlId="service-isactive">
          <Form.Label>Aktiivinen</Form.Label>
          <Form.Control
            as="select"
            name="isActive"
            value={formData.isActive}
            onChange={handleChange}
            disabled={user.readOnly || !user.isAdmin}
          >
            <option value={1}>Yes</option>
            <option value={0}>No</option>
          </Form.Control>
        </Form.Group>
        {formData.category !== "shipping" && (
          <Form.Group controlId="service-stripeproductid">
            <Form.Label>Stripe product ID</Form.Label>
            <Form.Control
              type="text"
              placeholder="Stripe product ID"
              name="stripeProductId"
              value={formData.stripeProductId}
              onChange={handleChange}
              readOnly={user.readOnly || !user.isAdmin}
            />
          </Form.Group>
        )}
        {formData.category === "shipping" && (
          <Form.Group controlId="service-stripeshippingrateid">
            <Form.Label>Stripe shipping rate ID (paid)</Form.Label>
            <Form.Control
              type="text"
              placeholder="Stripe shipping rate ID (paid)"
              name="stripeShippingRateId"
              value={formData.stripeShippingRateId}
              onChange={handleChange}
              readOnly={user.readOnly || !user.isAdmin}
            />
          </Form.Group>
        )}
        {formData.category === "shipping" && (
          <Form.Group controlId="service-stripeshippingrateidfree">
            <Form.Label>Stripe shipping rate ID (free)</Form.Label>
            <Form.Control
              type="text"
              placeholder="Stripe shipping rate ID (free)"
              name="stripeShippingRateIdFree"
              value={formData.stripeShippingRateIdFree}
              onChange={handleChange}
              readOnly={user.readOnly || !user.isAdmin}
            />
          </Form.Group>
        )}
        {formData.category !== "shipping" && (
          <Form.Group controlId="service-pricetype">
            <Form.Label>Price type</Form.Label>
            <Form.Control
              as="select"
              name="priceType"
              value={formData.priceType}
              onChange={handleChange}
              disabled={user.readOnly || !user.isAdmin}
            >
              <option value="one_time">One-time</option>
              <option value="recurring">Recurring</option>
            </Form.Control>
          </Form.Group>
        )}
        {formData.priceType === "recurring" && (
            <Form.Group controlId="service-period">
              <Form.Label>Period (month)</Form.Label>
              <Form.Control
                  type="number"
                  as="input"
                  name="period"
                  min={1}
                  max={60}
                  value={formData.period || 1}
                  onChange={handleChange}
                  disabled={user.readOnly || !user.isAdmin}
              />
            </Form.Group>
        )}
        <Form.Group controlId="service-photoshow">
          <Form.Label>Show photo</Form.Label>
          <Form.Control
              as="select"
              name="photoShow"
              value={formData.photoShow}
              onChange={handleChange}
              disabled={user.readOnly || !user.isAdmin}
          >
            <option value={1}>Yes</option>
            <option value={0}>No</option>
          </Form.Control>
        </Form.Group>
        {!!+formData.photoShow && (
            <Form.Group controlId="service-photolink">
              <Form.Label>Photo link</Form.Label>
              <Form.Control
                  type="text"
                  placeholder="https://..."
                  name="photoLink"
                  value={formData.photoLink}
                  onChange={handleChange}
                  readOnly={user.readOnly || !user.isAdmin}
              />
            </Form.Group>
        )}
        {!!+formData.photoShow && (
            <Form.Group controlId="service-photodescription">
              <Form.Label>Photo description</Form.Label>
              <Form.Control
                  type="text"
                  placeholder="Specify an alternate text for an image"
                  name="photoDescription"
                  value={formData.photoDescription}
                  onChange={handleChange}
                  readOnly={user.readOnly || !user.isAdmin}
              />
            </Form.Group>
        )}
        <Row>
          <Col>
            <LinkContainer to={`/admin/services`}>
              <Button className="tallenna paymentmethod-back-button">
                Takaisin
              </Button>
            </LinkContainer>
          </Col>
          {!user.readOnly && user.isAdmin && (
            <Col
              style={{
                textAlign: "right",
              }}
            >
              <Button className="create-service-button tallenna" type="submit">
                Tallenna
              </Button>
            </Col>
          )}
        </Row>
      </Form>
    </Container>
  );
};

export default ServiceForm;
