import {combineReducers} from 'redux'

import user from './user'
import users from './users'
import cart from './cart'
import services from './services'
import alerts from './alerts'
import coupons from './coupons'
import resources from './resources'
import order from './order'
import orders from './orders'
import blogs from './blogs'
import seniorHomes from "./senior_homes";
import nurses from './nurses'
import itSupport from './itSupport'

const rootReducer = () =>
    combineReducers({
        user,
        users,
        cart,
        services,
        alerts,
        coupons,
        blogs,
        resources,
        order,
        orders,
        seniorHomes,
        nurses,
        itSupport
    })

export default rootReducer